import React from "react";
import { Layout } from "antd";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch } from "react-router-dom";
import HeaderPage from "../components/Shared/Header/Header";
import Sidebar from "../components/Shared/Sidebar/Sidebar";

import { MenuItems } from "../helper/MenuItems/MenuItems";
import { paths } from "../helper/resources/paths";
import HomePage from "../views/Shared/HomePage/HomePage";
import UserTracingPage from "../views/UserTracingPage/UserTracingPage";
import { useSelector } from "react-redux";
import { MenuType } from "../helper/enums/MenuType";

const { Content } = Layout;

const PrivateRoutes = ({userState}) => {
  const { t } = useTranslation();
  const [routers, setRouters] = React.useState([]);
  React.useEffect(()=>{
    setRouters(
      MenuItems(t, userState.user.userRole).filter((p) => p.menuType === MenuType.SIDEBAR)
    )
  },[])
  
  const GetComponent = (path) => {
    return (
      <Layout>
        <HeaderPage />
        <Layout>
          <Sidebar />
          <Content id="root-content">
            <Switch>
              <Route
                path={"/UserTracingPage"}
                render={() => (
                  <>
                    <UserTracingPage />
                  </>
                )}
              />
              {routers?.map((p) =>
                p?.render ? (
                  <Route exact key={p.key} path={p.path}>
                    {p.render}
                  </Route>
                ) : undefined
              )}
              {routers?.map((items) =>
                items.subMenuItems?.length > 0
                  ? items.subMenuItems.map((subItem) => (
                      <Route exact key={subItem.path} path={subItem.path}>
                        {subItem.render}
                      </Route>
                    ))
                  : undefined
              )}
              <Redirect
                to={paths.homePage}
                render={() => (
                  <>
                    <HomePage />
                  </>
                )}
              />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    );
  };
  return routers.length === 0 ? <></> : GetComponent(window.location.pathname);
};

export default PrivateRoutes;

import { message } from "antd";

const messageError = ({ text, duration = 10 }) => {
  message.error({
    content: text,
    duration: duration,
    className: "custom-class",
    style: {
      position: "fixed",
      bottom: 0,
      right: 0,
    },
  });
};

export default messageError;

import { Tabs } from "antd";
import React from "react";
import GreenAlarmViewGrid from "./GreenAlarmViewGrid";
import RedAlarmViewGrid from "./RedAlarmViewGrid";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;

const AlarmViewPage = () => {
  const { t } = useTranslation();
  return (
    <div className="c-main">
      <div className="c-content">
        <Tabs className="c-alarm__tabs" defaultActiveKey="0" type="card">
          <TabPane tab={t("passiveAlarms")} key="0">
            <GreenAlarmViewGrid />
          </TabPane>
          <TabPane tab={t("activeAlarms")} key="1">
            <RedAlarmViewGrid />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default AlarmViewPage;

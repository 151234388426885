import axios from "axios";
import { getApiUrl } from "./../../../config/applicationConfig";

export const AddAlarmInsert = (data) => {
  var formData = new FormData();
  formData.append("values", JSON.stringify(data));

  return async (dispatch) => {
    axios
      .post(getApiUrl("/api/AlarmTrigger/Insert"), formData)
      .then((res) => res.data)
      .then()
      .catch();
  };
};

export const UpdateRangeDescription = (ids, description) => {
  axios
    .post(getApiUrl("/api/AlarmTriggerRecord/UpdateRangeDescription"), ids, {
      params: {
        description: description,
      },
    })
    .then((res) => console.log(res))
    .then()
    .catch();
};

import React from "react";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../helper/Grid/GridCreatorMixin";
import { SelectBox } from "devextreme-react";
import DataSource from "devextreme/data/data_source";
import { setSubGridApiDefault } from "../../../config/fetchApiConfig";
import { useTranslation } from "react-i18next";

const TagCustomTypeGrid = () => {
  const { t } = useTranslation();
  const modelDataSource = new DataSource({
    store: setSubGridApiDefault("TagCustomTypeGroup", "id", "GetListTreeView"),
    group: "masterName",
    sort: [{ selector: "orderNo", desc: false }],
  });

  const pageGrid = {
    apiController: "TagCustomType",
    apiKey: "Id",
    editTitle: t("productCategoryInfo"),
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 1,
      validationSummary: true,
    },
    onInitNewRow: (options) => {
      options.data.queryParameter = false;
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "QueryParameter",
        caption: t("queryParameter"),
        dataType: "boolean",
      },
      {
        dataField: "IsCustomTable",
        caption: t("isCustomTable"),
        dataType: "boolean",
      },
      {
        dataField: "RoundValue",
        caption: t("roundingValue"),
        dataType: "number",
      },
      {
        dataField: "TagCustomTypeGroupId",
        caption: t("tagCustomTypeGroup"),
        dataType: "number",
        relationContent: {
          controller: "TagCustomTypeGroup",
          display: "Name",
        },
        editCellComponent: (cell) => {
          return (
            <SelectBox
              dataSource={modelDataSource}
              valueExpr="id"
              grouped={true}
              onValueChanged={({ value }) => cell.setValue(value)}
              searchEnabled={true}
              defaultValue={cell.value}
              displayExpr="name"
              showClearButton={true}
              groupRender={(e) => e.key.split("~")[1]}
            />
          );
        },
      },
    ],
  };

  return <RenderMasterGrid gridData={CreateGridData(pageGrid)} />;
};
export default TagCustomTypeGrid;

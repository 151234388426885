import {
  EmailRule,
  NumericRule,
  RangeRule,
  RequiredRule,
  StringLengthRule,
} from "devextreme-react/form";
import React from "react";

const ValidateRequired = (fieldName, t) => {
  return (
    <RequiredRule
      message={t("validationStringRequired").replace("{0}", t(fieldName))}
    />
  );
};

const ValidateStringLength = (fieldName, min, max, t) => {
  let message;
  if (min && max)
    message = t("validationStringMinMax")
      .replace("{0}", t(fieldName))
      .replace("{1}", min.toString())
      .replace("{2}", max.toString());
  else if (min && !max)
    message = t("validationStringMin")
      .replace("{0}", t(fieldName))
      .replace("{1}", min.toString());
  else if (max && !min)
    message = t("validationStringMax")
      .replace("{0}", t(fieldName))
      .replace("{1}", max.toString());
  else return <div />;

  return <StringLengthRule min={min} max={max} message={message} />;
};

const ValidateEMail = (fieldName, t) => {
  return <EmailRule message={t("validationMail").replace("{0}", fieldName)} />;
};

const ValidateNumeric = (fieldName, t) => {
  return (
    <NumericRule message={t("validationNumeric").replace("{0}", fieldName)} />
  );
};

const ValidateRange = (fieldName, min, max, t) => {
  let message;
  if (min && max)
    message = t("validateNumericMinMax")
      .replace("{0}", fieldName)
      .replace("{1}", min.toString())
      .replace("{2}", max.toString());
  else if (min && !max)
    message = t("validateNumericMin")
      .replace("{0}", fieldName)
      .replace("{1}", min.toString());
  else if (max && !min)
    message = t("validateNumericMax")
      .replace("{0}", fieldName)
      .replace("{1}", max.toString());
  else return <div />;

  return <RangeRule min={min} max={max} message={message} />;
};

export {
  ValidateRequired,
  ValidateStringLength,
  ValidateEMail,
  ValidateNumeric,
  ValidateRange,
};

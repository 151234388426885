import React from "react";
import ProductCategoryGrid from "./ProductCategoryGrid";

const ProductCategoryPage = () => {
  return (
    <div className="c-main">
      <div className="c-content">
        <ProductCategoryGrid />
      </div>
    </div>
  );
};
export default ProductCategoryPage;

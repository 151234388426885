import { combineReducers } from "redux";
import { SignedUserReducer } from "./Auth/AuthReducer";

import { LoadingReducer } from "./Shared/LoadingReducer";
import { DashboardReducer } from "./Shared/DashboardReducer";
import { HeaderFilterReducer } from "./Shared/HeaderFilterReducer";
import { SidebarHidderReducer } from "./Shared/SidebarHidderReducer";
import { ProductTreeReducer } from "./Managament/ProductTreeReducer";
import { AddAlarmReducer } from "./Alarm/AddAlarmReducer";
import { RecipeRangeReducer } from "./RecipeRange/RecipeRangeReducer";
import { AlarmConnectionReducer } from "./Alarm/AlarmConnectionReducer";
import { CentralTracingReducer } from "./CentralTracing/CentralTracingReducer";
import { DashboardWidgetReducer } from "./DashboardWidget/DashboardWidgetReducer";
import { AnalysisReducer } from "./Analysis/AnalysisReducer";

const rootReducer = combineReducers({
  LoadingReducer,
  SidebarHidderReducer,
  SignedUserReducer,
  ProductTreeReducer,
  AddAlarmReducer,
  RecipeRangeReducer,
  AlarmConnectionReducer,
  DashboardReducer,
  CentralTracingReducer,
  HeaderFilterReducer,
  DashboardWidgetReducer,
  AnalysisReducer,
});

export default rootReducer;

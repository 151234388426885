import React from "react";
import "moment/locale/tr";
import CentralTracing from "../../CentralTracing/CentralTracing";

const HomePage = () => {
  return (
    <div>
      <CentralTracing />
    </div>
  );
};
export default HomePage;

import AccountTypePage from "../../../views/Definations/AccountType/AccountTypePage";
import ProductCategoryPage from "../../../views/Definations/ProductCategory/ProductCategoryPage";
import ProductUnitPage from "../../../views/Definations/ProductUnit/ProductUnitPage";
import { MenuType } from "../../enums/MenuType";
import { paths } from "../../resources/paths";
import ProductTypePage from "./../../../views/Definations/ProductType/ProductTypePage";
import TagCustomTypePage from "./../../../views/Definations/TagCustomType/TagCustomTypePage";
import TagGroupPage from "./../../../views/Definations/TagGroup/TagGroupPage";
import UnitPage from "./../../../views/Definations/Unit/UnitPage";
import LocationTypePage from "./../../../views/Definations/LocationType/LocationTypePage";
import CentralTypePage from "../../../views/Definations/CentralType/CentralTypePage";
import CommunicationDataTypePage from "./../../../views/Definations/CommunicationDataType/CommunicationDataTypePage";
import BrandPage from "./../../../views/Definations/Brand/BrandPage";
import LocationUnitPage from "./../../../views/Definations/LocationUnit/LocationUnitPage";
import DashboardDefinationPage from "./../../../views/Definations/Dashboard/DashboardDefinationPage";
import TagCustomTypeGroupPage from "../../../views/Definations/TagCustomTypeGroup/TagCustomTypeGroupPage";
import ImportFileTypePage from "../../../views/Definations/ImportFileType/ImportFileTypePage";

export const DefinationItems = (t) => [
  {
    key: "1",
    icon: "fad fa-folders",
    text: t("productUnit"),
    path: paths.definations.productUnitDefination,
    render: <ProductUnitPage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "2",
    icon: "fad fa-box-full",
    text: t("productCategory"),
    path: paths.definations.productCategoryDefination,
    render: <ProductCategoryPage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "3",
    icon: "fad fa-file-invoice",
    text: t("productType"),
    path: paths.definations.productTypeDefination,
    render: <ProductTypePage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "4",
    icon: "fad fa-copy",
    text: t("unit"),
    path: paths.definations.unitDefination,
    render: <UnitPage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "5",
    icon: "fad fa-tags",
    text: t("regModelTagGroup"),
    path: paths.definations.tagGroupDefination,
    render: <TagGroupPage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "6",
    icon: "fas fa-tag",
    text: t("tagCustomType"),
    path: paths.definations.tagCustomTypeDefination,
    render: <TagCustomTypePage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "7",
    icon: "fad fa-user-tag",
    text: t("accountType"),
    path: paths.definations.accountTypeDefination,
    render: <AccountTypePage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "8",
    icon: "fad fa-map-marker-edit",
    text: t("locationType"),
    path: paths.definations.locationTypeDefination,
    render: <LocationTypePage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "9",
    icon: "fad fa-send-backward",
    text: t("centralType"),
    path: paths.definations.centralType,
    render: <CentralTypePage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "10",
    icon: "fad fa-send-backward",
    text: t("communicationDataType"),
    path: paths.definations.communicationDataType,
    render: <CommunicationDataTypePage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "11",
    icon: "fad fa-send-backward",
    text: t("brand"),
    path: paths.definations.brand,
    render: <BrandPage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "12",
    icon: "fad fa-send-backward",
    text: t("locationUnit"),
    path: paths.definations.locationUnit,
    render: <LocationUnitPage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "13",
    icon: "fad fa-send-backward",
    text: t("dashboardDefination"),
    path: paths.definations.dashboard,
    render: <DashboardDefinationPage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "14",
    icon: "fas fa-tag",
    text: t("tagCustomTypeGroup"),
    path: paths.definations.tagCustomTypeGroupDefination,
    render: <TagCustomTypeGroupPage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "15",
    icon: "fas fa-tag",
    text: t("importFileType"),
    path: paths.definations.importFileType,
    render: <ImportFileTypePage />,
    MenuType: MenuType.MAINMENU,
  },
];

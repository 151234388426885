import React from "react";
import { Button } from "antd";
import TreeView from "./TreeView.js";
import { ProductStepForm } from "./Components/ProductStepForm.js";
import CentralSelect from "./Components/CentralSelect";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, useHistory } from "react-router-dom";
import { paths } from "./../../../helper/resources/paths";
import SbomProductDetailPage from "./Components/SbomProductDetails/SbomProductDetailPage";
import SbomProductCustomDetailGrid from "./Components/SbomProductDetails/SbomProductCustomDetailGrid";
import SbomProductCommunicationPage from "./Components/SbomProductDetails/SbomProductCommunicationPage";
import { TurnTreeView } from "./../../../redux/actions/Sbom/TreeViewActions";
import UpdateSbom from "./Components/New/UpdateSbom.js";
import SbomProductMovementGrid from "./Components/SbomProductDetails/SbomProductMovementGrid.js";
import { useTranslation } from "react-i18next";

const ProductTree = () => {
  const { t } = useTranslation();
  let productTreeState = useSelector((p) => p.ProductTreeReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div className="c-main">
      <div className="c-content">
        <div className="p-tree__header">
          <strong className="p-tree__header-title">{t("activeCentral")}</strong>
          <CentralSelect />
        </div>
      </div>
      <div
        className="c-product__tree"
        style={{
          display: productTreeState.selectedCentralId > 0 ? null : "none",
        }}
      >
        <TreeView />
        <div className="c-content">
          <ProductStepForm />
          <Switch>
            <Route path={"/Managment/ProductTree/Sbom/"}>
              <div className="c-button__bottom">
                <Button
                  className="c-button__close"
                  type="primary"
                  danger
                  size="small"
                  onClick={() =>
                    dispatch(
                      TurnTreeView(productTreeState.selectedCentralId, history)
                    )
                  }
                >
                  <i className="fas fa-times"></i>
                </Button>
              </div>
              <Route
                exact
                path={paths.managament.productTree.sbomProductDetailPage}
                component={SbomProductDetailPage}
              />
              <Route
                exact
                path={paths.managament.productTree.sbomProductMovement}
                component={SbomProductMovementGrid}
              />
              <Route
                exact
                path={paths.managament.productTree.sbomProductCustomDetailPage}
                component={SbomProductCustomDetailGrid}
              />
              <Route
                exact
                path={paths.managament.productTree.sbomProductCommunicationPage}
                component={SbomProductCommunicationPage}
              />
              <Route
                exact
                path={paths.managament.productTree.updateSbom}
                component={UpdateSbom}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};
export default ProductTree;

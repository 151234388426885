import React from "react";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../helper/Grid/GridCreatorMixin";
import AccountSelectBox from "./Components/AccountSelectBox";
import AccountFieldSelectBox from "./Components/AccountFieldSelectBox";
import CentralSelectBox from "./Components/CentralSelectBox";
import { useTranslation } from "react-i18next";

const RecipeRangeGrid = React.memo(
  () => {
      const { t } = useTranslation();
    const pageGrid = {
      apiController: "RecipeRange",
      apiKey: "Id",
      editTitle: t("tariffInfo"),
      editMode: {
        isActive: true,
        mode: "form",
        allowAdding: true,
        allowDeleting: true,
        allowUpdating: true,
        useIcons: true,
        containerColCount: 1,
        validationSummary: true,
      },
      columns: [
        {
          dataField: "Code",
          caption: t("tariffCode"),
          fieldValidation: {
            isRequired: true,
            stringLength: {
              min: 5,
            },
          },
        },
        {
          dataField: "Name",
          caption: t("tariffName"),
          fieldValidation: {
            isRequired: true,
          },
        },
        {
          dataField: "StartDate",
          caption: t("startDate"),
          editorType: "dxDateBox",
        },
        {
          dataField: "EndDate",
          caption: t("endDate"),
          editorType: "dxDateBox",
        },
        {
          dataField: "CurrencyTypeId",
          caption: t("priceType"),
          dataType: "number",
          relationContent: {
            controller: "CurrencyType",
            display: "Name",
          },
        },
        {
          dataField: "AccountId",
          caption: t("company"),
          dataType: "number",
          relationContent: {
            controller: "Account",
            display: "Value",
          },
          editCellComponent: (cell) => (
            <AccountSelectBox
              setValue={(id) => {
                cell.setValue(id);
              }}
              value={cell.value}
            />
          ),
        },
        {
          dataField: "AccountFieldId",
          caption: t("area"),
          dataType: "number",
          relationContent: {
            controller: "AccountField",
            display: "Name",
          },
          editCellComponent: (cell) => (
            <AccountFieldSelectBox
              setValue={(id) => {
                cell.setValue(id);
              }}
              value={cell.value}
            />
          ),
        },
        {
          dataField: "CentralId",
          caption: t("central"),
          dataType: "number",
          relationContent: {
            controller: "Central",
            display: "Name",
          },
          editCellComponent: (cell) => (
            <CentralSelectBox
              setValue={(id) => {
                cell.setValue(id);
              }}
              value={cell.value}
            />
          ),
        },
      ],
    };

    const rangePriceListGrid = {
      apiController: "RangePriceList",
      apiKey: "Id",
      editTitle: t("priceDetail"),
      subGridFeature: {
        loadUrl: "GetList",
        relationKey: "RecipeRangeId",
        insertUrl: "Insert",
        updateUrl: "Update",
        removeUrl: "Delete",
      },
      columns: [
        {
          dataField: "Code",
          caption: t("code"),
          fieldValidation: {
            isRequired: true,
            stringLength: {
              min: 2,
            },
          },
        },
        {
          dataField: "Name",
          caption: t("name"),
          fieldValidation: {
            isRequired: true,
            stringLength: {
              min: 2,
            },
          },
        },
        {
          dataField: "Value",
          caption: t("value"),
          dataType: "number",
          editorType: "dxNumberBox",
        },
        {
          dataField: "ReactiveProduction",
          caption: t("reactiveProduction"),
          dataType: "number",
          editorType: "dxNumberBox",
        },
        {
          dataField: "ReactiveConsumption",
          caption: t("reactiveConsumption"),
          dataType: "number",
          editorType: "dxNumberBox",
        },
        {
          dataField: "RecipeTypeId",
          caption: t("tariffType"),
          dataType: "number",
          relationContent: {
            controller: "RecipeType",
            display: "Name",
          },
        },
      ],
    };

    let gridData = CreateGridData(pageGrid);
    const rangePriceListGridData = CreateGridData(rangePriceListGrid);

    gridData.subGrids = [
      {
        tabCaption: t("priceDetail"),
        tabGridFeature: rangePriceListGridData,
      },
    ];

    return (
      <div>
        <RenderMasterGrid gridData={gridData} />
      </div>
    );
  },
  () => true
);

export default RecipeRangeGrid;

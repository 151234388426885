import React, {useState,useEffect } from "react";
import {
  DatePicker,
  Button,
  Tooltip,
  Menu,
  Dropdown,
  List,
  Switch,
  Tag,
  Radio,
} from "antd";
import "moment/locale/tr";
import dateLang from "antd/es/date-picker/locale/en_US";
import moment from "moment";
import { useSelector } from "react-redux";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

const HeaderFilter = ({ pageName, dateOnChange, updateDashboard }) => {
  const [visible, setVisible] = useState(false);
  const [counter, setCounter] = useState(0);
  const [selectedCounter, setSelectedCounter] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  var headerFilterState = useSelector((p) => p.HeaderFilterReducer);
  const { t } = useTranslation();

  const counters = [
    {
      name: "30 Saniye",
      value: 30,
    },
    {
      name: "1 Dakika",
      value: 60,
    },
    {
      name: "5 Dakika",
      value: 300,
    },
    {
      name: "15 Dakika",
      value: 900,
    },
    {
      name: "1 Saat",
      value: 3600,
    },
  ];

  useEffect(() => {
    let interval;

    if (isRunning && counter > 0) {
      interval = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isRunning, counter]);

  useEffect(() => {
    if (counter === 0) {
      setCounter(selectedCounter)
      updateDashboard()
    }
  }, [counter]);

  const refreshMenu = (
    <Menu>
      <Menu.Item key="item1" className="c-page__refresh-item" onClick={updateDashboard}>
        Şimdi Yenile
      </Menu.Item>
      <Menu.Item key="item2" className="c-page__refresh-item">
        Otomatik Yenile
        <div style={{ float: "right", paddingLeft: "1vh" }}>
          <Switch size="small" checked={isRunning} onChange={e => setIsRunning(e)} />
        </div>
      </Menu.Item>
      <Menu.SubMenu key="countersSubMenu" title="Sayac Seç">
        <Radio.Group 
        value={counter}
        onChange={e => {
          setCounter(e.target.value)
          setSelectedCounter(e.target.value)
        }}
        >
          {counters.map((item) => (
            <Radio key={item.value} value={item.value}>
              {item.name}
            </Radio>
          ))}
        </Radio.Group>
      </Menu.SubMenu>
    </Menu>
  );
  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  return (
    <>
      <div className="c-page__header">
        <strong className="c-page__header-title">{pageName}</strong>
        <div className="c-page__header-filter">
          <div className="c-page__header-filter-item">
            <RangePicker
              locale={dateLang}
              onChange={dateOnChange}
              format="DD/MM/YYYY"
              defaultValue={[
                moment(headerFilterState.startDate, "DD/MM/YYYY"),
                moment(headerFilterState.endDate, "DD/MM/YYYY"),
              ]}
              bordered={false}
            />
          </div>
          <div className="c-page__header-filter-item">
            <Dropdown
              overlay={refreshMenu}
              trigger={["hover"]}
              visible={visible}
              onVisibleChange={handleVisibleChange}
            >
              <Button
                className="c-page__header-filter-date-button"
                size="small"
                onClick={updateDashboard}
              >
                <ReloadOutlined />
              </Button>
            </Dropdown>
          </div>
          <div className="c-page__header-filter-item">
            <span className="c-page__header-filter-date-name">
              {t("period")}
            </span>
            <Tooltip
              placement="bottom"
              color="#32d2c9"
              title={t("facilitySpecificPeriodVal")}
            >
              <Button
                className="c-page__header-filter-date-button"
                size="small"
              >
                {t("Ö")}
              </Button>
            </Tooltip>
            <Tooltip
              placement="bottom"
              color="#32d2c9"
              title={t("dailyValues")}
            >
              <Button
                className="c-page__header-filter-date-button"
                size="small"
              >
                {t("G")}
              </Button>
            </Tooltip>
            <Tooltip
              placement="bottom"
              color="#32d2c9"
              title={t("monthlyValues")}
            >
              <Button
                className="c-page__header-filter-date-button"
                size="small"
              >
                {t("A")}
              </Button>
            </Tooltip>
            <Tooltip
              placement="bottom"
              color="#32d2c9"
              title={t("yearlyValues")}
            >
              <Button
                className="c-page__header-filter-date-button"
                size="small"
              >
                {t("Y")}
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
};
export default HeaderFilter;

import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { useRecoilState, useRecoilStateLoadable } from "recoil";
import { analysisPageKey, graphDataState } from "./recoil/RecoilActions";
import { useTranslation } from "react-i18next";

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/modules/data")(Highcharts);

const customizeLabel = (e) => {
  var tt = new moment(e);
  return tt;
};
var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const AnalysisPageGraph = () => {
  const { t } = useTranslation();
  const chart = useRef();
  const [options, setOptions] = useState();
  const [newData, setNewData] = useState([]);

  const [key, setKey] = useRecoilState(analysisPageKey);
  const graphData = useRecoilStateLoadable(graphDataState)[0];

  const setNewDataFunc = (_visible = true) => {
    const groupByCategory = graphData.contents.reduce((group, item) => {
      const { tagCode } = item;
      group[tagCode] = group[tagCode] ?? [];
      const subset = Object.fromEntries(
        Object.entries(item).filter(([key]) =>
          ["createdAt", "calcValue"].includes(key)
        )
      );

      let setData = [
        new Date(customizeLabel(subset.createdAt)).getTime(),
        subset.calcValue,
      ];
      group[tagCode].push(setData);
      return group;
    }, {});

    const unitByGroupData = groupBy(graphData.contents, "unit");
    setNewData(
      Object.keys(groupByCategory).map((p) => {
        return {
          name: p,
          data: groupByCategory[p],
          yAxis: Object.keys(unitByGroupData).indexOf(p.split("~")[1]) + 1,
          visible: _visible,
          tooltip: {
            valueSuffix: p.split("%")[1],
          },
        };
      })
    );
  };

  React.useEffect(() => {
    if (graphData.state === "hasValue" && graphData.contents != null) {
      if (graphData.contents.length == 0) {
        setNewData([]);
        setKey(key + 1);
        return;
      }

      const unitByGroupData = groupBy(graphData.contents, "unit");
      const yAxis = [
        {
          lineWidth: 1,
          opposite: false,
        },
      ];

      setNewDataFunc(true);

      let _options = {
        chart: {
          backgroundColor: "transparent",
          zoomType: "x",
        },
        plotOptions: {
          series: {
            events: {
              legendItemClick: function (event) {
                var series = this.chart.series;
                if (this.name === t("viewAll")) {
                  setNewDataFunc(true);
                  return false;
                } else if (this.name === t("removeAll")) {
                  setNewDataFunc(false);
                  return false;
                } else {
                  return true;
                }
              },
            },
          },
        },
        tooltip: {
          followPointer: false,
          backgroundColor: "rgba(255,255,255,0)",
          borderWidth: 0,
          shadow: false,
          useHTML: true,
          positioner: function (labelWidth, labelHeight, point) {
            const windowWidth = window.innerWidth;
            if (windowWidth > 1600) {
              if (point.plotX < 700) {
                return {
                  x: point.plotX,
                };
              } else {
                return {
                  x: point.plotX - labelWidth + 100,
                };
              }
            } else {
              if (point.plotX < 500) {
                return {
                  x: point.plotX,
                };
              } else {
                return {
                  x: point.plotX - labelWidth + 100,
                };
              }
            }
          },
          formatter: function (value) {
            var s = "";
            var date = new Date(this.x);

            var year = date.getUTCFullYear();
            var month = date.getUTCMonth() + 1;
            var day = date.getUTCDate();
            var hours = date.getUTCHours();
            var minutes = date.getUTCMinutes();
            var seconds = date.getUTCSeconds();

            month = month < 10 ? "0" + month : month;
            day = day < 10 ? "0" + day : day;
            hours = hours < 10 ? "0" + hours : hours;
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            var finalDate =
              year + "-" + month + "-" + day + " " + hours + ":" + minutes;

            var sortedPoints = this.points.sort(function (a, b) {
              return a.y > b.y ? -1 : a.y < b.y ? 1 : 0;
            });
            sortedPoints.forEach((element) => {
              const names = element.series.name.split("~");
              s +=
                '<div class="MyChartTooltipRow">' +
                '<div class="MyChartTooltipRowIcon" style="background-color:' +
                element.color +
                '"></div>' +
                '<div class="MyChartTooltipRowTitle" style="color:' +
                element.color +
                '">' +
                names[0] +
                "</div>" +
                '<div class="MyChartTooltipRowValue" style="color:' +
                element.color +
                '">' +
                element.y.toFixed(names[2]) +
                " " +
                names[1] +
                "</div>" +
                "</div>";
            });

            return (
              '<div class="MyChartTooltip" >' +
              '<div class="MyChartTooltipHeader">' +
              finalDate +
              "</div>" +
              '<div class="MyChartTooltipBody">' +
              s +
              "</div>" +
              "</div>"
            );
          },
        },
        title: {
          text: "",
          style: {
            display: "none",
          },
        },
        xAxis: {
          type: "datetime",
        },
        yAxis: yAxis,
        series: [
          { name: t("viewAll") },
          { name: t("removeAll") },
        ].concat(newData),
        legend: {
          enabled: true,
          align: "center",
          verticalAlign: "bottom",
          x: 0,
          y: 0,
          maxHeight: 85,
          itemStyle: {
            color: "#454545",
            fontWeight: "normal !important",
            fontSize: "12px",
          },
          labelFormatter: function () {
            return this.name.split("~")[0];
          },
        },
        navigator: {
          margin: 60,
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  floating: false,
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom",
                  x: 0,
                  y: 0,
                },
                yAxis: [
                  {
                    labels: {
                      align: "right",
                      x: 0,
                      y: -6,
                    },
                    showLastLabel: false,
                  },
                  {
                    labels: {
                      align: "left",
                      x: 0,
                      y: -6,
                    },
                    showLastLabel: false,
                  },
                  {
                    visible: false,
                  },
                ],
              },
            },
          ],
        },
      };

      Object.keys(unitByGroupData).forEach((item, index) => {
        _options?.yAxis.push({
          opposite: index % 2 == 1,
          title: {
            text: item,
            style: {
              color: Highcharts.getOptions().colors[index + 2],
            },
          },
        });
      });
      setOptions(_options);
      setKey(key + 1);
    }
  }, [graphData]);

  useEffect(() => {
    if (options) {
      setOptions({
        ...options,
        series: [
          { name: t("viewAll") },
          { name: t("removeAll") },
        ].concat(newData),
      });
      setKey(key + 1);
    }
  }, [newData]);

  return (
    <div
      className="c-select__graph"
      hidden={options?.series.length <= 2}
    >
      <HighchartsReact
        containerProps={{ style: { height: "100%", width: "100%" } }}
        ref={chart}
        constructorType={"stockChart"}
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

export default AnalysisPageGraph;

import React from "react";
import "devextreme-react/text-area";
import Form, { ButtonItem, ButtonOptions, Item } from "devextreme-react/form";
import {
  ValidateNumeric,
  ValidateEMail,
  ValidateStringLength,
  ValidateRange,
} from "./CustomValidation";
import { useTranslation } from "react-i18next";
import { setSubGridApiDefault } from "../../../config/fetchApiConfig";

const EditForm = ({ gridData }) => {
  const { t } = useTranslation();

  let editMode = gridData.editMode;
  let allGroupItems = [];
  for (const column in gridData.columns) {
    let activeItem = gridData.columns[column];
    if (
      activeItem.groupInfo &&
      allGroupItems.filter(
        (nq) => nq.groupIndex === activeItem.groupInfo?.groupIndex
      ).length <= 0
    )
      allGroupItems.push(activeItem.groupInfo);
  }

  const _onSubmit = async (event) => {
    const formData = new FormData(event.currentTarget);
    event.preventDefault();

    gridData.onSubmit(formData);
  };

  return (
    <div className="c-edit">
      <h3 className="c-edit__form-title">{gridData.editTitle}</h3>
      <form onSubmit={_onSubmit}>
        <Form
          showValidationSummary={editMode.validationSummary}
          colCount={editMode.containerColCount}
          allowAdding={true}
          allowDeleting={editMode.allowDeleting}
          allowUpdating={editMode.allowUpdating}
          formData={gridData.formData}
        >
          {allGroupItems
            .sort((a, b) => a.groupIndex - b.groupIndex)
            .map((item, index) => {
              let allColumns = gridData.columns.filter(
                (nq) =>
                  nq.groupInfo?.groupIndex === item.groupIndex &&
                  nq.editorType !== "dxHidden"
              );
              return (
                <Item
                  itemType="group"
                  colCount={item.groupColCount}
                  colSpan={item.groupColSpan}
                  caption={item.groupCaption}
                  cssClass={item.groupClass}
                  key={index}
                >
                  {allColumns.map((column, index) => {
                    if (column.relationContent) {
                      column.editorType = "dxSelectBox";
                      column.editorOptions = {
                        dataSource: setSubGridApiDefault(
                          column.relationContent.controller,
                          column.relationContent.key ?? "id",
                          column.relationContent.loadUrl,
                          null,
                          null,
                          null,
                          column.relationContent.customParam
                        ),
                        displayExpr: column.relationContent.display,
                        valueExpr: column.relationContent.value,
                        showClearButton: true,
                        searchEnabled: true,
                      };
                    }
                    let writeEditorType =
                      column.editorType === "dxHtml" ||
                      column.editorType === "dxImage" ||
                      column.editorType === "dxMap" ||
                      column.editorType === "dxTag" ||
                      column.editorType === undefined;
                    return (
                      <Item
                        dataField={column.dataField}
                        name={column.dataField}
                        label={{ text: column.caption }}
                        render={
                          column.customEditCellComponent
                            ? column.customEditCellComponent
                            : undefined
                        }
                        editorType={
                          !writeEditorType ? column.editorType : undefined
                        }
                        editorOptions={column.editorOptions}
                        cssClass={column.customCssClass}
                        visible={column.visible}
                        disabled={column.disabled}
                        key={index}
                      >
                        {column.fieldValidation?.isEmail &&
                          column.editorType !== "dxHidden" &&
                          column.editorType !== "dxHtml" &&
                          column.editorType !== "dxImage" &&
                          ValidateEMail(column.caption, t)}
                        {column.fieldValidation?.isNumeric &&
                          column.editorType !== "dxHidden" &&
                          column.editorType !== "dxHtml" &&
                          column.editorType !== "dxImage" &&
                          ValidateNumeric(column.caption, t)}
                        {column.fieldValidation?.stringLength &&
                          column.editorType !== "dxHidden" &&
                          column.editorType !== "dxHtml" &&
                          column.editorType !== "dxImage" &&
                          ValidateStringLength(
                            column.caption,
                            column.fieldValidation.stringLength.min,
                            column.fieldValidation.stringLength.max,
                            t
                          )}
                        {column.fieldValidation?.numericRange &&
                          column.editorType !== "dxHidden" &&
                          column.editorType !== "dxHtml" &&
                          column.editorType !== "dxImage" &&
                          ValidateRange(
                            column.caption,
                            column.fieldValidation.numericRange.min,
                            column.fieldValidation.numericRange.max,
                            t
                          )}
                        {column.fieldValidation?.asyncValidation &&
                          column.editorType !== "dxHidden" &&
                          column.editorType !== "dxHtml" &&
                          column.editorType !== "dxImage" &&
                          column.fieldValidation.asyncValidation()}
                      </Item>
                    );
                  })}
                </Item>
              );
            })}
          <Item
            itemType="group"
            caption={" "}
            colSpan={
              allGroupItems.reverse((a) => a.groupColCount)[0].groupColCount
            }
          >
            <ButtonItem horizontalAlignment="right">
              <ButtonOptions
                text={t("save")}
                useSubmitBehavior={true}
                type="success"
              />
            </ButtonItem>
          </Item>
        </Form>
      </form>
    </div>
  );
};

export default EditForm;

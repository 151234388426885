import React from "react";
import ProductTypeGrid from "./ProductTypeGrid";

const ProductTypePage = () => {
  return (
    <div className="c-main">
      <div className="c-content">
        <ProductTypeGrid />
      </div>
    </div>
  );
};
export default ProductTypePage;

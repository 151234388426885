import {
  CLEAR_ALARM_TYPE,
  SET_ALARM_DETAIL,
  SET_ALARM_TRIGGER,
  SET_ALARM_TYPE,
} from "./../../actions/actionTypes";

const addAlarmState = {
  alarmTypeId: 0,
  code: "",
  name: "",
  intervalTime: 0,
  priority: 0,
  isSunrise: false,
  alarmTriggerDetails: [],
};

export const AddAlarmReducer = (state = addAlarmState, action) => {
  switch (action.type) {
    case SET_ALARM_TRIGGER:
      return { ...state, [action.payload.dataField]: action.payload.value };
    case SET_ALARM_TYPE:
      return { ...state, alarmTypeId: action.payload };
    case SET_ALARM_DETAIL:
      return {
        ...state,
        alarmTriggerDetails: [...state.alarmTriggerDetails, action.payload],
      };
    case CLEAR_ALARM_TYPE:
      return addAlarmState;
    default:
      return state;
  }
};

import { P } from "@antv/g2plot";

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export const dynamicSort2 = (data, groupKey, property) => {
  var retArray = [];

  var groupData = groupBy(data, (p) => p[groupKey]);
  groupData.forEach((element) =>
    retArray.push(...element.sort(dynamicSortNumber("groupName")))
  );
  return new Promise((resolve, reject) => {
    console.log(retArray);
    resolve(retArray);
  });
};

export function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

export function dynamicSortNumber(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result =
      parseInt(a[property]) < parseInt(b[property])
        ? -1
        : parseInt(a[property]) > parseInt(b[property])
        ? 1
        : 0;
    return result * sortOrder;
  };
}

export function extend(a, b) {
  for (var key in b) if (b.hasOwnProperty(key)) a[key] = b[key];
  return a;
}
export function selectProps(...props) {
  return function (obj) {
    const newObj = {};
    props.forEach((name) => {
      newObj[name] = obj[name];
    });

    return newObj;
  };
}
export function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);

  var year = result.getFullYear();

  var month = (1 + result.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  var day = result.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return day + "/" + month + "/" + year;
}
export function doInputEvent(someInput, newValue) {
  let input = someInput;
  let lastValue = input.value;
  input.value = newValue;
  let event = new Event("input", { bubbles: true });
  event.simulated = true;
  let tracker = input._valueTracker;
  if (tracker) {
    tracker.setValue(lastValue);
  }
  input.dispatchEvent(event);
}

export const SetGlobalStringFunctions = () => {
  String.prototype.replaceAll = function (search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, "g"), replacement);
  };
};

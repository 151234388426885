import fetchApi from "axios";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { getBaseApiUrl } from "./applicationConfig";
import { GET_SIGNED_USER } from "../redux/actions/actionTypes";

const fetchApiConfig = {
  withCredentials: false,
  timeout: 500000,
  baseURL: createBaseUrl(),
  headers: {
    common: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("token")}`
    },
  },
};

export function createBaseUrl() {
  let apiRootUrl = getBaseApiUrl();
  return apiRootUrl;
}

export function setFetchApiToken(token) {
  fetchApi.defaults.baseURL = fetchApiConfig.baseURL;
  fetchApi.defaults.timeout = fetchApiConfig.timeout;
  fetchApi.defaults.withCredentials = fetchApiConfig.withCredentials;
}

export function setFetchApiDefault(setLoading, dispatch,history) {
  fetchApi.defaults.baseURL = fetchApiConfig.baseURL;
  fetchApi.defaults.timeout = fetchApiConfig.timeout;
  fetchApi.defaults.withCredentials = fetchApiConfig.withCredentials;
  fetchApi.defaults.headers.common = fetchApiConfig.headers.common;

  fetchApi.interceptors.request.use(
    (config) => {
      dispatch(setLoading(true));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  fetchApi.interceptors.response.use(
    (response) => {
      dispatch(setLoading(false));
      return response;
    },
    (error) => {
      dispatch(setLoading(false));
      if (error.response && error.response.status === 401) {
          localStorage.setItem("isLogin", false);
          localStorage.setItem("token", "");
          dispatch({
            type: GET_SIGNED_USER,
            payload: { token: "", isLogin: false, user: {} },
          });
          history.push("/Login");
      }
      return Promise.reject(error);
    }
  );
}
export function setSubGridApiDefault(
  controller,
  key,
  loadUrl,
  insertUrl,
  modUrl,
  delUrl,
  loadParams
) {
  let baseUrl = createBaseUrl();
  return createStore({
    key: key,
    loadUrl: `${baseUrl}/api/${controller}/${loadUrl}`,
    loadMethod: "GET",
    loadParams: loadParams,
    insertUrl: `${baseUrl}/api/${controller}/${insertUrl}`,
    insertMethod: "POST",
    updateUrl: `${baseUrl}/api/${controller}/${modUrl}`,
    updateMethod: "PUT",
    deleteUrl: `${baseUrl}/api/${controller}/${delUrl}`,
    deleteMethod: "DELETE",
    onBeforeSend: (method, ajaxOptions) => {
      if (loadParams) {
        ajaxOptions.headers = {
          ...fetchApiConfig.headers.common,
          ...loadParams,
        };
      } else
        ajaxOptions.headers = {
          ...fetchApiConfig.headers.common,
        };
    }
  });
}

export function CustomGetListStore(controller, key) {
  const baseUrl = createBaseUrl();
  return createStore({
    key: key,
    loadUrl: `${baseUrl}/api/${controller}/GetList`,
    loadMethod: "GET",
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        ...fetchApiConfig.headers.common,
      };
    },
  });
}

export function setGridApiDefault(controller, key, loadParams) {
  const baseUrl = createBaseUrl();
  return createStore({
    key: key,
    loadUrl: `${baseUrl}/api/${controller}/GetList`,
    loadMethod: "GET",
    insertUrl: `${baseUrl}/api/${controller}/Insert`,
    insertMethod: "POST",
    updateUrl: `${baseUrl}/api/${controller}/Update`,
    updateMethod: "PUT",
    deleteUrl: `${baseUrl}/api/${controller}/Delete`,
    deleteMethod: "DELETE",
    loadParams: loadParams,
    onBeforeSend: (method, ajaxOptions) => {
      if (loadParams) {
        ajaxOptions.headers = {
          ...fetchApiConfig.headers.common,
          ...loadParams,
        };
      } else
        ajaxOptions.headers = {
          ...fetchApiConfig.headers.common,
        };
    },
  });
}
export function getImageUrl(imageUrl) {
  if (!imageUrl) return imageUrl;
  let baseUrl = createBaseUrl();
  if (imageUrl.startsWith("data")) return imageUrl;
  if (imageUrl.substring(0, 1) === "/") return baseUrl + imageUrl;
  else return baseUrl + "/" + imageUrl;
}

function updateOptions(options) {
  const update = { ...options };
  update.headers = {
    ...update.headers,
    Authorization: `Bearer ${localStorage.getItem("token")}`
  };
  return update;
}

export function fetcher(url, options = {}) {
  return fetch(url, updateOptions(options));
}

export default fetchApiConfig;

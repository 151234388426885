import { SelectBox } from "devextreme-react";
import React from "react";
import { CalculateFunctionTypeSource } from "../../../helper/enums/EnumDataSource";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../helper/Grid/GridCreatorMixin";
import { useTranslation } from "react-i18next";

const UnitGrid = () => {
  const { t } = useTranslation();
  const pageGrid = {
    apiController: "Unit",
    apiKey: "Id",
    editTitle: t("unitInfo"),
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 1,
      validationSummary: true,
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 1,
          },
        },
      },
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 1,
          },
        },
      },
      {
        dataField: "CalculateFunctionType",
        caption: t("calculateFunction"),
        dataType: "number",
        localRelationContent: {
          dataSource: CalculateFunctionTypeSource,
        },
        editCellComponent: (cell) => {
          return (
            <SelectBox
              dataSource={CalculateFunctionTypeSource}
              valueExpr="key"
              grouped={true}
              onValueChanged={({ value }) => cell.setValue(value)}
              searchEnabled={true}
              defaultValue={cell.value}
              displayExpr="value"
              showClearButton={true}
            />
          );
        },
      },
    ],
  };

  return <RenderMasterGrid gridData={CreateGridData(pageGrid)} />;
};
export default UnitGrid;

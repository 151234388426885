import React from "react";
import { CreateGridData } from "../../../../../helper/Grid/GridCreatorMixin";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import EditForm from "./../../../../../components/Shared/form/EditForm";
import axios from "axios";
import { Button } from "antd";
import messageError from "./../../../../../helper/messages/errorMessage";
import { getApiUrl } from "./../../../../../config/applicationConfig";
import { paths } from "./../../../../../helper/resources/paths";
import messageSuccess from "./../../../../../helper/messages/successMessage";
import sbomProductDetailFields from "../../../../../assets/localData/sbomProductDetailField.json";
import DataSource from "devextreme/data/data_source";
import { setSubGridApiDefault } from "../../../../../config/fetchApiConfig";
import { SelectBox } from "devextreme-react";
import { useTranslation } from "react-i18next";

const SbomProductDetailGrid = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const productTreeState = useSelector((p) => p.ProductTreeReducer);

  const modelDataSource = new DataSource({
    store: setSubGridApiDefault("Model", "id", "GetRelationList"),
    group: "brandName",
  });

  const [formData, setFormData] = React.useState();
  const [formDataComm, setFormDataComm] = React.useState();

  const getSbomProductDetail = () => {
    if (
      productTreeState.selectedTreeView.sbomId &&
      productTreeState.selectedTreeView.productId
    ) {
      axios
        .get(getApiUrl("/api/SbomProductDetail/Get"), {
          params: {
            sbomId: productTreeState.selectedTreeView.sbomId,
            productId: productTreeState.selectedTreeView.productId,
          },
        })
        .then((res) => res.data)
        .then((res) => {
          setFormData(res);
          if (res && res.id) {
            getSbomProductComm(res.id);
          }
        });
    }
  };

  const getSbomProductComm = (_sbomProductDetailId) => {
    axios
      .get(getApiUrl("/api/SbomProductCommunication/Get"), {
        params: {
          sbomProductDetailId: _sbomProductDetailId,
        },
      })
      .then((res) => res.data)
      .then((res) => {
        setFormDataComm(res);
      });
  };

  const _onSubmit = (formData) => {
    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    object["sbomId"] = productTreeState.selectedTreeView.sbomId;
    object["productId"] = productTreeState.selectedTreeView.productId;

    var json = JSON.stringify(object);
    var sendFormData = new FormData();
    sendFormData.append("values", json);

    axios
      .post(getApiUrl("/api/SbomProductDetail/Insert"), sendFormData, {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      })
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          setFormData(res.data);
        }
        messageSuccess(res.message);
      })
      .catch((err) => messageError("Bir sorunla karşılaşıldı"));
  };

  const checkFieldDisabled = (pageGrid) => {
    var abstractProductAllColumn = [
      "serialNumber",
      "customName",
      "isActiveBySerialNo",
      "inverterRatedPower",
      "frontPrimaryDevice"
    ];
    var productAllColumn = [
      "serialNumber",
      "customName",
      "productCommunicationTypeId",
      "modelId",
      "isActiveBySerialNo",
      "inverterRatedPower",
      "frontPrimaryDevice"
    ];
    pageGrid.columns.forEach((element) => {
      const field = sbomProductDetailFields.find(
        (p) => p.fieldCode === productTreeState.selectedTreeView.categoryCode
      );
      let dataField = element.dataField;

      if (!field) {
        if (productTreeState.selectedTreeView.isProduct) {
          element.visible =
            productAllColumn.find(
              (p) => p.toLocaleLowerCase() === dataField.toLocaleLowerCase()
            ) !== undefined;
        } else {
          element.visible =
            abstractProductAllColumn.find(
              (p) => p.toLocaleLowerCase() === dataField.toLocaleLowerCase()
            ) !== undefined;
        }
      } else {
        element.visible =
          field?.fields?.find(
            (p) => p.toLocaleLowerCase() === dataField.toLocaleLowerCase()
          ) !== undefined;
      }
    });
    return pageGrid;
  };

  let pageGrid = {
    apiController: "SbomProductDetail",
    apiKey: "Id",
    editTitle: t("productDetail"),
    loadParams: {
      sbomId: productTreeState.selectedTreeView.sbomId,
      productId: productTreeState.selectedTreeView.productId,
    },
    formData: formData,
    onSubmit: _onSubmit,
    hideIsActive: true,
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 1,
      validationSummary: true,
    },
    columns: [
      {
        dataField: "CustomName",
        caption: t("customName"),
      },
      {
        dataField: "SerialNumber",
        caption: t("SerialNumber"),
      },
      {
        dataField: "isActiveBySerialNo",
        caption: t("isActiveBySerialNo"),
        editorType: "dxCheckBox",
      },
      {
        dataField: "RatedPower",
        caption: t("dcRatedPowerKW"),
        editorType: "dxNumberBox",
      },
      {
        dataField: "CalcRatedPower",
        caption: t("dcCalcRatedPowerKW"),
        editorType: "dxNumberBox",
        disabled: true,
      },
      {
        dataField: "AcPower",
        caption: t("acPower"),
        editorType: "dxNumberBox",
      },
      {
        dataField: "LimitedAcPower",
        caption: t("limitedAcPower"),
        editorType: "dxNumberBox",
      },
      {
        dataField: "Ratio",
        caption: t("ratio"),
        editorType: "dxNumberBox",
      },
      {
        dataField: "PrimaryVoltage",
        caption: t("primaryVoltage"),
        editorType: "dxNumberBox",
      },
      {
        dataField: "SecondaryVoltage",
        caption: t("secondaryVoltage"),
        editorType: "dxNumberBox",
      },
      {
        dataField: "StepRange",
        caption: t("stepRange"),
        editorType: "dxNumberBox",
      },
      {
        dataField: "AgdpOut",
        caption: t("agdpOut"),
        editorType: "dxNumberBox",
      },
      {
        dataField: "MaxPositiveGrade",
        caption: t("maxPositiveGrade"),
        editorType: "dxNumberBox",
      },
      {
        dataField: "MaxNegativeGrade",
        caption: t("maxNegativeGrade"),
        editorType: "dxNumberBox",
      },
      {
        dataField: "ProductCommunicationTypeId",
        caption: t("communicationType"),
        editorType: "dxNumberBox",
        visible: productTreeState.selectedTreeView.isProduct,
        relationContent: {
          controller: "ProductCommunicationType",
          display: "Name",
        },
      },
      {
        dataField: "ModelId",
        caption: t("model"),
        dataType: "number",
        relationContent: {
          controller: "Model",
          display: "Value",
          loadUrl: "GetList",
        },
        editCellComponent: (cell) => {
          return (
            <SelectBox
              dataSource={modelDataSource}
              valueExpr="id"
              grouped={true}
              onValueChanged={({ value }) => cell.setValue(value)}
              searchEnabled={true}
              defaultValue={cell.value}
              displayExpr="name"
              showClearButton={true}
            />
          );
        },
      },
      {
        dataField: "InverterRatedPower",
        caption: t("inverterRatedPower"),
        dataType: "number",
      },
      {
        dataField: "FrontPrimaryDevice",
        caption: t("frontPrimaryDevice"),
        editorType: "dxCheckBox",
      },
    ],
  };

  let gridData = CreateGridData(checkFieldDisabled(pageGrid));

  React.useEffect(() => {
    getSbomProductDetail();
  }, [productTreeState.selectedTreeView.sbomId]);

  return (
    <div>
      <div style={{ marginBottom: 15 }}>
        <Button
          className="c-button__margin-rl"
          hidden={
            !formData ||
            productTreeState.selectedTreeView.categoryCode !== "0004"
          }
          onClick={() => {
            var path =
              paths.managament.productTree.sbomProductCustomDetailPage.replace(
                ":sbomProductDetailId",
                formData.id
              );
            history.push(path);
          }}
        >
          Dizi Detayı
        </Button>
        <Button
          className="c-button__margin-rl"
          hidden={!formData}
          onClick={() => {
            var path =
              paths.managament.productTree.sbomProductCommunicationPage.replace(
                ":sbomProductDetailId",
                formData.id
              );
            history.push(path);
          }}
        >
          {t("addContactDetails")}
        </Button>
        <Button
          className="c-button__margin-rl"
          hidden={!productTreeState.selectedTreeView.isProduct && !formDataComm}
          onClick={() => {
            var path = paths.managament.productTree.sbomProductMovement.replace(
              ":sbomId",
              productTreeState.selectedTreeView.sbomId
            );
            history.push(path);
          }}
        >
          {t("transferRecords")}
        </Button>
      </div>
      <EditForm gridData={gridData} />
    </div>
  );
};
export default SbomProductDetailGrid;

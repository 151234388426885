import { Select as TagInput, Tag } from "antd";
import { Template, TextArea, TextBox } from "devextreme-react";
import DxButton from "devextreme-react/button";
import {
  Button,
  Column,
  DataGrid,
  Editing,
  Export,
  FilterPanel,
  FilterRow,
  Form,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Lookup,
  MasterDetail,
  Paging,
  Popup,
  Scrolling,
  Selection,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";

import {
  Item,
  GroupItem,
  TabbedItem,
  Tab,
  TabPanelOptions,
  ButtonItem,
  ButtonOptions,
} from "devextreme-react/form";
import { Item as TabItem, TabPanel } from "devextreme-react/tab-panel";
import { exportDataGrid } from "devextreme/excel_exporter";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import React, { memo } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import notFound from "../../assets/img/notFound.png";
import {
  ValidateEMail,
  ValidateNumeric,
  ValidateRange,
  ValidateRequired,
  ValidateStringLength,
} from "../../components/Shared/form/CustomValidation";
import {
  getImageUrl,
  setGridApiDefault,
  setSubGridApiDefault,
} from "../../config/fetchApiConfig";
import { KeyboardNavigation } from "devextreme-react/tree-list";
import i18n from "../LanguageSupport/i18nextInit";
import { useSelector } from "react-redux";

const t = (val) => i18n.t(val);

const GridDefaultData = {
  apiController: "",
  hideId: true,
  hideIsActive: false,
  apiKey: "",
  editTitle: "",
  columns: [],
  height: "auto",
  groupPanel: true,
  filterPanel: true,
  isCamalize: true,
  filterRow: true,
  headerFilter: false,
  exportPanel: true,
  columnChooser: true,
  refreshItem: true,
  searchPanel: true,
  showSeo: false,
  selection: true,
  onInitialized: null,
  onOptionChanged: () => {},
  onSelectionChanged: () => {},
  onEditorPreparing: () => {},
  onRowPrepared: undefined,
  selectionMode: "multiple",
  setParam: () => {},
  setRef: () => {},
  toolbarItemRender: null,
  summaries: [],
  param: 0,
  editMode: {
    onChangesChange: () => {},
    isActive: true,
    mode: "form",
    allowAdding: true,
    allowDeleting: true,
    allowUpdating: true,
    allowCopy: true,
    allowAllCopy: false,
    useIcons: true,
    containerColCount: 2,
    validationSummary: false,
    isTabs: false,
    width: 135,
  },
};
const GridEditModeDefaultData = {
  onChangesChange: () => {},
  isActive: true,
  mode: "form",
  allowAdding: true,
  allowDeleting: true,
  allowUpdating: true,
  allowCopy: true,
  allowAllCopy: false,
  useIcons: true,
  containerColCount: 2,
  validationSummary: false,
  isTabs: false,
  width: 135,
};
const GridColumnDefaultData = {
  dataField: "",
  caption: "",
  visibility: true,
  fixed: false,
  dataType: "string",
  multiLanguage: false,
  maxView: 40,
  allowFilter: true,
  editCellVisibility: true,
  isCamalize: true,
  editCellComponent: null,
  columnWidth: null,
  groupIndex: null,
  disabled: false,
};
const GridRelationDefaultData = {
  controller: "",
  key: "Id",
  loadUrl: "GetRelationList",
  value: "Id",
  display: "Name",
  customParam: null,
};
const GridLocalRelationDefaultData = {
  dataSource: {},
  value: "Key",
  display: "Value",
};
const GridColumnGroupDefaultData = {
  groupIndex: 1,
  groupColCount: 2,
  groupColSpan: 2,
};
const CreateGridData = (options) => {
  const settings = Object.assign({}, GridDefaultData, options);
  settings.editMode = Object.assign(
    {},
    GridEditModeDefaultData,
    settings.editMode
  );
  settings.apiKey = settings.isCamalize
    ? Camalize(settings.apiKey)
    : settings.apiKey;
  settings.exportWorkSheet = settings.editTitle;
  settings.exportFileName = GenerateSeoLink(settings.editTitle) + ".xlsx";

  if (!settings.hideIsActive) {
    if (
      settings.columns?.filter(
        (nq) => nq.dataField.toLowerCase() === "isactive"
      )?.length <= 0
    ) {
      settings.columns.push({
        dataField: "IsActive",
        caption: i18n.t("isActive"),
        dataType: "boolean",
      });
    }
  }

  if (!settings.hideId) {
    let setOrder =
      settings.columns?.filter((nq) => nq.sortOrder !== undefined)?.length > 0;
    settings.columns.unshift({
      dataField: "Id",
      caption: "Id",
      dataType: "number",
      fixed: true,
      editorType: "dxHidden",
      sortOrder: !setOrder ? "desc" : undefined,
    });
  }

  if (settings.addSeo) {
    if (settings.addSeoLang) {
      settings.columns.push({
        dataField: `${settings.addSeo}.AbstractLocalization`,
        caption: i18n.t("shortAbstract"),
        visibility: settings.showSeo,
        multiLanguage: true,
        groupInfo: {
          groupIndex: 999,
          groupColSpan: 2,
          groupColCount: 1,
          groupClass: "",
          groupCaption: i18n.t("seoInformation"),
        },
      });
      settings.columns.push({
        dataField: `${settings.addSeo}.DescriptionLocalization`,
        caption: i18n.t("longDescription"),
        editorType: "dxTextArea",
        visibility: settings.showSeo,
        multiLanguage: true,
        groupInfo: {
          groupIndex: 999,
          groupColSpan: 2,
          groupColCount: 1,
          groupClass: "",
          groupCaption: i18n.t("seoInformation"),
        },
      });
      settings.columns.push({
        dataField: `${settings.addSeo}.KeywordsLocalization`,
        caption: i18n.t("keywords"),
        visibility: false,
        editorType: "dxTag",
        multiLanguage: true,
        groupInfo: {
          groupIndex: 999,
          groupColSpan: 2,
          groupColCount: 1,
          groupClass: "",
          groupCaption: i18n.t("seoInformation"),
        },
      });
    } else {
      settings.columns.push({
        dataField: `${settings.addSeo}.Abstract`,
        caption: i18n.t("shortAbstract"),
        visibility: settings.showSeo,
        groupInfo: {
          groupIndex: 999,
          groupColSpan: 2,
          groupColCount: 1,
          groupClass: "",
          groupCaption: i18n.t("seoInformation"),
        },
      });
      settings.columns.push({
        dataField: `${settings.addSeo}.Description`,
        caption: i18n.t("longDescription"),
        editorType: "dxTextArea",
        visibility: settings.showSeo,
        groupInfo: {
          groupIndex: 999,
          groupColSpan: 2,
          groupColCount: 1,
          groupClass: "",
          groupCaption: i18n.t("seoInformation"),
        },
      });
      settings.columns.push({
        dataField: `${settings.addSeo}.Keywords`,
        caption: i18n.t("keywords"),
        visibility: false,
        editorType: "dxTag",
        groupInfo: {
          groupIndex: 999,
          groupColSpan: 2,
          groupColCount: 1,
          groupClass: "",
          groupCaption: i18n.t("seoInformation"),
        },
      });
    }
    settings.columns.push({
      dataField: `${settings.addSeo}.headerImage`,
      caption: i18n.t("headerImage"),
      multiLanguage: false,
      visibility: settings.showSeo,
      editorType: "dxImage",
      fieldValidation: {
        stringLength: { max: 400 },
      },
      groupInfo: {
        groupIndex: 999,
        groupColSpan: 2,
        groupColCount: 1,
        groupClass: "",
        groupCaption: i18n.t("seoInformation"),
      },
      editorOptions: {
        maxWidth: 1920,
        maxHeight: 280,
        aspectRatio: 1920 / 280,
        viewMode: 0,
      },
    });
  }

  if (settings.subGridFeature) {
    settings.subGridFeature.relationKey = settings.isCamalize
      ? Camalize(settings.subGridFeature.relationKey)
      : settings.subGridFeature.relationKey;
  }

  for (let i = 0; i < settings.columns.length; i++) {
    settings.columns[i] = CreateGridColumnData(settings.columns[i]);
  }

  return settings;
};
const CreateGridColumnData = (options) => {
  const finalColumn = Object.assign({}, GridColumnDefaultData, options);
  finalColumn.dataField = finalColumn.isCamalize
    ? Camalize(finalColumn.dataField)
    : finalColumn.dataField;

  if (finalColumn.dataType === "datetime" && !finalColumn.format) {
    finalColumn.format = "dd/MM/yyyy EEEE - HH:mm";
    if (!finalColumn.editorOptions) finalColumn.editorOptions = { timeZone: 0 };
  }

  if (finalColumn.dataType === "date" && !finalColumn.format) {
    finalColumn.format = "dd/MM/yyyy EEEE";
    if (!finalColumn.editorOptions) finalColumn.editorOptions = { timeZone: 0 };
  }

  if (finalColumn.relationContent) {
    const finalRelation = Object.assign(
      {},
      GridRelationDefaultData,
      finalColumn.relationContent
    );
    finalRelation.key = Camalize(finalRelation.key);
    finalRelation.value = Camalize(finalRelation.value);
    finalRelation.display = Camalize(finalRelation.display);
    finalColumn.relationContent = finalRelation;
    if (!finalColumn.editorOptions)
      finalColumn.editorOptions = { showClearButton: true };
  }

  if (finalColumn.localRelationContent) {
    const finalRelation = Object.assign(
      {},
      GridLocalRelationDefaultData,
      finalColumn.localRelationContent
    );
    finalRelation.value = Camalize(finalRelation.value);
    finalRelation.display = Camalize(finalRelation.display);
    finalColumn.localRelationContent = finalRelation;
  }

  if (!finalColumn.groupInfo)
    finalColumn.groupInfo = GridColumnGroupDefaultData;

  finalColumn.groupInfo = Object.assign(
    {},
    GridColumnGroupDefaultData,
    finalColumn.groupInfo
  );

  if (
    !finalColumn.relationContent &&
    !finalColumn.localRelationContent &&
    finalColumn.dataType !== "number" &&
    finalColumn.dataType !== "boolean" &&
    finalColumn.dataType !== "date" &&
    finalColumn.dataType !== "datetime" &&
    finalColumn.dataType !== "object"
  ) {
    if (finalColumn.multiLanguage) {
      if (
        finalColumn.editorType === "dxImage" &&
        !finalColumn.customCellRender
      ) {
        finalColumn.customCellRender = (cellData) => {
          let nameLocalization = cellData.value ?? {
            id: 0,
            defaultTranslate: "",
            customKey: null,
            localizations: [],
          };
          if (nameLocalization.defaultTranslate)
            return (
              <img
                src={getImageUrl(nameLocalization.defaultTranslate)}
                alt={i18n.t("image")}
                height="50px"
              />
            );
          else return <img src={notFound} alt={i18n.t("image")} height="50px" />;
        };
      } else if (
        finalColumn.editorType === "dxFile" &&
        !finalColumn.customCellRender
      ) {
        finalColumn.customCellRender = (cellData) => {
          let nameLocalization = cellData.value ?? {
            id: 0,
            defaultTranslate: "",
            customKey: null,
            localizations: [],
          };
          if (nameLocalization.defaultTranslate)
            return (
              <a
                href={getImageUrl(nameLocalization.defaultTranslate)}
                target="_blank"
                rel="noreferrer"
              >
                {i18n.t("viewFile")}
              </a>
            );
          else return <a>{i18n.t("fileNotFound")}</a>;
        };
      } else if (
        (finalColumn.maxView ?? 0) > 0 &&
        !finalColumn.customCellRender
      ) {
        finalColumn.customCellRender = (cellData) => {
          let nameLocalization = cellData.value ?? {
            id: 0,
            defaultTranslate: "",
            customKey: null,
            localizations: [],
          };
          let turnData = nameLocalization.defaultTranslate?.replace(
            /<[^>]*>?/gm,
            ""
          );
          turnData = turnData?.substr(0, finalColumn.maxView);
          if (
            (nameLocalization.defaultTranslate?.length ?? 0) >
            (finalColumn.maxView ?? 0)
          )
            turnData = turnData + "...";
          return turnData;
        };
      }
    } else {
      if (
        finalColumn.editorType === "dxImage" &&
        !finalColumn.customCellRender
      ) {
        finalColumn.customCellRender = (cellData) => {
          if (cellData.value)
            return (
              <div align="center">
                <img
                  src={getImageUrl(cellData.value)}
                  alt="resim"
                  height={35}
                  width={60}
                />
              </div>
            );
          else return <img src={notFound} alt="resim" height="50px" />;
        };
      } else if (
        finalColumn.editorType === "dxFile" &&
        !finalColumn.customCellRender
      ) {
        finalColumn.customCellRender = (cellData) => {
          if (cellData.value) {
            return (
              <a
                href={getImageUrl(cellData.value)}
                target="_blank"
                rel="noreferrer"
              >
                {i18n.t("viewFile")}
              </a>
            );
          } else return <a>{i18n.t("fileNotFound")}</a>;
        };
      } else if (
        finalColumn.editorType === "dxMap" &&
        !finalColumn.customCellRender
      ) {
        finalColumn.customCellRender = (cellData) => {
          let mapLat = parseFloat(
            cellData.data[options.editorOptions.lat] ?? 0
          );
          let mapLng = parseFloat(
            cellData.data[options.editorOptions.lng] ?? 0
          );
          if (mapLat) {
            return (
              <a
                href={`https://www.google.com/maps/dir//${mapLat},${mapLng}/`}
                target="_blank"
                rel="noreferrer"
              >
                {i18n.t("viewOnMap")}
              </a>
            );
          } else return <a>{i18n.t("mapNotFound")}</a>;
        };
      } else if (
        (finalColumn.maxView ?? 0) > 0 &&
        !finalColumn.customCellRender
      ) {
        finalColumn.customCellRender = (cellData) => {
          let turnData = cellData.value?.replace(/<[^>]*>?/gm, "");
          turnData = turnData?.substr(0, finalColumn.maxView);
          if ((cellData.value?.length ?? 0) > (finalColumn.maxView ?? 0))
            turnData = turnData + "...";
          return turnData;
        };
      }
    }
  }

  if (finalColumn.multiField) {
    if (!finalColumn.multiLanguage) {
      finalColumn.customCellRender = (cellData) => {
        if (cellData.data.inputType === 3) {
          if (cellData.value)
            return (
              <img
                src={getImageUrl(cellData.value)}
                alt={i18n.t("image")}
                height="50px"
              />
            );
          else return <img src={notFound} alt={i18n.t("image")} height="50px" />;
        } else if (cellData.data.inputType === 4) {
          if (cellData.value)
            return (
              <a
                href={getImageUrl(cellData.value)}
                rel="noreferrer"
                target="_blank"
              >
                {i18n.t("viewFile")}
              </a>
            );
          else return cellData.value;
        } else {
          let turnData = cellData.value?.replace(/<[^>]*>?/gm, "");
          turnData = turnData?.substr(0, finalColumn.maxView);
          if ((cellData.value?.length ?? 0) > (finalColumn.maxView ?? 0))
            turnData = turnData + "...";
          return turnData;
        }
      };
    }
  }
  return finalColumn;
};

const checkPermissions = (gridData,userPermissions = []) => {
  if(gridData.customDataSource != null){
    return gridData;
  }
  if(!userPermissions.includes("api/"+gridData.apiController+"/GetList")){
    return null
  }
  if(!userPermissions.includes("api/"+gridData.apiController+"/Insert")){
    gridData.editMode.allowAdding = false;
    gridData.editMode.allowCopy = false;
    gridData.editMode.allowAllCopy = false;
  }
  if(!userPermissions.includes("api/"+gridData.apiController+"/Update")){
    gridData.editMode.allowUpdating = false;
  }
  if(!userPermissions.includes("api/"+gridData.apiController+"/Delete")){
    gridData.editMode.allowDeleting = false;
  }
  return gridData;
}

export const RenderMasterGrid = memo(({ gridData }) => {
  const userState = useSelector((p) => p.SignedUserReducer);
  gridData = checkPermissions(gridData, userState.user.userPermissions);
  if(gridData == null)
    return <></>

  let tempData = {};
  // var { t } = useTranslation();
  var selectedTagId = "_" + Math.random().toString(36).substr(2, 9);
  let editButtons = [];
  if (gridData.editMode) {
    if (gridData.editMode.allowUpdating) editButtons.push("edit");
    if (gridData.editMode.allowDeleting) editButtons.push("delete");
    if (gridData.editMode.allowCopy) editButtons.push("copy");
    if (gridData.editMode.allowAllCopy) editButtons.push("copy");
  }

  let gridRef = null;
  const onToolbarPreparing = (e) => {
    if (gridData.refreshItem) {
      e.toolbarOptions.items.unshift({
        location: "after",
        template: "refreshItem",
      });
    }
    if (gridData.selection && gridData.selectionMode === "multiple") {
      e.toolbarOptions.items.unshift({
        location: "center",
        template: "selectionCount",
      });
    }
  };

  const refreshDataGrid = () => {
    gridRef.instance.refresh();
    gridRef.instance.clearSelection();
  };

  const onSelectionChanged = (param) => {
    if (gridData.selectionMode === "multiple") {
      var el = document.getElementById(selectedTagId);
      el.textContent = param.selectedRowKeys.length;
    }

    gridData.onSelectionChanged(param);
  };

  const cloneIconClick = (item) => {
    var data = item.row.data;
    data.tempId = data.id;
    delete data.updatedBy;
    delete data.createdBy;
    delete data.createdAt;
    delete data.updatedAt;
    delete data.id;

    tempData = data;
    gridRef.instance.addRow();
  };
  const cloneAllIconClick = (item) => {
    var data = item.row.data;
    data.tempId = data.id;
    data.isCopy = true;
    data.copiedId = data.id;
    delete data.updatedBy;
    delete data.createdBy;
    delete data.createdAt;
    delete data.updatedAt;
    delete data.id;

    tempData = data;
    gridRef.instance.addRow();
  };
  const dataSource = gridData.customDataSource
    ? gridData.customDataSource
    : setGridApiDefault(
        gridData.apiController,
        gridData.apiKey,
        gridData.loadParams
      );

  return (
    <div className="c-grid">
      <div id={gridData.apiController + "grid"}>
        <DataGrid
          ref={(ref) => {
            gridRef = ref;
            gridData.setRef(ref);
          }}
          onSaving={gridData.onSaving}
          onEditCanceled={gridData.onEditCanceled}
          onInitialized={gridData.onInitialized}
          rowAlternationEnabled={true}
          autoNavigateToFocusedRow={false}
          keyExpr={gridData.apiKey}
          dataSource={dataSource}
          showBorders={true}
          remoteOperations={false}
          columnAutoWidth={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          useNative={true}
          onToolbarPreparing={onToolbarPreparing}
          filterBuilderPopup={{
            position: { of: window, at: "center", my: "center" },
          }}
          columnFixing={{ enabled: false }}
          height={gridData.height}
          filterPanel={{ visible: gridData.filterPanel }}
          columnChooser={{ enabled: gridData.columnChooser, mode: "select" }}
          searchPanel={{ visible: gridData.searchPanel, width: 240 }}
          onExporting={(e) => onExporting(e, gridData)}
          onRowPrepared={gridData.onRowPrepared}
          onSelectionChanged={onSelectionChanged}
          defaultSelectedRowKeys={
            gridData.selection && gridData.defaultSelectedRowKeys
          }
          onEditCanceling={() => {
            tempData = {};
            refreshDataGrid();
          }}
          onInitNewRow={(options) => {
            options.data = tempData;
            if (gridData.hideIsActive) {
              options.data.isActive = true;
            }
            if (gridData.onInitNewRow) gridData.onInitNewRow(options);
          }}
          onCellClick={gridData.onCellClick}
          onRowInserting={gridData.onRowInserting}
          onRowRemoving={gridData.onRowRemoving}
          onRowClick={gridData.onRowClick}
          onEditorPreparing={(e) => {
            gridData.onEditorPreparing(e);
          }}
          onRowExpanding={(e) => {
            e.component.collapseAll(-1);
          }}
          onContentReady={(e) => {
            e.component.option("loadPanel.enabled", false);
          }}
        >
          <KeyboardNavigation enabled={false} />
          <Paging defaultPageSize={Math.floor(window.innerHeight / 55)} />
          <Scrolling scrollByContent={true} />

          {gridData.refreshItem && (
            <Template
              name="refreshItem"
              render={(e) => (
                <DxButton icon="refresh" onClick={refreshDataGrid} />
              )}
            />
          )}
          {gridData.selection && (
            <Template
              name="selectionCount"
              render={(e) => (
                <Tag color="green">
                  <p id={selectedTagId}>0</p>
                </Tag>
              )}
            />
          )}
          {gridData.subGrids && (
            <MasterDetail
              enabled={true}
              component={({ data }) =>
                gridData.subGrids.renderSubGrid
                  ? gridData.subGrids.renderSubGrid(data)
                  : RenderSubGripTab(data, gridData, t)
              }
            />
          )}
          {gridData.headerFilter && <HeaderFilter visible={true} />}
          {gridData.filterPanel && <FilterPanel visible={true} />}
          {gridData.selection && (
            <Selection
              mode={gridData.selectionMode}
              showCheckBoxesMode="always"
              deferred={false}
            />
          )}
          {gridData.filterRow && <FilterRow visible={true} />}
          {gridData.exportPanel && (
            <Export enabled={true} allowExportSelectedData={true} />
          )}
          {gridData.editMode?.isActive &&
            !gridData.editing &&
            !gridData.editMode.isTabs &&
            renderEditContent(gridData)}
          {gridData.editMode?.isActive &&
            !gridData.editing &&
            gridData.editMode.isTabs &&
            renderEditTabContent(gridData)}
          {gridData.groupPanel && <GroupPanel visible={true} />}
          <Grouping autoExpandAll={true} allowCollapsing={false} />
          {gridData.columns.map((item, index) => (
            <Column
              key={index}
              allowFiltering={item.allowFilter}
              caption={item.caption}
              dataField={item.dataField}
              fixed={item.fixed}
              sortOrder={item.sortOrder}
              visible={item.visibility}
              editorOptions={item.editorOptions}
              format={item.format}
              dataType={item.dataType}
              width={item.columnWidth}
              filterOperations={item.filterOperations}
              selectedFilterOperation={item.selectedFilterOperation}
              calculateFilterExpression={item.calculateFilterExpression}
              filterValue={item.filterValue}
              filterValues={item.filterValues}
              allowEditing={item.editCellVisibility}
              groupIndex={item.groupIndex ?? null}
              headerCellRender={item.headerCellRender}
              editCellComponent={
                item.editCellComponent
                  ? ({ data }) => item.editCellComponent(data)
                  : null
              }
              editCellRender={
                (item.customEditCellComponent ||
                  item.multiLanguage ||
                  item.customLanguage ||
                  item.multiField ||
                  item.editorType === "dxHtml" ||
                  item.editorType === "dxImage" ||
                  item.editorType === "dxTag" ||
                  item.editorType === "dxMap" ||
                  item.editorType === "dxFile" ||
                  item.editorType === "dxPhone") &&
                item.editCellVisibility
                  ? (cell) => {
                      if (item.customEditCellComponent)
                        return item.customEditCellComponent(cell);
                      else if (item.multiLanguage)
                        return renderMultiLanguageField(cell, item, gridData);
                      else if (item.customLanguage)
                        return renderMultiLanguageCustomField(
                          cell,
                          item,
                          gridData
                        );
                      else if (item.multiField) {
                        if (item.multiFieldLanguage)
                          return renderMultiValueLanguageField(
                            cell,
                            item,
                            gridData
                          );
                        else return renderMultiValueField(cell);
                      } else if (item.editorType === "dxHtml")
                        return renderHtmlEditor(cell);
                      else if (item.editorType === "dxImage")
                        return renderImageEditor(
                          cell,
                          item.editorOptions,
                          gridData.apiController
                        );
                      else if (item.editorType === "dxPhone")
                        return renderPhoneInput(cell, item.editorOptions);
                      else if (item.editorType === "dxFile")
                        return renderFileEditor(cell, item.editorOptions);
                      else if (item.editorType === "dxTag")
                        return renderTagEditor(cell);
                      else if (item.editorType === "dxMap") {
                        return renderMapEditor(cell, item.editorOptions);
                      }
                    }
                  : null
              }
              cellRender={item.customCellRender}
              setCellValue={
                item.editorType === "dxMap" || item.dataField === "inputType"
                  ? (newData, value) => {
                      if (item.editorType === "dxMap") {
                        newData[item.editorOptions.lat] = value.split("~")[0];
                        newData[item.editorOptions.lng] = value.split("~")[1];
                      }
                      if (item.dataField === "inputType") {
                        newData.inputType = value;
                      }
                    }
                  : undefined
              }
            >
              {item.fieldValidation?.isRequired &&
                item.editorType !== "dxHidden" &&
                item.editorType !== "dxHtml" &&
                item.editorType !== "dxImage" &&
                ValidateRequired(item.caption, t)}
              {item.fieldValidation?.isEmail &&
                item.editorType !== "dxHidden" &&
                item.editorType !== "dxHtml" &&
                item.editorType !== "dxImage" &&
                ValidateEMail(item.caption, t)}
              {item.fieldValidation?.isNumeric &&
                item.editorType !== "dxHidden" &&
                item.editorType !== "dxHtml" &&
                item.editorType !== "dxImage" &&
                ValidateNumeric(item.caption, t)}
              {item.fieldValidation?.stringLength &&
                item.editorType !== "dxHidden" &&
                item.editorType !== "dxHtml" &&
                item.editorType !== "dxImage" &&
                ValidateStringLength(
                  item.caption,
                  item.fieldValidation.stringLength.min,
                  item.fieldValidation.stringLength.max,
                  t
                )}
              {item.fieldValidation?.numericRange &&
                item.editorType !== "dxHidden" &&
                item.editorType !== "dxHtml" &&
                item.editorType !== "dxImage" &&
                ValidateRange(
                  item.caption,
                  item.fieldValidation.numericRange.min,
                  item.fieldValidation.numericRange.max,
                  t
                )}
              {item.fieldValidation?.asyncValidation &&
                item.editorType !== "dxHidden" &&
                item.editorType !== "dxHtml" &&
                item.editorType !== "dxImage" &&
                item.fieldValidation.asyncValidation()}
              {item.customColumnRender && item.customColumnRender}
              {item.relationContent && (
                <Lookup
                  dataSource={setSubGridApiDefault(
                    item.relationContent.controller,
                    item.relationContent.key ?? "id",
                    item.relationContent.loadUrl,
                    null,
                    null,
                    null,
                    item.relationContent.customParam
                  )}
                  valueExpr={item.relationContent.key}
                  displayExpr={item.relationContent.display}
                  searchExpr={item.relationContent.search}
                />
              )}
              {item.localRelationContent && (
                <Lookup
                  dataSource={item.localRelationContent.dataSource}
                  valueExpr={item.localRelationContent.value}
                  displayExpr={item.localRelationContent.display}
                  searchExpr={item.localRelationContent.search}
                />
              )}
            </Column>
          ))}
          {gridData.editMode?.customButtons?.map((items, index) => (
            <Column
              type="buttons"
              width={items.width}
              key={index}
              caption={items.caption}
            >
              {items.buttons.map((item, index) => (
                <Button key={index} render={item.render} />
              ))}
            </Column>
          ))}
          {editButtons.length > 0 && (
            <Column type="buttons" width={gridData.editMode?.width}>
              <Button name="edit" visible={gridData.editMode.allowUpdating} />
              <Button name="delete" visible={gridData.editMode.allowUpdating} />
              <Button
                hint="Clone"
                icon="copy"
                visible={gridData.editMode.allowCopy}
                onClick={cloneIconClick}
              />
              <Button
                hint="Clone All"
                icon="save"
                visible={gridData.editMode.allowAllCopy}
                onClick={cloneAllIconClick}
              />
            </Column>
          )}
          {gridData.summaries.length > 0 && (
            <Summary>
              {gridData.summaries.map((item) => (
                <TotalItem
                  column={item.column}
                  summaryType={item.summaryType}
                  customizeText={item.customizeText}
                />
              ))}
            </Summary>
          )}
        </DataGrid>
      </div>
    </div>
  );
});

//Modelden Çekilmiş
const RenderSubGrid = (gridData, key, t, masterData) => {
  let tempData = {};
  let gridRef = null;
  let editButtons = [];
  if (gridData.editMode) {
    if (gridData.editMode.allowUpdating) editButtons.push("edit");
    if (gridData.editMode.allowDeleting) editButtons.push("delete");
  }

  const refreshDataGrid = () => {
    gridRef.instance.refresh();
    gridRef.instance.clearSelection();
  };

  const cloneIconClick = (item) => {
    const deletedDataKeys = [
      "id",
      "updatedBy",
      "createdBy",
      "createdAt",
      "updatedAt",
    ];
    var data = item.row.data;
    for (const [key, value] of Object.entries(data)) {
      if (!deletedDataKeys.includes(key)) {
        tempData[key] = value;
      }
    }
    gridRef.instance.addRow();
  };
  return (
    <div id={gridData.apiController + "editgrid"}>
      <DataGrid
        ref={(ref) => (gridRef = ref)}
        onEditCanceled={gridData.onEditCanceled}
        onSaving={gridData.onSaving}
        dataSource={setSubGridApiDefault(
          gridData.apiController,
          gridData.apiKey,
          gridData.subGridFeature?.loadUrl,
          gridData.subGridFeature?.insertUrl,
          gridData.subGridFeature?.updateUrl,
          gridData.subGridFeature?.removeUrl,
          gridData.subGridFeature?.loadParams
        )}
        onInitialized={gridData.onInitialized}
        rowAlternationEnabled={true}
        autoNavigateToFocusedRow={false}
        keyExpr={gridData.apiKey}
        showBorders={true}
        remoteOperations={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        useNative={true}
        filterBuilderPopup={{
          position: { of: window, at: "center", my: "center" },
        }}
        columnFixing={{ enabled: false }}
        height={gridData.height}
        filterPanel={{ visible: gridData.filterPanel }}
        columnChooser={{ enabled: gridData.columnChooser, mode: "select" }}
        searchPanel={{ visible: gridData.searchPanel, width: 240 }}
        onExporting={(e) => onExporting(e, gridData)}
        onRowPrepared={gridData.onRowPrepared}
        defaultSelectedRowKeys={
          gridData.selection && gridData.defaultSelectedRowKeys
        }
        onInitNewRow={(options) => {
          if (gridData.onInitNewRow) gridData.onInitNewRow(options);
          options.data = tempData;
          if (gridData.hideIsActive) {
            options.data.isActive = true;
          }
        }}
        onEditCanceling={() => {
          tempData = {};
          refreshDataGrid();
        }}
        onCellClick={gridData.onCellClick}
        onRowClick={gridData.onRowClick}
        onEditorPreparing={(e) => {
          if (e.parentType === "dataRow") {
            e.editorElement.classList.add("cssClass2");
          }
        }}
        onRowInserting={(e) => {
          if (gridData.subGridFeature)
            e.data[gridData.subGridFeature.relationKey] = key;
        }}
        onRowExpanding={(e) => {
          e.component.collapseAll(-1);
        }}
        onContentReady={(e) => {
          e.component.option("loadPanel.enabled", false);
        }}
      >
        <KeyboardNavigation enabled={false} />
        {gridData.toolbarItemRender && (
          <Template
            name="toolbarItem"
            render={(e) => gridData.toolbarItemRender(key)}
          />
        )}

        {gridData.headerFilter && <HeaderFilter visible={true} />}
        {gridData.filterRow && <FilterRow visible={true} />}
        {gridData.exportPanel && (
          <Export enabled={true} allowExportSelectedData={true} />
        )}
        {gridData.editMode?.isActive &&
          !gridData.editing &&
          renderEditContent(gridData)}
        {gridData.columns.map((item, index) => (
          <Column
            key={index}
            caption={item.caption}
            dataField={item.dataField}
            fixed={item.fixed}
            sortOrder={item.sortOrder}
            visible={item.visibility}
            editorOptions={item.editorOptions}
            format={item.format}
            dataType={item.dataType}
            width={item.columnWidth}
            groupIndex={item.groupIndex ?? null}
            allowEditing={item.editCellVisibility}
            editCellComponent={
              item.editCellComponent
                ? ({ data }) => item.editCellComponent(data)
                : undefined
            }
            editCellRender={
              (item.customEditCellComponent ||
                item.multiLanguage ||
                item.customLanguage ||
                item.multiField ||
                item.editorType === "dxHtml" ||
                item.editorType === "dxImage" ||
                item.editorType === "dxTag" ||
                item.editorType === "dxMap" ||
                item.editorType === "dxFile" ||
                item.editorType === "dxPhone") &&
              item.editCellVisibility
                ? (cell) => {
                    if (item.customEditCellComponent)
                      return item.customEditCellComponent(cell, masterData);
                    else if (item.multiLanguage)
                      return renderMultiLanguageField(cell, item, gridData);
                    else if (item.customLanguage)
                      return renderMultiLanguageCustomField(
                        cell,
                        item,
                        gridData
                      );
                    else if (item.multiField) {
                      if (item.multiFieldLanguage)
                        return renderMultiValueLanguageField(
                          cell,
                          item,
                          gridData
                        );
                      else return renderMultiValueField(cell);
                    } else if (item.editorType === "dxHtml")
                      return renderHtmlEditor(cell);
                    else if (item.editorType === "dxImage")
                      return renderImageEditor(
                        cell,
                        item.editorOptions,
                        gridData.apiController
                      );
                    else if (item.editorType === "dxFile")
                      return renderFileEditor(cell, item.editorOptions);
                    else if (item.editorType === "dxPhone")
                      return renderPhoneInput(cell, item.editorOptions);
                    else if (item.editorType === "dxTag")
                      return renderTagEditor(cell);
                    else if (item.editorType === "dxMap")
                      return renderMapEditor(cell, item.editorOptions);
                  }
                : undefined
            }
            cellRender={item.customCellRender}
            setCellValue={
              item.editorType === "dxMap"
                ? (newData, value) => {
                    if (item.editorType === "dxMap") {
                      newData[item.editorOptions.lat] = value.split("~")[0];
                      newData[item.editorOptions.lng] = value.split("~")[1];
                    }
                  }
                : undefined
            }
          >
            {item.fieldValidation?.isRequired &&
              ValidateRequired(item.caption, t)}
            {item.fieldValidation?.isEmail && ValidateEMail(item.caption, t)}
            {item.fieldValidation?.isNumeric &&
              ValidateNumeric(item.caption, t)}
            {item.fieldValidation?.stringLength &&
              ValidateStringLength(
                item.caption,
                item.fieldValidation.stringLength.min,
                item.fieldValidation.stringLength.max,
                t
              )}
            {item.fieldValidation?.numericRange &&
              ValidateRange(
                item.caption,
                item.fieldValidation.numericRange.min,
                item.fieldValidation.numericRange.max,
                t
              )}
            {item.fieldValidation?.asyncValidation &&
              item.fieldValidation.asyncValidation()}
            {item.customColumnRender && item.customColumnRender(masterData)}
            {item.relationContent && !item.relationContent.customParam && (
              <Lookup
                dataSource={setSubGridApiDefault(
                  item.relationContent.controller,
                  item.relationContent.key ?? "id",
                  item.relationContent.loadUrl,
                  undefined,
                  undefined,
                  undefined,
                  {
                    ...item.relationContent.loadParam,
                    [item.relationContent.customParam]: key,
                  }
                )}
                valueExpr={item.relationContent.key}
                displayExpr={item.relationContent.display}
              />
            )}
            {item.relationContent && item.relationContent.customParam && (
              <Lookup
                dataSource={setSubGridApiDefault(
                  item.relationContent.controller,
                  item.relationContent.key ?? "id",
                  item.relationContent.loadUrl,
                  undefined,
                  undefined,
                  undefined,
                  {
                    ...item.relationContent.loadParam,
                    [item.relationContent.customParam]: key,
                  }
                )}
                valueExpr={item.relationContent.key}
                displayExpr={item.relationContent.display}
              />
            )}
            {item.localRelationContent && (
              <Lookup
                dataSource={item.localRelationContent.dataSource}
                valueExpr={item.localRelationContent.value}
                displayExpr={item.localRelationContent.display}
              />
            )}
          </Column>
        ))}
        {gridData.editMode?.customButtons?.map((items, index) => (
          <Column
            type="buttons"
            width={items.width}
            key={index}
            caption={items.caption}
          >
            {items.buttons.map((item, index) => (
              <Button key={index} render={item.render} />
            ))}
          </Column>
        ))}
        {gridData.subGrids && (
          <MasterDetail
            enabled={true}
            component={({ data }) =>
              gridData.subGrids.renderSubGrid
                ? gridData.subGrids.renderSubGrid(data)
                : RenderSubGripTab(data, gridData, t)
            }
          />
        )}
        {editButtons.length > 0 && (
          <Column type="buttons" width={gridData.editMode?.width}>
            <Button name="edit" visible={gridData.editMode.allowUpdating} />
            <Button name="delete" visible={gridData.editMode.allowUpdating} />
            <Button
              hint="Clone"
              icon="copy"
              visible={gridData.editMode.allowCopy}
              onClick={cloneIconClick}
            />
          </Column>
        )}
        {gridData.summaries.length > 0 && (
          <Summary>
            {gridData.summaries.map((item) => (
              <TotalItem
                column={item.column}
                summaryType={item.summaryType}
                customizeText={item.customizeText}
              />
            ))}
          </Summary>
        )}
        <Paging defaultPageSize={5} />
        <Scrolling scrollByContent={true} />
      </DataGrid>
    </div>
  );
};
//Modelden Çekilmiş
const renderEditTabContent = (gridData) => {
  let editMode = gridData.editMode;
  let allGroupItems = [];
  for (const column in gridData.columns) {
    let activeItem = gridData.columns[column];
    if (
      activeItem.groupInfo &&
      allGroupItems.filter(
        (nq) => nq.groupIndex === activeItem.groupInfo?.groupIndex
      ).length <= 0
    )
      allGroupItems.push(activeItem.groupInfo);
  }
  return (
    <Editing
      mode={editMode.mode}
      allowAdding={editMode.allowAdding}
      allowDeleting={editMode.allowDeleting}
      allowUpdating={editMode.allowUpdating}
      useIcons={editMode.useIcons}
    >
      <Popup
        title={gridData.editTitle}
        showTitle={true}
        container={`#${
          gridData.subGridFeature
            ? gridData.apiController + "edit"
            : gridData.apiController
        }grid`}
        position={{
          at: "center",
          of: `#${
            gridData.subGridFeature
              ? gridData.apiController + "edit"
              : gridData.apiController
          }grid`,
        }}
        height={"90%"}
        width={"90%"}
        resizeEnabled={true}
        shading={false}
      ></Popup>

      <Form
        showValidationSummary={editMode.validationSummary}
        colCount={editMode.containerColCount}
      >
        <GroupItem>
          <TabbedItem>
            <TabPanelOptions height={260} />

            {allGroupItems
              .sort((a, b) => a.groupIndex - b.groupIndex)
              .map((item, index) => {
                let allColumns = gridData.columns.filter(
                  (nq) =>
                    nq.groupInfo?.groupIndex === item.groupIndex &&
                    nq.editorType !== "dxHidden"
                );
                return (
                  <Tab title={item.groupCaption}>
                    <Item
                      itemType="group"
                      colCount={item.groupColCount}
                      colSpan={item.groupColSpan}
                      caption={item.groupCaption}
                      cssClass={item.groupClass}
                      key={index}
                    >
                      {allColumns.map((column, index) => {
                        let writeEditorType =
                          column.editorType === "dxHtml" ||
                          column.editorType === "dxImage" ||
                          column.editorType === "dxMap" ||
                          column.editorType === "dxTag" ||
                          column.editorType === undefined;
                        return (
                          <Item
                            dataField={column.dataField}
                            render={
                              column.customEditCellComponent
                                ? column.customEditCellComponent
                                : undefined
                            }
                            editorType={
                              !writeEditorType ? column.editorType : undefined
                            }
                            editorOptions={column.editorOptions}
                            cssClass={column.customCssClass}
                            key={index}
                          />
                        );
                      })}
                    </Item>
                  </Tab>
                );
              })}
          </TabbedItem>
        </GroupItem>
        <Item itemType="group" caption={" "} colCount={12}>
          <ButtonItem horizontalAlignment="left">
            <ButtonOptions
              text={i18n.t("prev")}
              useSubmitBehavior={true}
              type="success"
            />
          </ButtonItem>
          <ButtonItem horizontalAlignment="left">
            <ButtonOptions
              text={i18n.t("next")}
              useSubmitBehavior={true}
              type="success"
            />
          </ButtonItem>
        </Item>
      </Form>
    </Editing>
  );
};
//Modelden Çekilmiş
const renderEditContent = (gridData) => {
  let editMode = gridData.editMode;
  let allGroupItems = [];
  for (const column in gridData.columns) {
    let activeItem = gridData.columns[column];
    if (
      activeItem.groupInfo &&
      allGroupItems.filter(
        (nq) => nq.groupIndex === activeItem.groupInfo?.groupIndex
      ).length <= 0
    )
      allGroupItems.push(activeItem.groupInfo);
  }
  if (editMode)
    return (
      <Editing
        onChangesChange={editMode.onChangesChange}
        mode={editMode.mode}
        allowAdding={editMode.allowAdding}
        allowDeleting={editMode.allowDeleting}
        allowUpdating={editMode.allowUpdating}
        useIcons={editMode.useIcons}
      >
        <Popup
          title={gridData.editTitle}
          showTitle={true}
          container={`#${
            gridData.subGridFeature
              ? gridData.apiController + "edit"
              : gridData.apiController
          }grid`}
          position={{
            at: "center",
            of: `#${
              gridData.subGridFeature
                ? gridData.apiController + "edit"
                : gridData.apiController
            }grid`,
          }}
          height={"90%"}
          width={"90%"}
          resizeEnabled={true}
          shading={false}
        ></Popup>
        <Form
          showValidationSummary={editMode.validationSummary}
          colCount={editMode.containerColCount}
        >
          {allGroupItems
            .sort((a, b) => a.groupIndex - b.groupIndex)
            .map((item, index) => {
              let allColumns = gridData.columns.filter(
                (nq) =>
                  nq.groupInfo?.groupIndex === item.groupIndex &&
                  nq.editorType !== "dxHidden"
              );
              return (
                <Item
                  itemType="group"
                  colCount={item.groupColCount}
                  colSpan={item.groupColSpan}
                  caption={item.groupCaption}
                  cssClass={item.groupClass}
                  key={index}
                >
                  {allColumns
                    .filter((p) => p.editCellVisibility)
                    .map((column, index) => {
                      let writeEditorType =
                        column.editorType === "dxHtml" ||
                        column.editorType === "dxImage" ||
                        column.editorType === "dxMap" ||
                        column.editorType === "dxTag" ||
                        column.editorType === undefined;
                      return (
                        <Item
                          dataField={column.dataField}
                          render={
                            column.customEditCellComponent
                              ? column.customEditCellComponent
                              : undefined
                          }
                          editorType={
                            !writeEditorType ? column.editorType : undefined
                          }
                          editorOptions={column.editorOptions}
                          cssClass={column.customCssClass}
                          key={index}
                        />
                      );
                    })}
                </Item>
              );
            })}
        </Form>
      </Editing>
    );
};
const renderHtmlEditor = (cell) => {
  return (
    <div className={"editorContainer"}>
      {/* <HtmlEditor
                initValue={cell.value}
                setValue={(content) => cell.setValue(content)}
            /> */}
    </div>
  );
};
const renderImageEditor = (cell, editorOptions, controller) => {
  return (
    <div className="imageSelectorContainer">
      {/* <ImageSelector defaultValue={getImageUrl(cell.value)} url={getApiUrl(`/api/${controller}/UploadFile`)} setValue={(value) => {
                cell.setValue(value);
            }} maxWidth={editorOptions?.maxWidth} maxHeight={editorOptions?.maxHeight}
                aspectRatio={editorOptions?.aspectRatio} viewMode={editorOptions?.viewMode} /> */}
    </div>
  );
};
const renderFileEditor = (cell, editorOptions) => {
  return (
    <div className="imageSelectorContainer">
      {/* <FileSelector defaultValue={getImageUrl(cell.value)} setValue={(value) => {
                cell.setValue(value);
            }} /> */}
    </div>
  );
};
const renderMapEditor = (cell, editorOptions) => {
  let mapLat = parseFloat(cell.data[editorOptions.lat] ?? 0);
  let mapLng = parseFloat(cell.data[editorOptions.lng] ?? 0);
  return (
    <div className={"mapLine"}>
      {/* <MapSelector mapHeight={"300px"} onChange={(lat, lng) => {
                cell.setValue(`${lat}~${lng}`);
            }} lat={mapLat} lng={mapLng} /> */}
    </div>
  );
};
const renderPhoneInput = (cell, editorOptions) => {
  return (
    <div className={"phoneNumber"}>
      <PhoneInput
        country={"tr"}
        value={cell.value}
        onChange={(value) => cell.setValue(value)}
      />
    </div>
  );
};
const renderTagEditor = (cell) => {
  let defaultValue = [];
  if (cell.value?.length > 0 && cell.value?.split(",").length > 0)
    defaultValue = cell.value?.split(",");
  return (
    <div className="tagInputContainer">
      <TagInput
        mode="tags"
        onChange={(value) => cell.setValue(value.toString())}
        defaultValue={defaultValue}
      />
    </div>
  );
};
const renderMultiLanguageField = (cell, column, gridData) => {
  let localizationData = cell.value ?? {
    id: 0,
    defaultTranslate: "",
    customKey: null,
    localizations: [],
  };
  let translationData = [];
  gridData.languageList?.forEach((item) => {
    if (
      localizationData.localizations.filter((nq) => nq.languageId === item.id)
        .length === 0
    ) {
      translationData.push({
        id: 0,
        languageId: item.id,
        translation: null,
        customDictionayId: localizationData.id,
      });
    } else {
      translationData.push(
        localizationData.localizations.filter(
          (nq) => nq.languageId === item.id
        )[0]
      );
    }
  });
  localizationData.localizations = translationData;
  cell.setValue(localizationData);
  let onChangeData = (data, index) => {
    let cellData = cell.value ?? [];

    let firstLanguage = gridData.languageList?.filter(
      (nq) => nq.globalName === "tr"
    )[0]?.id;
    if (!firstLanguage && gridData.languageList)
      firstLanguage = gridData.languageList[0]?.id;

    if (firstLanguage === cellData.localizations[index].languageId)
      cellData.defaultTranslate = data;

    cellData.localizations[index].translation = data;
    cell.setValue(cellData);
  };

  return (
    <div className="multiLanguageContainer">
      <TabPanel>
        {translationData.forEach((item, index) => {
          let itemLang = gridData.languageList?.find(
            (nq) => nq.id === item.languageId
          );
          return (
            <TabItem
              title={itemLang?.name}
              render={() => (
                <>
                  {column.editorType === "dxHtml" && (
                    <div className={"editorContainer"}>
                      {/* <HtmlEditor
                                                initValue={item.translation}
                                                setValue={(content) => onChangeData(content, index)}
                                            /> */}
                    </div>
                  )}
                  {column.editorType === "dxImage" && (
                    <div className="imageSelectorContainer">
                      {/* <ImageSelector defaultValue={getImageUrl(item.translation)}
                                                setValue={(value) => {
                                                    onChangeData(value, index);
                                                }} /> */}
                    </div>
                  )}
                  {column.editorType === "dxFile" && (
                    <div className="imageSelectorContainer">
                      {/* <FileSelector defaultValue={getImageUrl(item.translation)}
                                                setValue={(value) => {
                                                    onChangeData(value, index);
                                                }} /> */}
                    </div>
                  )}
                  {column.editorType === "dxTag" && (
                    <div className="tagInputContainer">
                      <TagInput
                        mode="tags"
                        onChange={(value) =>
                          onChangeData(value.toString(), index)
                        }
                        defaultValue={
                          item.translation?.length > 0 &&
                          item.translation?.split(",").length > 0
                            ? item.translation?.split(",")
                            : []
                        }
                      />
                    </div>
                  )}
                  {column.editorType === "dxTextArea" && (
                    <TextArea
                      defaultValue={item.translation}
                      height={140}
                      onValueChanged={(data) => onChangeData(data.value, index)}
                    />
                  )}
                  {column.editorType !== "dxHtml" &&
                    column.editorType !== "dxImage" &&
                    column.editorType !== "dxTag" &&
                    column.editorType !== "dxTextArea" &&
                    column.editorType !== "dxFile" && (
                      <TextBox
                        defaultValue={item.translation}
                        onValueChanged={(data) =>
                          onChangeData(data.value, index)
                        }
                      />
                    )}
                </>
              )}
            />
          );
        })}
      </TabPanel>
    </div>
  );
};
const renderMultiLanguageCustomField = (cell, column, gridData) => {
  let dictionaryId = cell.data?.id;
  let localizations = cell.value ?? [];
  let translationData = [];
  gridData.languageList?.forEach((item) => {
    if (localizations.filter((nq) => nq.languageId === item.id).length === 0) {
      translationData.push({
        id: 0,
        languageId: item.id,
        translation: null,
        customDictionayId: dictionaryId,
      });
    } else {
      translationData.push(
        localizations.filter((nq) => nq.languageId === item.id)[0]
      );
    }
  });
  localizations = translationData;
  cell.setValue(localizations);

  let onChangeData = (data, index) => {
    let cellData = cell.value ?? [];
    cellData[index].translation = data;
    cell.setValue(cellData);
  };

  return (
    <div className="multiLanguageContainer">
      <TabPanel>
        {translationData.forEach((item, index) => {
          let itemLang = gridData.languageList?.find(
            (nq) => nq.id === item.languageId
          );
          return (
            <TabItem
              title={itemLang?.name}
              render={() => (
                <>
                  {column.editorType === "dxHtml" && (
                    <div className={"editorContainer"}>
                      {/* <HtmlEditor
                                                initValue={item.translation}
                                                setValue={(content) => onChangeData(content, index)}
                                            /> */}
                    </div>
                  )}
                  {column.editorType === "dxImage" && (
                    <div className="imageSelectorContainer">
                      {/* <ImageSelector defaultValue={getImageUrl(item.translation)}
                                                setValue={(value) => {
                                                    onChangeData(value, index);
                                                }} /> */}
                    </div>
                  )}
                  {column.editorType === "dxFile" && (
                    <div className="imageSelectorContainer">
                      {/* <FileSelector defaultValue={getImageUrl(item.translation)}
                                                setValue={(value) => {
                                                    onChangeData(value, index);
                                                }} /> */}
                    </div>
                  )}
                  {column.editorType === "dxTag" && (
                    <div className="tagInputContainer">
                      <TagInput
                        mode="tags"
                        onChange={(value) =>
                          onChangeData(value.toString(), index)
                        }
                        defaultValue={
                          item.translation?.length > 0 &&
                          item.translation?.split(",").length > 0
                            ? item.translation?.split(",")
                            : []
                        }
                      />
                    </div>
                  )}
                  {column.editorType === "dxTextArea" && (
                    <TextArea
                      defaultValue={item.translation}
                      height={140}
                      onValueChanged={(data) => onChangeData(data.value, index)}
                    />
                  )}
                  {column.editorType !== "dxHtml" &&
                    column.editorType !== "dxImage" &&
                    column.editorType !== "dxTag" &&
                    column.editorType !== "dxTextArea" &&
                    column.editorType !== "dxFile" && (
                      <TextBox
                        defaultValue={item.translation}
                        onValueChanged={(data) =>
                          onChangeData(data.value, index)
                        }
                      />
                    )}
                </>
              )}
            />
          );
        })}
      </TabPanel>
    </div>
  );
};
const renderMultiValueField = (cell) => {
  let onChangeData = (data) => {
    cell.setValue(data);
  };

  if (cell.data.inputType === 1) {
    return (
      <TextArea
        defaultValue={cell.value}
        height={140}
        onValueChanged={(data) => onChangeData(data.value)}
      />
    );
  } else if (cell.data.inputType === 2) {
    return (
      <div className={"editorContainer"}>
        {/* <HtmlEditor
                initValue={cell.value}
                setValue={(content) => onChangeData(content)}
            /> */}
      </div>
    );
  } else if (cell.data.inputType === 3) {
    return (
      <div className="imageSelectorContainer">
        {/* <ImageSelector defaultValue={getImageUrl(cell.value)} setValue={(value) => {
                onChangeData(value);
            }} /> */}
      </div>
    );
  } else if (cell.data.inputType === 4) {
    return (
      <div className="imageSelectorContainer">
        {/* <FileSelector defaultValue={getImageUrl(cell.value)} setValue={(value) => {
                onChangeData(value);
            }} /> */}
      </div>
    );
  } else if (cell.data.inputType === 6) {
    return (
      <div className="tagInputContainer">
        <TagInput
          mode="tags"
          onChange={(value) => onChangeData(value.toString())}
          defaultValue={
            cell.value?.length > 0 && cell.value?.split(",").length > 0
              ? cell.value?.split(",")
              : []
          }
        />
      </div>
    );
  }

  return (
    <TextBox
      defaultValue={cell.value}
      onValueChanged={(data) => onChangeData(data.value)}
    />
  );
};
//Modelden Çekilmiş
const renderMultiValueLanguageField = (cell, column, gridData) => {
  let dictionaryId = cell.data?.id;
  let localizations = cell.value ?? [];
  let translationData = [];
  gridData.languageList?.forEach((item) => {
    if (localizations.filter((nq) => nq.languageId === item.id).length === 0) {
      translationData.push({
        id: 0,
        languageId: item.id,
        translation: null,
        dictionaryId: dictionaryId,
      });
    } else {
      translationData.push(
        localizations.filter((nq) => nq.languageId === item.id)[0]
      );
    }
  });
  localizations = translationData;
  cell.setValue(localizations);

  let onChangeData = (data, index) => {
    let cellData = cell.value ?? [];
    cellData[index].translation = data;
    cell.setValue(cellData);
  };

  return (
    <div className="multiLanguageContainer">
      <TabPanel>
        {translationData.forEach((item, index) => {
          let itemLang = gridData.languageList?.find(
            (nq) => nq.id === item.languageId
          );
          return (
            <TabItem
              title={itemLang?.name}
              render={() => {
                if (cell.data.inputType === 1) {
                  return (
                    <TextArea
                      defaultValue={item.translation}
                      height={140}
                      onValueChanged={(data) => onChangeData(data, index)}
                    />
                  );
                } else if (cell.data.inputType === 2) {
                  return (
                    <div className={"editorContainer"}>
                      {/* <HtmlEditor
                                            initValue={item.translation}
                                            setValue={(content) => onChangeData(content, index)}
                                        /> */}
                    </div>
                  );
                } else if (cell.data.inputType === 3) {
                  return (
                    <div className="imageSelectorContainer">
                      {/* <ImageSelector defaultValue={getImageUrl(item.translation)}
                                            setValue={(value) => {
                                                onChangeData(value, index);
                                            }} /> */}
                    </div>
                  );
                } else if (cell.data.inputType === 4) {
                  return (
                    <div className="imageSelectorContainer">
                      {/* <FileSelector defaultValue={getImageUrl(item.translation)}
                                            setValue={(value) => {
                                                onChangeData(value, index);
                                            }} /> */}
                    </div>
                  );
                } else if (cell.data.inputType === 6) {
                  return (
                    <div className="tagInputContainer">
                      <TagInput
                        mode="tags"
                        onChange={(value) =>
                          onChangeData(value.toString(), index)
                        }
                        defaultValue={
                          item.translation?.length > 0 &&
                          item.translation?.split(",").length > 0
                            ? item.translation?.split(",")
                            : []
                        }
                      />
                    </div>
                  );
                }

                return (
                  <TextBox
                    defaultValue={item.translation}
                    onValueChanged={(data) => onChangeData(data.value, index)}
                  />
                );
              }}
            />
          );
        })}
      </TabPanel>
    </div>
  );
};
//Modelden Çekilmiş
const onExporting = (e, gridData) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(gridData.exportWorkSheet);
  const fileName = gridData.exportFileName;

  exportDataGrid({
    component: e.component,
    worksheet: worksheet,
    autoFilterEnabled: true,
  }).then(function () {
    workbook.xlsx.writeBuffer().then(function (buffer) {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        fileName
      );
    });
  });
  e.cancel = true;
};
//Modelden Çekilmiş
const RenderSubGripTab = (data, masterGrid, t) => {
  return (
    <TabPanel>
      {masterGrid.subGrids?.map((item, index) => {
        let activeGridData =
          typeof item.tabGridFeature === "function"
            ? item.tabGridFeature(data)
            : item.tabGridFeature;
        if (activeGridData && activeGridData.subGridFeature) {
          let filterKey = data.key;
          if (activeGridData.subGridFeature.loadParams === null)
            activeGridData.subGridFeature.loadParams = {
              [activeGridData.subGridFeature.relationKey]: filterKey,
            };
          else
            activeGridData.subGridFeature.loadParams = {
              ...activeGridData.subGridFeature.loadParams,
              [activeGridData.subGridFeature.relationKey]: filterKey,
            };

          return (
            <TabItem
              title={item.tabCaption}
              key={index}
              render={() =>
                activeGridData ? (
                  RenderSubGrid(activeGridData, filterKey, t, data)
                ) : (
                  <div />
                )
              }
            />
          );
        } else if (item.tabGridContent) {
          return (
            <TabItem
              title={item.tabCaption}
              key={index}
              render={() =>
                item.tabGridContent ? item.tabGridContent(data) : <div />
              }
            />
          );
        }
      })}
    </TabPanel>
  );
};
const GenerateSeoLink = (CreateElement) => {
  if (CreateElement === null || CreateElement === "") {
    return i18n.t("undefined");
  }
  CreateElement = CreateElement.toLowerCase();
  CreateElement = CreateElement.split("/")[CreateElement.split("/").length - 1];
  CreateElement = CreateElement.replace(/-/g, " ");
  CreateElement = CreateElement.replace(/ç/g, "c");
  CreateElement = CreateElement.replace(/ş/g, "s");
  CreateElement = CreateElement.replace(/ı/g, "i");
  CreateElement = CreateElement.replace(/ü/g, "u");
  CreateElement = CreateElement.replace(/ö/g, "o");
  CreateElement = CreateElement.replace(/ğ/g, "g");
  CreateElement = CreateElement.replace(/Ç/g, "C");
  CreateElement = CreateElement.replace(/Ş/g, "S");
  CreateElement = CreateElement.replace(/I/g, "I");
  CreateElement = CreateElement.replace(/Ü/g, "U");
  CreateElement = CreateElement.replace(/Ö/g, "O");
  CreateElement = CreateElement.replace(/Ğ/g, "G");
  CreateElement = CreateElement.replace(/^\s+|\s+$/g, "");
  let turnSeoUrl = "";
  for (let i = 0; i < CreateElement.length; i++) {
    switch (CreateElement.substring(i, i + 1)) {
      case " ":
        turnSeoUrl += "-";
        break;
      case "&":
        turnSeoUrl += "-";
        break;
      case "\\":
        turnSeoUrl += "-";
        break;
      case "/":
        turnSeoUrl += "-";
        break;
      default:
        if (
          (CreateElement.substring(i, i + 1) >= "0" &&
            CreateElement.substring(i, i + 1) <= "9") ||
          (CreateElement.substring(i, i + 1) >= "a" &&
            CreateElement.substring(i, i + 1) <= "z") ||
          (CreateElement.substring(i, i + 1) >= "A" &&
            CreateElement.substring(i, i + 1) <= "Z")
        )
          turnSeoUrl += CreateElement.substring(i, i + 1);
        break;
    }
  }
  turnSeoUrl = turnSeoUrl.replace("--", "-");
  turnSeoUrl = turnSeoUrl.replace("--", "-");
  turnSeoUrl = turnSeoUrl.replace("--", "-");
  if (
    turnSeoUrl === null ||
    turnSeoUrl === "" ||
    turnSeoUrl.indexOf("---") > -1
  ) {
    turnSeoUrl = i18n.t("undefined");
  }
  return turnSeoUrl;
};
const Camalize = (str) => {
  if (str.split(".").length > 1) {
    let firstItem = SubCamalize(str.split(".")[0]);
    let secondItem = SubCamalize(str.split(".")[1]);
    return `${firstItem}.${secondItem}`;
  } else {
    return SubCamalize(str);
  }
};
const SubCamalize = (str) => {
  return `${str.substr(0, 1).toLowerCase()}${str.substr(1, str.length - 1)}`;
};
//Modelden Çekilmiş
const GetEditSizeWidth = (gridData) => {
  if (gridData.editSize) {
    if (gridData.editSize === "small") {
      return "500px";
    } else if (gridData.editSize === "normal") {
      return "750px";
    }
  }
  return undefined;
};
//Modelden Çekilmiş
const GetEditSizeHeight = (gridData) => {
  if (gridData.editSize) {
    if (gridData.editSize === "small") {
      return "500px";
    } else if (gridData.editSize === "normal") {
      return "80%";
    }
  }
  return undefined;
};

export {
  Camalize,
  GenerateSeoLink,
  CreateGridData,
  RenderSubGrid,
  renderEditContent,
  RenderSubGripTab,
};

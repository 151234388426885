import React from "react";
import DataConnectionReportGrid from "./DataConnectionReportGrid";

const DataConnectionReportPage = () => {
  return (
    <div className="c-main">
      <div className="c-content">
        <DataConnectionReportGrid />
      </div>
    </div>
  );
};
export default DataConnectionReportPage;

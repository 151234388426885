import React from "react";
import { SelectBox } from "devextreme-react";
import { useEffect } from "react";
import { setSubGridApiDefault } from "../../../../config/fetchApiConfig";
import { useSelector, useDispatch } from "react-redux";
import { SET_RECIPERANGE_EDIT } from "../../../../redux/actions/actionTypes";
import { useTranslation } from "react-i18next";

const CentralSelectBox = ({ setValue, value }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [customValue, setCustomValue] = React.useState(null);
  const recipeRangeState = useSelector((p) => p.RecipeRangeReducer);

  useEffect(() => {
    dispatch({
      type: SET_RECIPERANGE_EDIT,
      payload: { dataField: "selectedCentralId", value: value },
    });
  }, [value]);

  return (
    <SelectBox
      dataSource={setSubGridApiDefault(
        "Central",
        "id",
        "GetListByAccountField",
        null,
        null,
        null,
        { accountFieldId: recipeRangeState.selectedAccountFieldId }
      )}
      displayExpr="name"
      onValueChanged={({ value }) => {
        setValue && setValue(value.id);
        setCustomValue(value.id);
        dispatch({
          type: SET_RECIPERANGE_EDIT,
          payload: { dataField: "selectedCentralId", value: value.id },
        });
      }}
      searchEnabled={true}
      value={recipeRangeState.selectedCentralId ?? value}
      placeholder={t("selectCentral")}
    />
  );
};
export default CentralSelectBox;

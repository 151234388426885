import React from "react";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../helper/Grid/GridCreatorMixin";
import { useTranslation } from "react-i18next";

const LogDetailReportGrid = () => {
  const { t } = useTranslation();
  const pageGrid = {
    apiController: "LogDetailView",
    apiKey: "Id",
    editTitle: t("logDetailInfo"),
    selection: false,
    editMode: {
      isActive: false,
      mode: "form",
      allowAdding: false,
      allowDeleting: false,
      allowUpdating: false,
      allowCopy: false,
      useIcons: false,
      containerColCount: 1,
      validationSummary: true,
    },
    columns: [
      {
        dataField: "LogLevel",
        caption: t("logLevel"),
      },
      {
        dataField: "CategoryName",
        caption: t("category"),
      },
      {
        dataField: "Message",
        caption: t("message"),
      },
      {
        dataField: "CreatedAt",
        caption: t("logDate"),
        dataType: "date",
        format: "dd/MM/yyyy HH:mm",
        sortOrder: "desc",
      },
      {
        dataField: "SbomName",
        caption: t("sbomCode"),
      },
      {
        dataField: "CentralName",
        caption: t("centralCode"),
      },
      {
        dataField: "AccountFieldName",
        caption: t("fieldCode"),
      },
      {
        dataField: "AccountName",
        caption: t("companyCode"),
      },
    ],
  };

  let gridData = CreateGridData(pageGrid);

  return <RenderMasterGrid gridData={gridData} />;
};
export default LogDetailReportGrid;

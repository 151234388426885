import React from "react";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../../../helper/Grid/GridCreatorMixin";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SbomProductCustomDetailGrid = () => {
  const { t } = useTranslation();
  var { sbomProductDetailId = 0 } = useParams();

  const pageGrid = {
    apiController: "SbomProductCustomDetail",
    apiKey: "Id",
    editTitle: t("customDetailInformation"),
    loadParams: { sbomProductDetailId: sbomProductDetailId },
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 1,
      validationSummary: true,
    },
    onInitNewRow: (options) => {
      options.data.isEmpty = false;
    },
    columns: [
      {
        dataField: "Row",
        caption: t("array"),
        dataType: "number",
      },
      {
        dataField: "ModulQty",
        caption: t("modulQty"),
        dataType: "number",
      },
      {
        dataField: "Branch",
        caption: t("brand"),
      },
      {
        dataField: "Model",
        caption: t("modulePower"),
        dataType: "number",
      },
      {
        dataField: "ModelId",
        caption: t("model"),
      },
      {
        dataField: "SunAngle",
        caption: t("sunAngle"),
        dataType: "number",
      },
      {
        dataField: "Slope",
        caption: t("slope"),
        dataType: "number",
      },
      {
        dataField: "IsEmpty",
        caption: t("isEmpty"),
        dataType: "boolean",
      }
    ],
  };

  let gridData = CreateGridData(pageGrid);

  return <RenderMasterGrid gridData={gridData} />;
};
export default SbomProductCustomDetailGrid;

import React from "react";
import TagCustomTypeGroupGrid from "./TagCustomTypeGroupGrid";

const TagCustomTypeGroupPage = () => {
  return (
    <div className="c-main">
      <div className="c-content">
        <TagCustomTypeGroupGrid />
      </div>
    </div>
  );
};
export default TagCustomTypeGroupPage;

import React from "react";
import RegionDropdown from "./RegionDropdown";
import CountryDropdown from "./CountryDropdown";

const CountryCityDropdown = ({
  setCityValue,
  setCountryValue,
  cityValue,
  countryValue,
}) => {
  const [countryId, setCountryId] = React.useState(null);
  const setCountry = (value) => {
    if (setCountryValue) {
      setCountryValue(value);
    }
    setCountryId(value);
  };
  return (
    <div className="c-country">
      <CountryDropdown
        setValue={setCountry}
        defaultCityId={cityValue}
        value={countryId}
      />
      <RegionDropdown
        setValue={setCityValue}
        countryId={countryId}
        value={cityValue}
      />
    </div>
  );
};
export default CountryCityDropdown;

import React from "react";
import LogDetailReportGrid from "./LogDetailReportGrid";

const LogDetailReportPage = () => {
  return (
    <div className="c-main">
      <div className="c-content">
        <LogDetailReportGrid />
      </div>
    </div>
  );
};
export default LogDetailReportPage;

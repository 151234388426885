import React from "react";
import axios from "axios";
import TreeView from "devextreme-react/tree-view";
import { getApiUrl } from "../../../config/applicationConfig";
import { useDispatch } from "react-redux";
import { Dropdown, Space, Menu, message } from "antd";
import { MoreOutlined, DeleteOutlined } from "@ant-design/icons";
import { dynamicSort, dynamicSort2 } from "../../../helper/JavascriptHelper";
import AnalysisPageTreeAddFavorite from "./Components/AnalysisPageTreeAddFavorite";
import { useSelector } from "react-redux";
import { GetFavoriteTagGroup } from "../../../redux/actions/Analysis/AnalysisActions";
import { useRecoilState } from "recoil";
import { selectedTagGroupData } from "./recoil/RecoilActions";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AnalysisPageTree = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const list = React.useRef(null);
  const [lastTop, setTop] = React.useState(0);
  const [dataSource, setDataSource] = React.useState([]);
  const [key, setKey] = React.useState(0);
  const [component, setComponent] = React.useState();
  const analysisState = useSelector((p) => p.AnalysisReducer);

  const setSelectedTagGroupDatas = useRecoilState(selectedTagGroupData)[1];

  React.useEffect(() => {
    dispatch(GetFavoriteTagGroup());
    if (dataSource.length <= 0) {
      axios
        .get(getApiUrl("/api/TagCustomTypeGroup/GetListView"))
        .then((res) => res.data)
        .then((res) => {
          let data = res.data.sort((p) => p.groupName).reverse();
          if (data.filter((p) => !p.masterId).length > 0) {
            data
              .filter((p) => !p.masterId)
              .map((item) => (item["expanded"] = true));
          }
          setDataSource(data);
        });
    }
  }, []);

  const SetFavoriteTagGroupDetail = (favoriteTagGroupId) => {
    component.unselectAll();
    axios
      .get(getApiUrl("/api/TagCustomTypeGroupFavoriteDetail/GetList"), {
        params: {
          tagCustomTypeGroupFavoriteId: favoriteTagGroupId,
        },
      })
      .then((res) => res.data)
      .then((res) => {
        res.data?.forEach((e) => {
          dataSource
            .filter((p) => p.id == e.treeviewId)
            .forEach((item) => component.selectItem(item));
        });
      })
      .catch(() => {});
  };
  const DeleteFavoriteTagGroupDetail = (favoriteTagGroupId) => {
    axios
      .delete(getApiUrl("/api/TagCustomTypeGroupFavorite/Delete"), {
        params: {
          key: favoriteTagGroupId,
        },
      })
      .then((res) => res.data)
      .then((res) => {
        dispatch(GetFavoriteTagGroup());
        message.success(res.message);
      })
      .catch(() => {});
  };

  const filterMenu = (
    <Menu>
      <Menu.SubMenu key={0} title={t("sort")}>
        <Menu.Item
          key={"0-1"}
          onClick={(e) => {
            setDataSource(dataSource.sort(dynamicSort("name")));
            setKey(key + 1);
          }}
        >
          {t("alphabetical")}
        </Menu.Item>
        <Menu.Item
          key={"0-2"}
          onClick={(e) => {
            setDataSource(dataSource.sort(dynamicSort("createDate")));
            setKey(key + 1);
          }}
        >
          {t("dateOfRegistration")}
        </Menu.Item>
        <Menu.Item
          key={"0-3"}
          onClick={(e) => {
            dynamicSort2(dataSource, "masterId", "groupName").then((val) => {
              setDataSource(val);
              // setTimeout(() => {
              //   setKey(key+1)
              // }, 300);
            });
          }}
        >
          {t("rowNumber")}
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu key={1} title={t("favorite")}>
        {analysisState.favoriteTagGroup?.map((item) => (
          <Menu.Item
            key={"1-" + item.id}
            onClick={() => SetFavoriteTagGroupDetail(item.id)}
          >
            <div style={{ display: "flex", maxWidth: "150px" }}>
              <div
                style={{ maxWidth: "130px", overflow: "auto" }}
                onClick={() => SetFavoriteTagGroupDetail(item.id)}
              >
                <a>{item.name}</a>
              </div>
              <div
                style={{ minWidth: "20px", marginLeft: "10px" }}
                onClick={() => DeleteFavoriteTagGroupDetail(item.id)}
              >
                <DeleteOutlined />
              </div>
            </div>
          </Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.Item
        key={3}
        onClick={(e) => {
          setSelectedTagGroupDatas([]);
          setTop(0);
          component.unselectAll();
        }}
      >
        {t("clearSelection")}
      </Menu.Item>
      <Menu.Item key={4} onClick={(e) => list?.current?.instance?.expandAll()}>
        {t("openAll")}
      </Menu.Item>
      <Menu.Item
        key={5}
        onClick={(e) => list?.current?.instance?.collapseAll()}
      >
        {t("closeAll")}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="c-select__tree">
      <div className="c-select__tree-header" style={{ display: "flex" }}>
        <strong>{t("templates")}</strong>

        <div style={{ paddingLeft: "65%" }}>
          <Dropdown overlay={filterMenu} trigger={["click"]}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <MoreOutlined style={{ fontSize: "18px", color: "black" }} />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>

      <div className="c-select__tree-menu">
        <TreeView
          onContentReady={(e) => {
            setComponent(e.component);
            list?.current?.instance?._scrollableContainer.scrollTo(lastTop);
          }}
          ref={list}
          key={key}
          dataSource={dataSource}
          searchEnabled={true}
          searchEditorOptions={{ placeholder: "Arama..." }}
          dataStructure="plain"
          keyExpr="id"
          parentIdExpr="masterId"
          selectionMode="multiple"
          displayExpr="name"
          selectByClick={true}
          showCheckBoxesMode="selectAll"
          height="calc(100% - 35px)"
          itemRender={(item) =>
            item.masterId ? (
              <p>{item.name}</p>
            ) : (
              <p>
                <b>{item.name}</b>
              </p>
            )
          }
          onSelectAllValueChanged={(e) => {
            if (e.value === true) {
              setSelectedTagGroupDatas(e.component.getSelectedNodes());
              setTop(list.current.instance._scrollableContainer.scrollTop());
            }
          }}
          onItemSelectionChanged={async (e) => {
            setTop(list.current.instance._scrollableContainer.scrollTop());
            await setSelectedTagGroupDatas(e.component.getSelectedNodes());
          }}
        />
        <AnalysisPageTreeAddFavorite />
      </div>
    </div>
  );
};

export default AnalysisPageTree;

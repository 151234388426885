import React from "react";
import ProductUnitGrid from "./ProductUnitGrid";

const ProductUnitPage = () => {
  return (
    <div className="c-main">
      <div className="c-content">
        <ProductUnitGrid />
      </div>
    </div>
  );
};
export default ProductUnitPage;

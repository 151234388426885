import React, { useState } from "react";
import { Modal, Button } from "antd";
import AddStepForm from "./AddStepForm";
import { useDispatch, useSelector } from "react-redux";
import {
  CLEAR_ALARM_TYPE,
  SET_ALARM_TRIGGER,
} from "../../../redux/actions/actionTypes";
import { getApiUrl } from "./../../../config/applicationConfig";
import { fetcher } from "../../../config/fetchApiConfig";
import { useTranslation } from "react-i18next";

const AddAlarmModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [key, setKey] = useState(0);
  const userState = useSelector((p) => p.SignedUserReducer);

  const showModal = () => {
    setIsModalVisible(true);
    setKey(key+1)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    dispatch({ type: CLEAR_ALARM_TYPE, payload: {} });
    // window.location.reload(false);
  };

  React.useEffect(() => {
    if (isModalVisible) {
      fetcher(getApiUrl(`/api/AlarmTrigger/AutoGenerateCode`))
        .then((res) => res.json())
        .then((data) => {
          dispatch({
            type: SET_ALARM_TRIGGER,
            payload: { dataField: "code", value: data },
          });
        });
    }
  }, [isModalVisible]);

  return (
    <>
      <Button type="primary" onClick={showModal} hidden={!userState.user.userPermissions.includes("api/AlarmTrigger/Insert")}>
        {t("addNew")}
      </Button>
      <Modal
        key={key}
        title={t("alarm")}
        visible={isModalVisible}
        width={1000}
        okButtonProps={{ hidden: true }}
        cancelText={t("cancel")}
        onCancel={handleCancel}
      >
        <AddStepForm setIsModalVisible={setIsModalVisible} />
      </Modal>
    </>
  );
};
export default AddAlarmModal;

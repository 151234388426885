import React from "react";
import moment from "moment";
import { Line as LineAntd } from "@ant-design/plots";

const LineChart = ({
  dataSource,
  xField,
  yField,
  seriesField,
  xAxisType = "time",
}) => {
  const config = {
    data: dataSource,
    xField,
    yField,
    seriesField,
    xAxis: {
      label: {
        formatter: (v) => customizeLabel(v),
      },
    },
    meta: {
      [yField]: {
        min: Math.floor(Math.min(...dataSource.map((p) => p[yField])) - 1),
        max: Math.ceil(Math.max(...dataSource.map((p) => p[yField])) + 1),
      },
    },
  };

  const customizeLabel = (e) => {
    var tt = new moment(e);
    return tt.format("hh:mm A");
  };

  return <LineAntd {...config} />;
};
export default LineChart;

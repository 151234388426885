import { Tabs } from "antd";
import BrowserPlot from "./BrowserPlot";
import { useTranslation } from "react-i18next";
const { TabPane } = Tabs;

const onChange = (key) => {
  console.log(key);
};

const BrowserGraph = () => (
  <div className="c-browser__graph">
    <Tabs
      defaultActiveKey="1"
      onChange={onChange}
      size="small"
      className="c-browser__graph-tabs"
    >
      {/* <TabPane tab={t("plotBig")} key="1">
        <BrowserPlot />
      </TabPane>
      <TabPane tab={t("exportBig")} key="2">
        <BrowserPlot />
      </TabPane>
      <TabPane tab={t("realTimeMonitorBig")} key="3">
        <BrowserPlot />
      </TabPane>
      <TabPane tab={t("importBig")} key="4">
        <BrowserPlot />
      </TabPane> */}
    </Tabs>
  </div>
);
export default BrowserGraph;

import { CoordinateType } from "./CoordinateType";
import { SqlOperatorType } from "./SqlOperatorType";
import { OperatorType } from "./OperatorType";
import { CalculateFunctionType } from "./CalculateFunctionType";
import { MoveType } from "./MoveType";
import { PriorityType } from "./PriorityType";
import { IecStandardType } from "./IecStandardType";
import { AlarmCauseType } from "./AlarmCauseType";
import i18n from "../LanguageSupport/i18nextInit";

const CoordinateTypeSource = [
  { key: CoordinateType.ED50, value: "ED50" },
  { key: CoordinateType.WG84, value: "WG84" },
];

const SqlOperatorTypeSource = [
  { key: SqlOperatorType.And, value: i18n.t("and") },
  { key: SqlOperatorType.Or, value: i18n.t("or") },
];
const OperatorTypeSource = [
  { key: OperatorType.Equals, value: i18n.t("equals") },
  { key: OperatorType.Greater, value: i18n.t("isGreater") },
  { key: OperatorType.Less, value: i18n.t("isSmall") },
  { key: OperatorType.GreaterEqual, value: i18n.t("greatEquals") },
  { key: OperatorType.LessEqual, value: i18n.t("smallEquals") },
];

const CalculateFunctionTypeSource = [
  { key: CalculateFunctionType.ModbusDatetime, value: i18n.t("modbusDatetime") },
];
const MoveTypeSource = [
  { key: MoveType.Entry, value: i18n.t("entry") },
  { key: MoveType.Exit, value: i18n.t("out") },
];

const PriorityTypeSource = [
  { key: PriorityType.Low, value: i18n.t("low") },
  { key: PriorityType.Middle, value: i18n.t("medium") },
  { key: PriorityType.High, value: i18n.t("high") },
];

const IecStandardTypeSource = [
  { key: IecStandardType.ClassA, value: i18n.t("classA") },
  { key: IecStandardType.ClassB, value: i18n.t("classB") },
  { key: IecStandardType.ClassC, value: i18n.t("classC") },
];

const AlarmCauseTypeSource = [
  { key: AlarmCauseType.Planned, value: i18n.t("planned") },
  { key: AlarmCauseType.Unplanned, value: i18n.t("unplanned") },
  { key: AlarmCauseType.BusinessSourced, value: i18n.t("businessSourced") },
];

export {
  CoordinateTypeSource,
  SqlOperatorTypeSource,
  OperatorTypeSource,
  CalculateFunctionTypeSource,
  MoveTypeSource,
  PriorityTypeSource,
  IecStandardTypeSource,
  AlarmCauseTypeSource,
};

import React from "react";
import { SelectBox } from "devextreme-react";
import {
  fetcher,
  setSubGridApiDefault,
} from "../../../../config/fetchApiConfig";
import { useEffect } from "react";
import { getApiUrl } from "../../../../config/applicationConfig";
import { useTranslation } from "react-i18next";

const CountryDropdown = ({ setValue, defaultCityId, value }) => {
  const [customValue, setCustomValue] = React.useState(null);
  useEffect(() => {
    if (value) {
      setCustomValue(value);
    }
    if (defaultCityId) {
      fetcher(getApiUrl(`/api/Country/GetByCity?cityId=${defaultCityId}`))
        .then((res) => res.json())
        .then((data) => {
          setCustomValue(data.id);
        });
    }
  }, [defaultCityId]);

  const { t } = useTranslation();

  return (
    <SelectBox
      className="c-country__drop"
      dataSource={setSubGridApiDefault(
        "Country",
        "id",
        "GetList",
        null,
        null,
        null
      )}
      displayExpr="name"
      onValueChanged={({ value }) => {
        setValue && setValue(value.id);
        setCustomValue(value.id);
      }}
      searchEnabled={true}
      value={customValue}
      placeholder={t("selectCountry")}
    />
  );
};
export default CountryDropdown;

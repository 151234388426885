import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Drawer, Input, Space, Divider, Segmented } from "antd";
import { SelectBox, TextBox } from "devextreme-react";
import { setSubGridApiDefault } from "../../../config/fetchApiConfig";
import DropDownBox from "devextreme-react/drop-down-box";
import TreeView from "devextreme-react/tree-view";
import axios from "axios";
import { getApiUrl } from "./../../../config/applicationConfig";
import { WidgetChartType } from "./../../../helper/enums/WidgetChartType";
import { useSelector, useDispatch } from "react-redux";
import { InsertDashboardWidgetLayout } from "../../../redux/actions/CentralTracing/CentralTracingActions";
import { useTranslation } from "react-i18next";

const AddNewWidget = () => {
    const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = React.useState([]);
  const [defaultDataSource, setDefaultDataSource] = React.useState([]);
  const dashboardState = useSelector((p) => p.DashboardReducer);

  const [state, setState] = React.useState({
    alarmSbomTreeviews: [],
    elasticsearchReportId: null,
    title: null,
    widgetChartType: 0,
    visible: false,
    dashboardWidgetId: null,
  });

  const showDrawer = () => {
    setState({ ...state, visible: true });
  };

  const onClose = () => {
    setState({ ...state, visible: false });
  };
  const onAddItem = () => {
    InsertDashboardWidgetLayout(state, dispatch);
  };

  const segmentedOptions = [
    {
      label: (
        <div style={{ padding: 4 }}>
          <i className="fas fa-chart-bar fa-5x"></i>
        </div>
      ),
      value: WidgetChartType.Bar,
    },
    {
      label: (
        <div style={{ padding: 4 }}>
          <i className="fas fa-chart-area fa-5x"></i>
        </div>
      ),
      value: WidgetChartType.Area,
    },
    {
      label: (
        <div style={{ padding: 4 }}>
          <i className="fas fa-chart-scatter fa-5x"></i>
        </div>
      ),
      value: WidgetChartType.Scatter,
    },
  ];

  React.useEffect(() => {
    axios
      .get(getApiUrl("/api/AlarmSbomTreeview/GetListWidget"))
      .then((res) => res.data)
      .then((res) => {
        setDataSource(res.data);
        setDefaultDataSource(res.data);
      });
  }, []);
  
  React.useEffect(() => {
    setState({
      ...state,
      dashboardWidgetId: dashboardState.dashboardWidgets.find((p) => p.isActive)
        ?.id,
    });
  }, [dashboardState]);

  function searchId(masterId, out) {
    defaultDataSource.forEach((data) => {
      if (data.id == masterId && out.findIndex((p) => p.id == data.id) < 0) {
        out.push(data);
        if (data.masterId) {
          searchId(data.masterId, out);
        }
      }
    });
    return out;
  }

  function searchTree(datas, matchingTitle) {
    let out = [];
    datas.forEach((data) => {
      if (data.name.toLowerCase().includes(matchingTitle.toLowerCase())) {
        out.push(data);
        if (data.masterId) {
          out = searchId(data.masterId, out);
        }
      }
    });
    return out;
  }

  const treeViewRender = () => {
    const _valueChanged = ({ value }) => {
      setDataSource(searchTree(defaultDataSource, value));
    };

    return (
      <>
        <TextBox
          placeholder={t("search")}
          showClearButton={true}
          onValueChanged={_valueChanged}
        />
        <TreeView
          dataSource={dataSource}
          dataStructure="plain"
          keyExpr="id"
          parentIdExpr="masterId"
          selectionMode="multiple"
          displayExpr="name"
          selectByClick={true}
          showCheckBoxesMode="normal"
          height="95%"
          onItemSelectionChanged={(e) => {
            setState({
              ...state,
              alarmSbomTreeviews: e.component
                .getSelectedNodes()
                .map((e) => e.itemData),
            });
          }}
        />
      </>
    );
  };
  return (
    <div>
      <div className="c-widget__add">
        <Button
          className="c-widget__add-button"
          type="primary"
          onClick={showDrawer}
          icon={<PlusOutlined />}
        />
      </div>
      <Drawer
        title={t("addWidget")}
        width={500}
        placement="right"
        visible={state.visible}
        getContainer={false}
        onClose={onClose}
        className="c-widget__menu"
      >
        <div className="c-widget__menu-content">
          <Divider orientation="left" plain>
            {t("title")}
          </Divider>
          <div className="c-widget__menu-area-title">
            <Input
              placeholder={t("title")}
              onChange={(e) => setState({ ...state, title: e.target.value })}
            />
          </div>
          <Divider orientation="left" plain>
            {t("display")}
          </Divider>
          <div className="c-widget__menu-area-checkbox">
            <Segmented
              defaultValue={state.widgetChartType}
              options={segmentedOptions}
              onChange={(e) => setState({ ...state, widgetChartType: e })}
            />
          </div>
          <Divider orientation="left" plain>
            {t("parameters")}
          </Divider>
          <div className="c-widget__menu-area-select">
            <span className="c-widget__menu-area-select-title">
              {t("source")}
            </span>
            <SelectBox
              dataSource={setSubGridApiDefault(
                "ElasticsearchReport",
                "id",
                "GetList",
                null,
                null,
                null
              )}
              displayExpr="name"
              searchEnabled={true}
              placeholder={t("selectSource")}
              onItemClick={(e) =>
                setState({ ...state, elasticsearchReportId: e.itemData.id })
              }
            />
          </div>
          <div className="c-widget__menu-area-select">
            <span className="c-widget__menu-area-select-title">
              {t("parameters")}
            </span>
            <DropDownBox
              valueExpr="id"
              displayExpr="name"
              placeholder={t("selectParameters")}
              showClearButton={true}
              contentRender={treeViewRender}
              value={state.alarmSbomTreeviews.map((e) => e.name)}
              dataSource={dataSource}
            />
          </div>
        </div>
        <Space className="c-widget__menu-bottom">
          <Button onClick={onClose}>{t("cancel")}</Button>
          <Button onClick={onAddItem} type="primary">
            {t("add")}
          </Button>
        </Space>
      </Drawer>
    </div>
  );
};
export default AddNewWidget;

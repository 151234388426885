import React from "react";
import AnalysisPageMenu from "./AnalysisPageMenu";
import AnalysisPageGrid from "./AnalysisPageGrid";
import HeaderFilterAnalysis from "./Components/HeaderFilterAnalysis";
import { SetDefaultAnalysis } from "./recoil/RecoilActions";

const AnalysisPage = () => {
  return (
    <div>
      <SetDefaultAnalysis />
      <div className="c-page">
        <HeaderFilterAnalysis pageName="Analizler" />
      </div>
      <div className="c-main">
        <div className="c-select__top">
          <AnalysisPageMenu />
        </div>
        <div className="c-select__bottom">
          <div className="c-content">
            <AnalysisPageGrid />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalysisPage;

import React from "react";
import EditForm from "../../../../../components/Shared/form/EditForm.js";
import { CreateGridData } from "../../../../../helper/Grid/GridCreatorMixin.js";
import { useHistory } from "react-router-dom";
import { paths } from "./../../../../../helper/resources/paths";
import { useDispatch, useSelector } from "react-redux";
import { SET_SBOM_DETAIL } from "../../../../../redux/actions/actionTypes.js";
import axios from "axios";
import { getApiUrl } from "./../../../../../config/applicationConfig";
import { useTranslation } from "react-i18next";

const NewSbom = () => {
  const { t } = useTranslation();
  const productTreeState = useSelector((p) => p.ProductTreeReducer);
  const [formData, setFormData] = React.useState({
    code: "",
    isActive: true,
  });
  const history = useHistory();
  const dispatch = useDispatch();

  React.useEffect(() => {
    axios
      .get(getApiUrl("/api/Sbom/AutoGenerateCode"), {
        params: {
          centralId: productTreeState.selectedCentralId,
        },
      })
      .then((res) => res.data)
      .then((res) => setFormData({ ...formData, code: res }))
      .catch((res) => console.log(res));
  }, [productTreeState]);

  const _onSubmit = (formData) => {
    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });

    dispatch({ type: SET_SBOM_DETAIL, payload: object });
    history.push(paths.managament.productTree.productSelect);
  };
  const pageGrid = {
    apiController: "Sbom",
    apiKey: "Id",
    editTitle: t("treeInformation"),
    onSubmit: _onSubmit,
    formData: formData,
    hideIsActive: true,
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 2,
      validationSummary: true,
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        disabled: true,
      },
      {
        dataField: "Name",
        caption: t("name"),
      },
      {
        dataField: "IsActive",
        caption: t("active"),
        editorType: "dxCheckBox",
      },
    ],
  };
  var gridData = CreateGridData(pageGrid);

  return (
    <div>
      <EditForm gridData={gridData} />
    </div>
  );
};
export default NewSbom;

import React from "react";
import { useHistory } from "react-router-dom";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../helper/Grid/GridCreatorMixin";
import { paths } from "../../../helper/resources/paths";
import { useDispatch } from "react-redux";
import { SET_SELECTED_CENTRAL_ID } from "../../../redux/actions/actionTypes";
import { Button } from "antd";
import currencies from "../../../assets/localData/currencies.json";
import { GetTreeViewData } from "../../../redux/actions/Sbom/TreeViewActions";
import {
  CoordinateTypeSource,
  IecStandardTypeSource,
} from "../../../helper/enums/EnumDataSource";
import { CoordinateType } from "../../../helper/enums/CoordinateType";
import { NumberBox, SelectBox } from "devextreme-react";
import DataSource from "devextreme/data/data_source";
import { setSubGridApiDefault } from "../../../config/fetchApiConfig";
import { useTranslation } from "react-i18next";

const CentralManagamentGrid = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const updateGridHide = (data) => {
    if (data?.coordinateType === CoordinateType.ED50) {
      if (document.getElementById("centralGridId")) {
        document.getElementById("centralGridId").style.visibility = null;
      }
    } else if (data?.coordinateType === CoordinateType.WG84) {
      if (document.getElementById("centralGridId")) {
        document.getElementById("centralGridId").style.visibility = "hidden";
      }
    }
  };
  const modelDataSource = new DataSource({
    store: setSubGridApiDefault("AccountField", "id", "GetListTreeView"),
    group: "accountName",
  });
  const pageGrid = {
    apiController: "Central",
    apiKey: "Id",
    editTitle: t("centralInfo"),
    loadParams: { accountFieldId: 0 },
    onInitNewRow: (options) => {
      options.data.priceType = "TRY";
      options.data.coordinateType = CoordinateType.WG84;
    },
    editMode: {
      onChangesChange: (e) => {
        if (e[0]?.data) {
          updateGridHide(e[0].data);
        }
      },
      allowAllCopy: true,
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 1,
      validationSummary: true,
      customButtons: [
        {
          width: 170,
          caption: undefined,
          buttons: [
            {
              render: (e) => {
                let data = e.row.data;
                let url = paths.managament.productTree.index;

                return (
                  <Button
                    className="btn-grid-button"
                    onClick={() => {
                      dispatch({
                        type: SET_SELECTED_CENTRAL_ID,
                        payload: data.id,
                      });
                      dispatch(GetTreeViewData(data.id));
                      history.push(url);
                    }}
                  >
                    {t("goToBom")}
                  </Button>
                );
              },
            },
          ],
        },
      ],
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 5,
          },
        },
      },
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "Latitude",
        caption: t("latitude"),
        dataType: "number",
      },
      {
        dataField: "Longitude",
        caption: t("longitude"),
        dataType: "number",
      },
      {
        dataField: "AcRatedPower",
        caption: t("acRatedPower"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "DcRatedPower",
        caption: t("dcRatedPower"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "DcRestrictPower",
        caption: t("dcRestrictPower"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "PriceType",
        caption: t("priceType"),
        localRelationContent: {
          dataSource: currencies,
          value: "Symbol",
          display: "Symbol",
        },
        fieldValidation: {
          isRequired: true,
        },
      },
      // {
      //     dataField: "Price",
      //     caption: "Fiyat",
      //     dataType: "number"
      // },
      {
        dataField: "RadiationLimit",
        caption: t("radiationLimit"),
        dataType: "number",
      },
      {
        dataField: "AccountId",
        caption: t("company"),
        dataType: "number",
        relationContent: {
          controller: "Account",
          display: "Name",
        },
        editCellVisibility: false,
      },
      {
        dataField: "AccountFieldId",
        caption: t("area"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        relationContent: {
          controller: "AccountField",
          display: "Name",
        },
        editCellComponent: (cell) => {
          return (
            <SelectBox
              dataSource={modelDataSource}
              valueExpr="id"
              grouped={true}
              onValueChanged={({ value }) => cell.setValue(value)}
              searchEnabled={true}
              defaultValue={cell.value}
              displayExpr="name"
              showClearButton={true}
            />
          );
        },
      },
      {
        dataField: "CentralTypeId",
        caption: t("centralType"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        relationContent: {
          controller: "CentralType",
          display: "Name",
        },
      },
      {
        dataField: "CoordinateType",
        caption: t("coordinateType"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        localRelationContent: {
          dataSource: CoordinateTypeSource,
        },
      },
      {
        dataField: "Grid",
        caption: t("grid"),
        dataType: "number",
        editCellComponent: (cell) => {
          return (
            <NumberBox
              defaultValue={cell.value}
              onValueChanged={(e) => cell.setValue(e.value)}
              id="centralGridId"
              style={{ visibility: cell.value ? null : "hidden" }}
            />
          );
        },
      },
      {
        dataField: "IecStandardType",
        caption: "IEC 61724-1",
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        localRelationContent: {
          dataSource: IecStandardTypeSource,
        },
      },
    ],
  };

  const featuresGrid = {
    apiController: "CentralFeature",
    apiKey: "Id",
    editTitle: t("centralFeature"),
    subGridFeature: {
      loadUrl: "GetList",
      relationKey: "CentralId",
      insertUrl: "Insert",
      updateUrl: "Update",
      removeUrl: "Delete",
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
    ],
  };
  const recipesGrid = {
    apiController: "CentralRecipeList",
    apiKey: "Id",
    editTitle: t("centralRecipeList"),
    subGridFeature: {
      loadUrl: "GetList",
      relationKey: "CentralId",
      insertUrl: "Insert",
      updateUrl: "Update",
      removeUrl: "Delete",
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
      {
        dataField: "StartDate",
        caption: t("startDate"),
        editorType: "dxDateBox",
      },
      {
        dataField: "RecipeRangeId",
        caption: t("recipeRangeId"),
        dataType: "number",
        relationContent: {
          controller: "RecipeRange",
          display: "Code",
        },
      },
    ],
  };

  let gridData = CreateGridData(pageGrid);
  const featuresGridData = CreateGridData(featuresGrid);
  const recipesGridData = CreateGridData(recipesGrid);

  gridData.subGrids = [
    {
      tabCaption: t("features"),
      tabGridFeature: featuresGridData,
    },
    {
      tabCaption: t("recipeDefinition"),
      tabGridFeature: recipesGridData,
    },
  ];

  return <RenderMasterGrid gridData={gridData} />;
};
export default CentralManagamentGrid;

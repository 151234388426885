import React from "react";
import TagCustomTypeGrid from "./TagCustomTypeGrid";

const TagCustomTypePage = () => {
  return (
    <div className="c-main">
      <div className="c-content">
        <TagCustomTypeGrid />
      </div>
    </div>
  );
};
export default TagCustomTypePage;

import { atom, selector, useRecoilState } from "recoil";
import React from "react";
import { useHistory } from "react-router-dom";
import { addDays } from "../../../../helper/JavascriptHelper";
import { SunriseType } from "../../../../helper/enums/SunriseType";
import { AsyncGetCustomTagDataMovement } from "../../../../redux/actions/Analysis/AnalysisActions";

const flatten = (data, retData) => {
  retData.push(data.itemData);
  if (data.parent != null) {
    flatten(data.parent, retData);
  } else {
    return retData;
  }
};
const getSelectedData = (datas) => {
  let retData = [];
  datas.forEach((data) => {
    flatten(data, retData);
  });

  return retData;
};

export const selectedCentral = atom({
  key: "selectedCentral",
  default: [],
  dangerouslyAllowMutability: true,
});

export const selectedTagGroupData = atom({
  key: "selectedTagGroupData",
  default: [],
  dangerouslyAllowMutability: true,
});

export const analysisPageKey = atom({
  key: "analysisPageKey",
  default: 0,
  dangerouslyAllowMutability: true,
});

export const leftOpenState = atom({
  key: "leftOpen",
  default: true,
  dangerouslyAllowMutability: true,
});

export const dateFilterState = atom({
  key: "dateFilter",
  default: {
    startDate: addDays(Date.now(), -1),
    endDate: addDays(Date.now(), 0),
  },
  dangerouslyAllowMutability: true,
});

export const sunriseTypeState = atom({
  key: "sunriseTypeState",
  default: SunriseType.All,
  dangerouslyAllowMutability: true,
});

export const selectedProductState = atom({
  key: "selectedProductState",
  default: [],
  dangerouslyAllowMutability: true,
});

export const selectedCategoryState = atom({
  key: "selectedCategoryState",
  default: [],
  dangerouslyAllowMutability: true,
});

export const refreshAction = atom({
  key: "refreshAction",
  default: 0,
  dangerouslyAllowMutability: true,
});

export const openComponentState = atom({
  key: "openComponentState",
  default: false,
  dangerouslyAllowMutability: true,
});

export const showingGraphData = atom({
  key: "showingGraphData",
  default: [],
  dangerouslyAllowMutability: true,
});

export const graphDataState = selector({
  key: "graphDataState",
  get: ({ get }) => {
    const tagGroupData = get(selectedTagGroupData);
    if (tagGroupData.length <= 0) {
      return [];
    }

    const centrals = get(selectedCentral);
    if (centrals.length <= 0) {
      return [];
    }

    const refresh = get(refreshAction);
    const isOpenComponent = get(openComponentState);

    if (isOpenComponent) {
      return null;
    }

    const dateFilter = get(dateFilterState);
    const products = get(selectedProductState);
    const categories = get(selectedCategoryState);
    const sunriseType = get(sunriseTypeState);

    try {
      const data = AsyncGetCustomTagDataMovement(
        dateFilter,
        getSelectedData(centrals),
        getSelectedData(tagGroupData).filter((p) => p.type === 4),
        products,
        categories,
        sunriseType
      );
      return data;
    } catch (err) {
      return [];
    }
  },
});

export const SetDefaultAnalysis = () => {
  const history = useHistory();
  const setSelectedCentral = useRecoilState(selectedCentral)[1];
  const setSelectedTagGroup = useRecoilState(selectedTagGroupData)[1];
  const setLeftOpen = useRecoilState(leftOpenState)[1];
  const setDateFilter = useRecoilState(dateFilterState)[1];

  React.useEffect(() => {
    return history.listen((location) => {
      setSelectedCentral([]);
      setSelectedTagGroup([]);
      setLeftOpen(true);
      setDateFilter({
        startDate: addDays(Date.now(), -1),
        endDate: addDays(Date.now(), 0),
      });
    });
  }, [history]);
  return <></>;
};

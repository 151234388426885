import React from "react";
import { useTranslation } from "react-i18next";

const OverviewPage = () => {
    const { t } = useTranslation();
  return (
    <div className="c-main">
      <div className="c-content">{t("overview")}</div>
    </div>
  );
};
export default OverviewPage;

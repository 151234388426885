import React from "react";
import RecipeRangeGrid from "./RecipeRangeGrid";

const RecipeRangePage = () => {
  return (
    <div className="c-main">
      <div className="c-content">
        <RecipeRangeGrid />
      </div>
    </div>
  );
};
export default RecipeRangePage;

import React from "react";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../helper/Grid/GridCreatorMixin";
import { useTranslation } from "react-i18next";

const ProductTypeGrid = () => {
  const { t } = useTranslation();
  const pageGrid = {
    apiController: "ProductType",
    apiKey: "Id",
    editTitle: t("productTypeInfo"),
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 1,
      validationSummary: true,
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
    ],
  };

  return <RenderMasterGrid gridData={CreateGridData(pageGrid)} />;
};
export default ProductTypeGrid;

import axios from "axios";
import { getApiUrl } from "./../../../config/applicationConfig";
import { GET_DASHBOARD_WIDGET_DATA } from "./../actionTypes";

export const GetDashboardWidgetData = (
  dashboardWidgetLayoutId,
  startDate,
  endDate
) => {
  return async (dispatch) => {
    axios
      .get(getApiUrl("/api/CustomTagDataMovement/GetList"), {
        params: {
          dashboardWidgetLayoutId,
          startDate,
          endDate,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        dispatch({
          type: GET_DASHBOARD_WIDGET_DATA,
          payload: { id: dashboardWidgetLayoutId, data: data.data },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

import React from "react";
import AnalysisPageTree from "./AnalysisPageTree";
import AnalysisPageGraph from "./AnalysisPageGraph";
import CentralPageTree from "./CentralPageTree";
import { analysisPageKey, leftOpenState } from "./recoil/RecoilActions";
import { useRecoilState } from "recoil";

const AnalysisPageMenu = () => {
  const [leftOpen, setLeftOpen] = useRecoilState(leftOpenState);
  const [key, setKey] = useRecoilState(analysisPageKey);

  const toggleSidebar = (event) => {
    setLeftOpen(!leftOpen);
    setKey(key + 1);
  };

  let iconOpen = leftOpen ? "fas fa-angle-left" : "fas fa-angle-right";

  return (
    <div id="layout">
      <div id="left" className={leftOpen ? "open" : "closed"}>
        <div className={`sidebar ${leftOpen ? "open" : "closed"}`}>
          <div className="c-select__top-left">
            <CentralPageTree />
            <AnalysisPageTree />
          </div>
        </div>
        <div className="icon" onClick={toggleSidebar}>
          <i className={iconOpen}></i>
        </div>
      </div>
      <div id="main">
        <div className="content">
          <div className="c-select__top-right">
            <AnalysisPageGraph />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AnalysisPageMenu;

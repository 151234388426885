import React, { useState } from "react";
import { Modal, Button, Input } from "antd";
import messageError from "./../../../../helper/messages/errorMessage";
import { UpdateRangeDescription } from "../../../../redux/actions/Alarm/AlarmActions";
import { useTranslation } from "react-i18next";

const UpdateDescriptionModal = ({ gridRef }) => {
  const [description, setDescription] = useState("");
  const style = {
    inputStyle: { width: "auto", marginBottom: "10px", marginTop: "7px" },
    labelStyle: { fontWeight: "bold" },
  };
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    if (gridRef.instance.getSelectedRowKeys() <= 0) {
      messageError({ text: t("selectAlarm") });
    } else {
      setIsModalVisible(true);
    }
  };

  const handleOk = () => {
    let ids = gridRef.instance.getSelectedRowKeys();
    UpdateRangeDescription(ids, description);
    setIsModalVisible(false);
    gridRef.instance.clearSelection();
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Açıklama Güncelle
      </Button>
      <Modal
        title={t("alarm")}
        visible={isModalVisible}
        width={1000}
        okText={t("update")}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
        cancelText={t("cancel")}
      >
        <div>
          <div>
            <label style={style.labelStyle}>Açıklama</label>
            <Input
              className="ant-input"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
export default UpdateDescriptionModal;

import React from "react";
import { fetcher } from "../../../config/fetchApiConfig";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../helper/Grid/GridCreatorMixin";
import { doInputEvent } from "./../../../helper/JavascriptHelper";
import { getApiUrl } from "./../../../config/applicationConfig";
import { Input } from "antd";
import { useTranslation } from "react-i18next";

const RegisterTagModelAddGrid = () => {
  const { t } = useTranslation();
  const pageGrid = {
    apiController: "ProductRegisterModel",
    apiKey: "Id",
    editTitle: t("registerProductModel"),
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 1,
      validationSummary: true,
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 5,
          },
        },
        editCellComponent: (cell) => {
          const onChange = (value) => {
            cell.setValue(value);
          };
          const handleKeyDown = (e) => {
            if (e.key === "Tab") {
              fetcher(getApiUrl(`/api/ProductRegisterModel/AutoGenerateCode`))
                .then((res) => res.json())
                .then((data) => {
                  var el = document.getElementById("productRegisterCodeInput");
                  doInputEvent(el, data);
                });
            }
          };
          return (
            <Input
              defaultValue={cell.value}
              maxLength={5}
              minLength={16}
              id="productRegisterCodeInput"
              onKeyDown={handleKeyDown}
              onChange={(e) => onChange(e.target.value)}
            />
          );
        },
      },
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "Version",
        caption: t("version"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "VersionDesciption",
        caption: t("versionDescription"),
      },
      {
        dataField: "ProductId",
        caption: t("product"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        relationContent: {
          controller: "Product",
          display: "Value",
        },
      },
      {
        dataField: "ImportFileTypeId",
        caption: t("importFileType"),
        dataType: "number",
        relationContent: {
          controller: "ImportFileType",
          display: "Name",
        },
      },
      {
        dataField: "ProductCommunicationTypeId",
        caption: t("productContactType"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        relationContent: {
          controller: "ProductCommunicationType",
          display: "Name",
        },
      },
    ],
  };

  const productRegisterTagGrid = {
    apiController: "ProductRegisterTagModel",
    apiKey: "Id",
    editTitle: t("productRegisterModelDetail"),
    onInitNewRow: (options) => {
      options.data.readWrite = 0;
      options.data.blockSize = 1;
      options.data.intervalTime = 1;
      options.data.factor = 1;
      options.data.isSunrise = false;
    },
    subGridFeature: {
      loadUrl: "GetList",
      relationKey: "ProductRegisterModelId",
      insertUrl: "Insert",
      updateUrl: "Update",
      removeUrl: "Delete",
    },
    columns: [
      {
        dataField: "Code",
        caption: t("tagCode"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "Description",
        caption: t("description"),
      },
      {
        dataField: "DataType",
        caption: t("dataType"),
        fieldValidation: {
          isRequired: true,
        },
        relationContent: {
          controller: "CommunicationDataType",
          display: "Reference",
          key: "Reference",
          customParam: "productRegisterModelId",
        },
      },
      {
        dataField: "Address",
        caption: t("address"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "BlockSize",
        caption: t("blockSize"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "ReadWrite",
        caption: t("readWrite"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        localRelationContent: {
          dataSource: [
            { key: 0, value: t("read") },
            { key: 1, value: t("write") },
          ],
          value: "key",
          display: "value",
        },
      },
      {
        dataField: "IntervalTime",
        caption: t("intervalTime"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "RoundValue",
        caption: t("roundingValue"),
        dataType: "number",
      },
      {
        dataField: "Factor",
        caption: t("factor"),
        dataType: "number",
        editorType: "dxNumberBox",
        editorOptions: {
          format: "#,##0.0000000000",
          step: 0.0000000001,
          showSpinButtons: true,
        },
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "ProductId",
        caption: t("product"),
        dataType: "number",
        relationContent: {
          controller: "Product",
          display: "Value",
        },
      },
      {
        dataField: "CalcUnit",
        caption: t("calcUnit"),
        dataType: "number",
        relationContent: {
          controller: "Unit",
          display: "Name",
        },
      },
      {
        dataField: "TagCustomTypeId",
        caption: t("tagType"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        relationContent: {
          controller: "TagCustomType",
          display: "Value",
        },
      },
      {
        dataField: "TagGroupId",
        caption: t("tagGroup"),
        dataType: "number",
        relationContent: {
          controller: "TagGroup",
          display: "Name",
        },
      },
      {
        dataField: "UnitId",
        caption: t("calculatedUnit"),
        dataType: "number",
        relationContent: {
          controller: "Unit",
          display: "Name",
        },
      },
      {
        dataField: "IsSunrise",
        caption: t("sunrise"),
        dataType: "boolean",
      },
      {
        dataField: "MinValue",
        caption: t("minValue"),
        dataType: "number",
      },
      {
        dataField: "MaxValue",
        caption: t("maxValue"),
        dataType: "number",
      },
    ],
  };

  let gridData = CreateGridData(pageGrid);
  const productRegisterTagGridData = CreateGridData(productRegisterTagGrid);

  gridData.subGrids = [
    {
      tabCaption: t("productRegisterModel"),
      tabGridFeature: productRegisterTagGridData,
    },
  ];

  return <RenderMasterGrid gridData={gridData} />;
};

export default RegisterTagModelAddGrid;

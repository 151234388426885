import React from "react";
import crpLogoWhite from "../../assets/img/corporate/crp-logo-white.png";
import { Tooltip, Progress, DatePicker } from "antd";
import Chart, {
  Legend,
  SeriesTemplate,
  Size,
  CommonSeriesSettings,
  Export,
} from "devextreme-react/chart";

export const dataSource = [
  {
    year: 1970,
    country: "Saudi Arabia",
    oil: -192.2,
  },
  {
    year: 1970,
    country: "USA",
    oil: -533.5,
  },
  {
    year: 1970,
    country: "Iran",
    oil: -192.6,
  },
  {
    year: 1970,
    country: "Mexico",
    oil: -24.2,
  },
  {
    year: 1980,
    country: "Saudi Arabia",
    oil: -509.8,
  },
  {
    year: 1980,
    country: "USA",
    oil: -480.2,
  },
  {
    year: 1980,
    country: "Iran",
    oil: -74.3,
  },
  {
    year: 1980,
    country: "Mexico",
    oil: -107.2,
  },
  {
    year: 1990,
    country: "Saudi Arabia",
    oil: -342.6,
  },
  {
    year: 1990,
    country: "USA",
    oil: 416.6,
  },
  {
    year: 1990,
    country: "Iran",
    oil: 162.8,
  },
  {
    year: 1990,
    country: "Mexico",
    oil: 146.3,
  },
  {
    year: 1990,
    country: "Russia",
    oil: 515.9,
  },
  {
    year: 2000,
    country: "Saudi Arabia",
    oil: 456.3,
  },
  {
    year: 2000,
    country: "USA",
    oil: 352.6,
  },
  {
    year: 2000,
    country: "Iran",
    oil: 191.3,
  },
  {
    year: 2000,
    country: "Mexico",
    oil: 171.2,
  },
  {
    year: 2000,
    country: "Russia",
    oil: 323.3,
  },
  {
    year: 2008,
    country: "Saudi Arabia",
    oil: 515.3,
  },
  {
    year: 2008,
    country: "USA",
    oil: 304.9,
  },
  {
    year: 2008,
    country: "Iran",
    oil: 209.9,
  },
  {
    year: 2008,
    country: "Mexico",
    oil: 157.7,
  },
  {
    year: 2008,
    country: "Russia",
    oil: 488.5,
  },
  {
    year: 2009,
    country: "Saudi Arabia",
    oil: -459.5,
  },
  {
    year: 2009,
    country: "USA",
    oil: -325.3,
  },
  {
    year: 2009,
    country: "Iran",
    oil: -202.4,
  },
  {
    year: 2009,
    country: "Mexico",
    oil: -147.5,
  },
  {
    year: 2009,
    country: "Russia",
    oil: -494.2,
  },
];

const UserTracingPage = () => {
  return (
    <div className="c-main">
      <header className="u-header">
        <a className="u-header__logo" href="/Home" rel="noreferrer">
          <img src={crpLogoWhite} />
        </a>
        <div className="u-header__menu">
          <a>Durum</a>
          <a>Enerji</a>
          <a>Diziler</a>
          <a>İletişim</a>
        </div>
      </header>
      <main className="u-main">
        <div className="u-status">
          <div className="u-status__info">
            <strong>Son Durum</strong>
            <small>
              Son güncelleme 1 saat önce
              <i className="fas fa-redo-alt"></i>
            </small>
            <p>
              <i className="fas fa-check-circle"></i>
              <span>Sistem Normal</span>
            </p>
          </div>
          <div className="u-status__weather">
            <span>29°C</span>
          </div>
        </div>
        <div className="u-summary">
          <div className="u-summary-item">
            <div className="u-summary-item-info">
              <i className="fas fa-bolt"></i>
              <strong>
                2.03 <small>kw</small>
              </strong>
            </div>
            <span className="u-summary-item-info-text">En Son Güç</span>
          </div>
          <div className="u-summary-item">
            <div className="u-summary-item-info">
              <i className="fas fa-bolt"></i>
              <strong>
                2.2 <small>kw</small>
              </strong>
            </div>
            <span className="u-summary-item-info-text">Maksimum Güç</span>
          </div>
          <div className="u-summary-item">
            <div className="u-summary-item-info">
              <i className="fas fa-car-battery"></i>
              <strong>
                60 <small>%</small>
              </strong>
            </div>
            <span className="u-summary-item-info-text">Depolama</span>
          </div>
        </div>
        <div className="u-view">
          <div className="u-view__container">
            <div className="u-view__info">
              <div className="u-view__info-item">
                <div className="u-view__info-item-icon">
                  <i className="fas fa-broadcast-tower"></i>
                </div>
                <strong>
                  6,9 <small>kwh</small>
                </strong>
                <span>Gönderilen</span>
              </div>
              <div className="u-view__info-item blue">
                <div className="u-view__info-item-icon">
                  <i className="fas fa-grip-horizontal"></i>
                </div>
                <strong>
                  19,7 <small>kwh</small>
                </strong>
                <span>Üretilen</span>
              </div>
              <div className="u-view__info-item green">
                <div className="u-view__info-item-icon">
                  <i className="fas fa-car-battery"></i>
                </div>
                <strong>
                  6,9 <small>kwh</small>
                </strong>
                <span>Deşarj</span>
              </div>
            </div>
            <div className="u-view__info-effect">
              <div className="u-view__info-effect-left"></div>
              <div className="u-view__info-effect-center"></div>
              <div className="u-view__info-effect-right"></div>
            </div>
            <div className="u-view__info-center">
              <Tooltip title="3 done / 3 in progress / 4 to do">
                <Progress
                  width={200}
                  percent={60}
                  success={{ percent: 30 }}
                  format={() => (
                    <div className="u-view__info-center-progress">
                      <i className="fas fa-home"></i>
                      <strong>
                        18,29 <small>kwh</small>
                      </strong>
                      <small>Tüketilen</small>
                    </div>
                  )}
                  trailColor="#8f97a8"
                  type="circle"
                />
              </Tooltip>
            </div>
            <div className="u-view__info-effect u-view__info-effect-rotate">
              <div className="u-view__info-effect-right"></div>
              <div className="u-view__info-effect-left"></div>
            </div>
            <div className="u-view__info">
              <div className="u-view__info-item">
                <div className="u-view__info-item-icon">
                  <i className="fas fa-broadcast-tower"></i>
                </div>
                <strong>
                  6,9 <small>kwh</small>
                </strong>
                <span>Aktarılan</span>
              </div>
              <div className="u-view__info-item green">
                <div className="u-view__info-item-icon">
                  <i className="fas fa-car-battery"></i>
                </div>
                <strong>
                  6,9 <small>kwh</small>
                </strong>
                <span>Şarj</span>
              </div>
            </div>
          </div>
        </div>
        <div className="u-date">
          <ul className="u-date__select">
            <li className="u-date__select-item u-date__select-item--active">
              <a>GÜNLÜK</a>
            </li>
            <li className="u-date__select-item">
              <a>HAFTALIK</a>
            </li>
            <li className="u-date__select-item">
              <a>AYLIK</a>
            </li>
            <li className="u-date__select-item">
              <a>YILLIK</a>
            </li>
            <li className="u-date__select-item">
              <a>TÜMÜ</a>
            </li>
          </ul>
          <div className="u-date__calendar">
            <DatePicker
              className="u-date__calendar-datepicker"
              bordered={false}
            />
            <span className="u-date__calendar-weather">29°C</span>
          </div>
        </div>
        <div className="u-energy">
          <span>Son güncelleme 3 saat önce yapıldı</span>
          <div className="u-energy__status">
            <div className="u-energy__status-info">
              <div className="u-energy__status-info-item blue">
                <div className="u-energy__status-info-item-icon">
                  <i className="fas fa-grip-horizontal"></i>
                </div>
                <div className="u-energy__status-info-item-text">
                  <strong>
                    19,7 <small>kwh</small>
                  </strong>
                  <span>Üretilen</span>
                </div>
              </div>
              <div className="u-energy__status-info-item">
                <div className="u-energy__status-info-item-icon">
                  <i className="fas fa-broadcast-tower"></i>
                </div>
                <div className="u-energy__status-info-item-text">
                  <strong>
                    6,9 <small>kwh</small>
                  </strong>
                  <span>Gönderilen</span>
                </div>
              </div>
              <div className="u-energy__status-info-item green">
                <div className="u-energy__status-info-item-icon">
                  <i className="fas fa-car-battery"></i>
                </div>
                <div className="u-energy__status-info-item-text">
                  <strong>
                    6,9 <small>kwh</small>
                  </strong>
                  <span>Deşarj</span>
                </div>
              </div>
            </div>
            <div className="u-energy__status-info-effect "></div>
            <Tooltip>
              <Progress
                width={200}
                percent={60}
                success={{ percent: 30 }}
                format={() => (
                  <div className="u-energy__status-progress">
                    <i className="fas fa-home"></i>
                    <strong>
                      18,29 <small>kwh</small>
                    </strong>
                    <small>Tüketilen</small>
                  </div>
                )}
                trailColor="#8f97a8"
                type="circle"
              />
            </Tooltip>
            <div className="u-energy__status-info-effect"></div>
            <div className="u-energy__status-info">
              <div className="u-energy__status-info-item blue">
                <div className="u-energy__status-info-item-icon">
                  <i className="fas fa-grip-horizontal"></i>
                </div>
                <div className="u-energy__status-info-item-text">
                  <strong>
                    0,85 <small>kwh</small>
                  </strong>
                  <span>Net Aktarılan</span>
                </div>
              </div>
              <div className="u-energy__status-info-item">
                <div className="u-energy__status-info-item-icon">
                  <i className="fas fa-broadcast-tower"></i>
                </div>
                <div className="u-energy__status-info-item-text">
                  <strong>
                    6,9 <small>kwh</small>
                  </strong>
                  <span>Aktarılan</span>
                </div>
              </div>
              <div className="u-energy__status-info-item green">
                <div className="u-energy__status-info-item-icon">
                  <i className="fas fa-car-battery"></i>
                </div>
                <div className="u-energy__status-info-item-text">
                  <strong>
                    6,9 <small>kwh</small>
                  </strong>
                  <span>Şarj</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="u-graphic">
          <Chart
            id="chart"
            palette="Violet"
            className="full-width"
            dataSource={dataSource}
          >
            <SeriesTemplate
              nameField="year"
              customizeSeries={customizeSeries}
            />
            <CommonSeriesSettings
              argumentField="country"
              valueField="oil"
              type="bar"
            />
            <Legend verticalAlignment="bottom" horizontalAlignment="center" />
            <Export enabled={true} />
          </Chart>
        </div>
      </main>
    </div>
  );
};

function customizeSeries(valueFromNameField) {
  return valueFromNameField === 2009
    ? { type: "line", label: { visible: true }, color: "#ff3f7a" }
    : {};
}

export default UserTracingPage;

import axios from "axios";
import { getApiUrl } from "./../../../config/applicationConfig";
import { GET_DASHBOARD_LAYOUTS } from "./../actionTypes";
import messageError from "./../../../helper/messages/errorMessage";

export const InsertDashboardWidgetLayout = (setData, dispatch) => {
  delete setData.visible;
  axios
    .post(getApiUrl("/api/DashboardWidgetLayout/Insert"), setData)
    .then((res) => res.data)
    .then((data) => dispatch(GetDashboardWidgetLayout()))
    .catch((err) => {
      console.log(err);
    });
};

export const UpdateDashboardWidgetLayout = (values, dispatch) => {
  var formData = new FormData();
  formData.append("values", JSON.stringify(values));

  axios
    .post(getApiUrl("/api/DashboardWidgetLayout/Update"), formData)
    .then((res) => res.data)
    .then((data) => dispatch(GetDashboardWidgetLayout()))
    .catch((err) => {
      console.log(err);
    });
};

export const DeleteDashboardWidgetLayout = (key, dispatch) => {
  var formData = new FormData();
  formData.append("key", key);

  axios
    .delete(getApiUrl("/api/DashboardWidgetLayout/Delete"), {
      data: formData,
    })
    .then((res) => res.data)
    .then((data) => dispatch(GetDashboardWidgetLayout()))
    .catch((err) => {
      console.log(err);
    });
};

export const GetDashboardWidgetLayout = () => {
  return async (dispatch) => {
    axios
      .get(getApiUrl("/api/DashboardWidgetLayout/GetList"))
      .then((res) => res.data)
      .then((data) => {
        dispatch({ type: GET_DASHBOARD_LAYOUTS, payload: data.data });
      })
      .catch((err) => {
        messageError({ text: err.message });
      });
  };
};

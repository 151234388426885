import React from "react";
import TagGroupGrid from "./TagGroupGrid";

const TagGroupPage = () => {
  return (
    <div className="c-main">
      <div className="c-content">
        <TagGroupGrid />
      </div>
    </div>
  );
};
export default TagGroupPage;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Modal as AntModal } from "antd";
import React, { useEffect, useState } from "react";

const Modal = (props) => {
  const [visible, setVisible] = useState(props.visible ?? false);

  const showModal = async () => {
    if (props.showModal) {
      props.showModal();
    }
    setVisible(true);
  };

  const handleOk = (props) => {
    props.blur();
    if (props.onClick) {
      props.onClick();
    }
    setVisible(false);
  };

  const handleCancel = () => {
    if (props.onClick) {
      props.onClick();
    }
    setVisible(false);
  };

  const customHandle = (onClick) => {
    if (onClick) {
      onClick();
    }
    if (props.onClick) {
      props.onClick();
    }
    setVisible(false);
  };

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  return (
    <>
      <a>
        <Button
          className={props.className}
          onClick={showModal}
          disabled={props.disabled ?? false}
        >
          {props.buttonText}
        </Button>
      </a>

      <AntModal
        getContainer="#root-content"
        title={props.title}
        visible={props.visible ?? visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: props.buttons }}
        cancelButtonProps={{ disabled: props.buttons }}
        width={props.width ?? 700}
        headStyle={{ backgroundColor: "red" }}
        footer={
          !props.hideFooter ? (
            props.buttons?.map((item, index) => (
              <Button
                key={index}
                onClick={() => customHandle(item.onClick)}
                className={item.className}
              >
                {item.buttonText}
              </Button>
            ))
          ) : (
            <></>
          )
        }
      >
        {props.content}
      </AntModal>
    </>
  );
};
export default Modal;

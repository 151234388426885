import React from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import AddNewWidget from "./Components/AddNewWidget";
import {
  DeleteDashboardWidgetLayout,
  GetDashboardWidgetLayout,
  UpdateDashboardWidgetLayout,
} from "../../redux/actions/CentralTracing/CentralTracingActions";
import { selectProps } from "./../../helper/JavascriptHelper";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { WidgetChartType } from "./../../helper/enums/WidgetChartType";
import LineChart from "./../../components/Shared/Charts/LineChart";
import { GetDashboardWidgetData } from "./../../redux/actions/DashboardWidget/DashboardWidgetActions";
import {
  DELETE_DASHBOARD_WIDGET_IDS,
  GET_DASHBOARD_WIDGET_IDS,
  SET_HEADER_FILTER,
} from "../../redux/actions/actionTypes";
import HeaderFilter from "./../../components/Shared/Header/HeaderFilter";
import { useTranslation } from "react-i18next";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const CentralTracing = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const centralTracingState = useSelector((p) => p.CentralTracingReducer);
  const dashboardWidgetState = useSelector((p) => p.DashboardWidgetReducer);
  const headerFilterState = useSelector((p) => p.HeaderFilterReducer);

  const defaultProps = {
    className: "c-widget__container layout",
    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    rowHeight: 100,
  };

  React.useEffect(() => {
    dispatch(GetDashboardWidgetLayout());
  }, []);
  const onRemoveItem = (i) => {
    DeleteDashboardWidgetLayout(i, dispatch);
  };
  const dateOnChange = (dates, dateStrings) => {
    dispatch({
      type: SET_HEADER_FILTER,
      payload: {
        startDate: dateStrings[0],
        endDate: dateStrings[1],
      },
    });
    updateDashboard();
  };
  const updateDashboard = () => {
    dispatch({ type: DELETE_DASHBOARD_WIDGET_IDS, payload: [] });
  };
  const createElement = (el) => {
    if (!dashboardWidgetState.widgetIds.includes(el.id)) {
      dispatch({ type: GET_DASHBOARD_WIDGET_IDS, payload: el.id });
      dispatch(
        GetDashboardWidgetData(
          el.id,
          headerFilterState.startDate,
          headerFilterState.endDate
        )
      );
    }
    return (
      <div key={el.id} data-grid={el} className="c-widget__item">
        <div className="c-widget__item-header ">
          <span className="text c-widget__item-header-title">{el.title}</span>
          <button
            className="remove c-widget__item-remove"
            onClick={() => onRemoveItem(el.id)}
          >
            <i className="fas fa-times"></i>
          </button>
        </div>
        <div className="c-widget__item-content">
          {el.widgetChartType === WidgetChartType.Area && (
            <LineChart
              dataSource={
                dashboardWidgetState.widgets.find((p) => p.id == el.id)?.data ??
                []
              }
              xField="createdAt"
              yField="calcValue"
              seriesField="tagCode"
            />
          )}
          {el.widgetChartType === WidgetChartType.Line && (
            <LineChart
              dataSource={
                dashboardWidgetState.widgets.find((p) => p.id == el.id)?.data ??
                []
              }
              xField="createdAt"
              yField="calcValue"
              seriesField="tagCode"
            />
          )}
        </div>
      </div>
    );
  };

  const onLayoutChange = (layout) => {
    let newLayouts = layout.map(selectProps("i", "h", "w", "x", "y"));
    UpdateDashboardWidgetLayout(newLayouts, dispatch);
  };

  return (
    <>
      <div className="c-page">
        <HeaderFilter
          pageName={t("homePage")}
          dateOnChange={dateOnChange}
          updateDashboard={updateDashboard}
        />
      </div>
      <div className="c-main c-main__widget">
        <div className="site-drawer-render-in-current-wrapper">
          <div className="c-widget">
            <ResponsiveReactGridLayout
              onLayoutChange={onLayoutChange}
              layouts={centralTracingState.layout}
              draggableHandle=".c-widget__item-header-title"
              {...defaultProps}
            >
              {_.map(centralTracingState.layout, (el) => createElement(el))}
            </ResponsiveReactGridLayout>
          </div>
          <AddNewWidget />
        </div>
      </div>
    </>
  );
};
export default CentralTracing;

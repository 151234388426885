import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { paths } from "./../../../helper/resources/paths";
import { fetcher } from "../../../config/fetchApiConfig";
import { getApiUrl } from "./../../../config/applicationConfig";
import { SET_DASHBOARD_WIDGETS } from "../../../redux/actions/actionTypes";
import axios from "axios";

const DashboardMasterDropdown = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const dashboardState = useSelector((p) => p.DashboardReducer);

  React.useEffect(() => {
    var token = localStorage.getItem("token");
    fetcher(getApiUrl(`/api/DashboardWidget/GetList`), { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => res.json())
      .then((data) => {
        dispatch({ type: SET_DASHBOARD_WIDGETS, payload: data.data });
      });
  }, []);

  const updateActiveDashboard = (widgetId) => {
    axios
      .post(getApiUrl("/api/DashboardWidget/UpdateActiveDashboard"), null, {
        params: { key: widgetId },
      })
      .then((res) => res.data)
      .then((res) => {
        window.location.reload();
      });
  };

  const dashboardMenu = (
    <Menu>
      {dashboardState.dashboardWidgets.map((item, index) => (
        <div key={index}>
          <Menu.Item
            key={item.code}
            onClick={() => updateActiveDashboard(item.id)}
          >
            {item.code}
          </Menu.Item>
          <Menu.Divider />
        </div>
      ))}
      <Menu.Item
        key="2"
        onClick={() => history.push(paths.definations.dashboard)}
      >
        Dashboard Ekle
      </Menu.Item>
      <Menu.Divider />
    </Menu>
  );

  return (
    <>
      <Dropdown
        overlay={dashboardMenu}
        trigger={["click"]}
        className="c-header__menu-item"
      >
        <a
          className="ant-dropdown-link c-header__menu-item-drop"
          href="#"
          onClick={(e) => e.preventDefault()}
        >
          <span className="c-header__menu-item-drop-name">
            {dashboardState.dashboardWidgets.find((p) => p.isActive)?.code}
          </span>
          <DownOutlined />
        </a>
      </Dropdown>
    </>
  );
};
export default DashboardMasterDropdown;

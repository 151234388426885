import RegisterTagModelAddPage from "../../../views/RegisterModelAdd/RegisterTagModelAdd/RegisterTagModelAddPage";
import { MenuType } from "../../enums/MenuType";
import { paths } from "../../resources/paths";

export const RegisterModelItems = (t) => [
  {
    key: "1",
    icon: "fas fa-map-marker-plus",
    text: t("registerModelTagAdd"),
    path: paths.registerModelAdded.registerModelTagAdd,
    render: <RegisterTagModelAddPage />,
    MenuType: MenuType.MAINMENU,
  },
];

import React from "react";
import CompanyManagementGrid from "./CompanyManagementGrid";

const CompanyManagementPage = () => {
  return (
    <div className="c-main">
      <div className="c-content">
        <CompanyManagementGrid />
      </div>
    </div>
  );
};
export default CompanyManagementPage;

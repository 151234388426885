import React from "react";
import { DatePicker, Button, Tooltip } from "antd";
import "moment/locale/tr";
import dateLang from "antd/es/date-picker/locale/en_US";
import moment from "moment";
import { ReloadOutlined } from "@ant-design/icons";
import { TagBox } from "devextreme-react";
import { getApiUrl } from "./../../../../config/applicationConfig";
import { fetcher } from "../../../../config/fetchApiConfig";
import gunBatimiDogumu from "../../../../assets/img/icon/gun-dogum-batım.png";
import gunDogumuBatimi from "../../../../assets/img/icon/gun-batım-dogum.png";
import kpiLogo from "../../../../assets/img/icon/KPI_Logo.png";
import { SunriseType } from "./../../../../helper/enums/SunriseType";
import { useRecoilState } from "recoil";
import {
  dateFilterState,
  openComponentState,
  refreshAction,
  selectedCategoryState,
  selectedProductState,
  sunriseTypeState,
} from "../recoil/RecoilActions";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

const HeaderFilterAnalysis = ({ pageName }) => {
  const { t } = useTranslation();
  const [categories, setCategories] = React.useState([]);
  const [products, setProducts] = React.useState([]);

  const [dateFilter, setDateFilter] = useRecoilState(dateFilterState);
  const [refresh, setRefresh] = useRecoilState(refreshAction);
  const [sunriseType, setSunriseType] = useRecoilState(sunriseTypeState);
  const [selectedCategory, setSelectedCategory] = useRecoilState(
    selectedCategoryState
  );
  const setSelectedProduct = useRecoilState(selectedProductState)[1];
  const setIsOpen = useRecoilState(openComponentState)[1];

  const dateOnChange = (dates, dateStrings) => {
    setDateFilter({
      startDate: dateStrings[0],
      endDate: dateStrings[1],
    });
  };

  const updateDashboard = () => {
    setRefresh(refresh + 1);
  };

  React.useEffect(() => {
    fetcher(getApiUrl(`/api/ProductCategory/GetListAnalysis`), {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        alarmSbomTreeviews: [],
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setCategories(res);
      });
  }, []);

  React.useEffect(() => {
    fetcher(getApiUrl(`/api/Product/GetListByCategories`), {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        categoryIds: selectedCategory.map((p) => p.id),
        alarmSbomTreeviews: [],
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setProducts(res);
      });
  }, [selectedCategory]);

  const sunriseButtonClick = (tempSunriseType) => {
    if (sunriseType == tempSunriseType) {
      setSunriseType(SunriseType.All);
    } else {
      setSunriseType(tempSunriseType);
    }
  };

  return (
    <>
      <div className="c-page__header">
        <strong className="c-page__header-title">{pageName}</strong>
        <div className="c-page__header-filter">
          <div className="c-page__header-filter-item">
            <TagBox
              className="c-country__drop"
              items={categories}
              displayExpr="name"
              searchEnabled={true}
              placeholder={t("category")}
              multiline={false}
              showSelectionControls={true}
              width="250px"
              showClearButton={false}
              onClosed={(e) => setIsOpen(false)}
              onOpened={(e) => setIsOpen(true)}
              onOptionChanged={(e) => {
                if (e.name == "selectedItems") {
                  setSelectedCategory(e.value);
                }
              }}
            />
            <TagBox
              className="c-country__drop"
              items={products}
              displayExpr="name"
              searchEnabled={true}
              placeholder={t("product")}
              multiline={false}
              showSelectionControls={true}
              width="250px"
              onClosed={(e) => setIsOpen(false)}
              onOpened={(e) => setIsOpen(true)}
              onOptionChanged={(e) => {
                if (e.name == "selectedItems") {
                  setSelectedProduct(e.value);
                }
              }}
            />
          </div>
          <div className="c-page__header-filter-item">
            <RangePicker
              locale={dateLang}
              onChange={dateOnChange}
              onOpenChange={(e) => setIsOpen(e)}
              format="DD/MM/YYYY"
              defaultValue={[
                moment(dateFilter.startDate, "DD/MM/YYYY"),
                moment(dateFilter.endDate, "DD/MM/YYYY"),
              ]}
              bordered={false}
            />
          </div>
          <div className="c-page__header-filter-item">
            <Button
              className="c-page__header-filter-date-button"
              size="small"
              onClick={updateDashboard}
            >
              <ReloadOutlined />
            </Button>
          </div>
          <div className="c-page__header-filter-item">
            <Tooltip
              placement="bottom"
              color="#32d2c9"
              title={t("betweenSunriseSunset")}
            >
              <Button
                className={
                  "c-page__header-filter-date-button " +
                  (sunriseType == SunriseType.Sunrise
                    ? "c-page__header-filter-date-button--active"
                    : "")
                }
                size="small"
                onClick={() => sunriseButtonClick(SunriseType.Sunrise)}
              >
                <img className="c-day__sunset" src={gunBatimiDogumu} />
              </Button>
            </Tooltip>
            <Tooltip
              placement="bottom"
              color="#32d2c9"
              title={t("betweenSunriseSunset")}
            >
              <Button
                className={
                  "c-page__header-filter-date-button " +
                  (sunriseType == SunriseType.Sunset
                    ? "c-page__header-filter-date-button--active"
                    : "")
                }
                size="small"
                onClick={() => sunriseButtonClick(SunriseType.Sunset)}
              >
                <img className="c-day__sunrise" src={gunDogumuBatimi} />
              </Button>
            </Tooltip>
          </div>
          <div className="c-page__header-filter-item">
            <span className="c-page__header-filter-date-name">
              {t("period")}
            </span>
            <Tooltip
              placement="bottom"
              color="#32d2c9"
              title={t("facilitySpecificPeriodVal")}
            >
              <Button
                className="c-page__header-filter-date-button"
                size="small"
              >
                {t("ö")}
              </Button>
            </Tooltip>
            <Tooltip
              placement="bottom"
              color="#32d2c9"
              title={t("dailyValues")}
            >
              <Button
                className="c-page__header-filter-date-button"
                size="small"
              >
                {t("g")}
              </Button>
            </Tooltip>
            <Tooltip
              placement="bottom"
              color="#32d2c9"
              title={t("monthlyValues")}
            >
              <Button
                className="c-page__header-filter-date-button"
                size="small"
              >
                {t("a")}
              </Button>
            </Tooltip>
            <Tooltip
              placement="bottom"
              color="#32d2c9"
              title={t("yearlyValues")}
            >
              <Button
                className="c-page__header-filter-date-button"
                size="small"
              >
                {t("y")}
              </Button>
            </Tooltip>
          </div>
          <div className="c-page__header-filter-item">
            <Tooltip placement="bottom" color="#32d2c9" title="KPI">
              <Button
                className="c-page__header-filter-date-button"
                size="small"
              >
                <img className="c-day__sunset" src={kpiLogo} />
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
};
export default HeaderFilterAnalysis;

import React from "react";
import { Button, Modal } from "antd";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../helper/Grid/GridCreatorMixin";
import { useTranslation } from "react-i18next";

const AccountDetailTypeDefination = ({ setValue, value }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = React.useState(false);
  const _onSelectionChanged = (data) => {
    setValue(data.selectedRowKeys[0]);
  };
  const pageGrid = {
    apiController: "AccountDetailType",
    apiKey: "Id",
    editTitle: t("detailTypeInfo"),
    exportPanel: false,
    refreshItem: false,
    groupPanel: false,
    searchPanel: false,
    onSelectionChanged: _onSelectionChanged,
    selection: true,
    selectionMode: "single",
    defaultSelectedRowKeys: [value],
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 1,
      validationSummary: true,
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
    ],
  };

  return (
    <div>
      <Button onClick={() => setVisible(true)}>
        {t("detailType")} {value ? t("update") : t("defination")}
      </Button>
      <Modal
        title={t("detailType")`${value ? t("update") : t("defination")}`}
        visible={visible}
        onCancel={() => setVisible(false)}
        cancelText={t("close")}
        okButtonProps={{ hidden: true }}
        width={800}
      >
        <RenderMasterGrid gridData={CreateGridData(pageGrid)} />
      </Modal>
    </div>
  );
};
export default AccountDetailTypeDefination;

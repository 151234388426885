import { GET_DASHBOARD_LAYOUTS } from "./../../actions/actionTypes";

const centralTracingState = {
  layout: [],
};

export const CentralTracingReducer = (state = centralTracingState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_LAYOUTS:
      return { ...state, layout: action.payload };
    default:
      return state;
  }
};

import React from "react";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../helper/Grid/GridCreatorMixin";
import { useTranslation } from "react-i18next";

const DataConnectionReportGrid = () => {
  const { t } = useTranslation();
  const pageGrid = {
    apiController: "DataCounter",
    apiKey: "Id",
    editTitle: "",
    selection: false,
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: false,
      allowDeleting: false,
      allowUpdating: false,
      allowCopy: false,
      useIcons: false,
      containerColCount: 1,
      validationSummary: true,
    },
    columns: [
      {
        dataField: "DataMb",
        caption: t("dataMb"),
        dataType: "number",
      },
      {
        dataField: "Date",
        caption: t("date"),
        dataType: "date",
        format: "dd/MM/yyyy HH:mm",
      },
      {
        dataField: "CentralName",
        caption: t("centralName"),
      },
      {
        dataField: "ProductName",
        caption: t("deviceName"),
      },
    ],
  };

  let gridData = CreateGridData(pageGrid);

  return <RenderMasterGrid gridData={gridData} />;
};
export default DataConnectionReportGrid;

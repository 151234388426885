import { MenuType } from "../../enums/MenuType";
import { paths } from "../../resources/paths";
import RecipeRangePage from "./../../../views/Recipe/RecipeRange/RecipeRangePage";

export const RecipeItems = (t) => [
  {
    key: "1",
    icon: "fas fa-map-marker-plus",
    text: t("recipeRange"),
    path: paths.recipes.recipeRange,
    render: <RecipeRangePage />,
    MenuType: MenuType.MAINMENU,
  },
];

import React from "react";
import { useTranslation } from "react-i18next";

const ReportViewPage = () => {
  const { t } = useTranslation();
  return (
    <div className="c-main">
      <div className="c-content">{t("reportView")}</div>
    </div>
  );
};
export default ReportViewPage;

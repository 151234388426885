import { MenuType } from "../../enums/MenuType";
import { paths } from "../../resources/paths";
import AlarmViewPage from "../../../views/Alarms/AlarmView/AlarmViewPage";
import AlarmIdentificationPage from "../../../views/Alarms/AlarmIdentificationPage";

export const AlarmItems = (t) => [
  {
    key: "1",
    icon: "fad fa-bells",
    text: t("alarmView"),
    path: paths.alarms.alarmView,
    render: <AlarmViewPage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "2",
    icon: "fas fa-bell-plus",
    text: t("alarmIdentification"),
    path: paths.alarms.alarmIdentification,
    render: <AlarmIdentificationPage />,
    MenuType: MenuType.MAINMENU,
  },
];

import React from "react";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../../../helper/Grid/GridCreatorMixin";
import { useParams } from "react-router-dom";
import { MoveTypeSource } from "../../../../../helper/enums/EnumDataSource";
import { useTranslation } from "react-i18next";

const SbomProductMovementGrid = () => {
  const { t } = useTranslation();
  var { sbomId = 0 } = useParams();

  const pageGrid = {
    apiController: "SbomProductMovement",
    apiKey: "Id",
    editTitle: "Devir Kayıt Bilgisi",
    loadParams: { sbomId: sbomId },
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 1,
      validationSummary: true,
    },
    columns: [
      {
        dataField: "LastIndex",
        caption: "Son Index",
        dataType: "number",
      },
      {
        dataField: "StartIndex",
        caption: t("startIndex"),
        dataType: "number",
      },
      {
        dataField: "Description",
        caption: t("description"),
      },
      {
        dataField: "Description2",
        caption: t("description2"),
      },
      {
        dataField: "CycleDate",
        caption: t("cycleDate"),
        editorType: "dxDateBox",
      },
      {
        dataField: "MovementDate",
        caption: t("movementDate"),
        editorType: "dxDateBox",
      },
      {
        dataField: "MoveType",
        caption: t("moveType"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        localRelationContent: {
          dataSource: MoveTypeSource,
        },
      },
    ],
  };

  let gridData = CreateGridData(pageGrid);

  return <RenderMasterGrid gridData={gridData} />;
};
export default SbomProductMovementGrid;

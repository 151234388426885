import React from "react";
import { SelectBox } from "devextreme-react";
import { useEffect } from "react";
import { setSubGridApiDefault } from "../../../../config/fetchApiConfig";
import { useDispatch, useSelector } from "react-redux";
import { SET_ALARMCONNECTION_EDIT } from "./../../../../redux/actions/actionTypes";
import { useTranslation } from "react-i18next";

const AccountSelectBox = ({ setValue, value }) => {
  const { t } = useTranslation();
  const [customValue, setCustomValue] = React.useState(null);
  const dispatch = useDispatch();
  const alarmConnectiontate = useSelector((p) => p.AlarmConnectionReducer);

  useEffect(() => {
    if (value) {
      dispatch({
        type: SET_ALARMCONNECTION_EDIT,
        payload: { dataField: "selectedAccountId", value: value },
      });
      setCustomValue(value);
    }
  }, [value]);

  return (
    <SelectBox
      dataSource={setSubGridApiDefault(
        "Account",
        "id",
        "GetRelationList",
        null,
        null,
        null
      )}
      displayExpr="value"
      onValueChanged={({ value }) => {
        setValue && setValue(value.id);
        setCustomValue(value.id);
        dispatch({
          type: SET_ALARMCONNECTION_EDIT,
          payload: { dataField: "selectedSbomId", value: null },
        });
        dispatch({
          type: SET_ALARMCONNECTION_EDIT,
          payload: { dataField: "selectedCentralId", value: null },
        });
        dispatch({
          type: SET_ALARMCONNECTION_EDIT,
          payload: { dataField: "selectedAccountFieldId", value: null },
        });
        dispatch({
          type: SET_ALARMCONNECTION_EDIT,
          payload: { dataField: "selectedAccountId", value: value.id },
        });
      }}
      searchEnabled={true}
      value={customValue ?? alarmConnectiontate.selectedAccountId}
      placeholder={t("selectCompany")}
    />
  );
};
export default AccountSelectBox;

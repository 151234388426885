import React from "react";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../helper/Grid/GridCreatorMixin";
import { SelectBox } from "devextreme-react";
import { fetcher, setSubGridApiDefault } from "../../../config/fetchApiConfig";
import DataSource from "devextreme/data/data_source";
import { getApiUrl } from "./../../../config/applicationConfig";
import { doInputEvent } from "./../../../helper/JavascriptHelper";
import { Input } from "antd";
import { useTranslation } from "react-i18next";

const ProductManagamentGrid = () => {
  const { t } = useTranslation();
  const modelDataSource = new DataSource({
    store: setSubGridApiDefault("Model", "id", "GetRelationList"),
    group: "brandName",
  });

  const pageGrid = {
    apiController: "Product",
    apiKey: "Id",
    editTitle: t("productInfo"),
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 1,
      validationSummary: true,
    },
    onInitNewRow: (options) => {
      options.data.isActive = true;
    },
    columns: [
      {
        dataField: "Code",
        caption: t("productCode"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 5,
          },
        },
        editCellComponent: (cell) => {
          const onChange = (value) => {
            cell.setValue(value);
          };
          const handleKeyDown = (e) => {
            if (e.key === "Tab") {
              fetcher(getApiUrl(`/api/Product/AutoGenerateCode`))
                .then((res) => res.json())
                .then((data) => {
                  var el = document.getElementById("productCodeInput");
                  doInputEvent(el, data);
                });
            }
          };
          return (
            <Input
              defaultValue={cell.value}
              maxLength={5}
              minLength={16}
              id="productCodeInput"
              onKeyDown={handleKeyDown}
              onChange={(e) => onChange(e.target.value)}
            />
          );
        },
      },
      {
        dataField: "Name",
        caption: t("productName"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "SerialNumber",
        caption: t("serialNumber"),
      },
      {
        dataField: "ProductionDate",
        caption: t("productionDate"),
        dataType: "date",
        format: "dd/MM/yyyy",
      },
      {
        dataField: "ModelId",
        caption: t("model"),
        dataType: "number",
        relationContent: {
          controller: "Model",
          display: "Value",
          loadUrl: "GetList",
        },
        editCellComponent: (cell) => {
          return (
            <SelectBox
              dataSource={modelDataSource}
              valueExpr="id"
              grouped={true}
              onValueChanged={({ value }) => cell.setValue(value)}
              searchEnabled={true}
              defaultValue={cell.value}
              displayExpr="name"
              showClearButton={true}
            />
          );
        },
      },
      {
        dataField: "ProductTypeId",
        caption: t("type"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        relationContent: {
          controller: "ProductType",
          display: "Name",
        },
      },
      {
        dataField: "ProductCategoryId",
        caption: t("category"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        relationContent: {
          controller: "ProductCategory",
          display: "Name",
        },
      },
      {
        dataField: "ProductUnitId",
        caption: t("unit"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        relationContent: {
          controller: "ProductUnit",
          display: "Name",
        },
      },
    ],
  };

  let gridData = CreateGridData(pageGrid);

  return <RenderMasterGrid gridData={gridData} />;
};
export default ProductManagamentGrid;

import React from "react";
import SbomProductDetailGrid from "./SbomProductDetailGrid";
import { Switch, Route } from "react-router-dom";
import { paths } from "./../../../../../helper/resources/paths";

const SbomProductDetailPage = () => {
  return (
    <Switch>
      <Route
        exact
        path={paths.managament.productTree.sbomProductDetailPage}
        component={SbomProductDetailGrid}
      />
    </Switch>
  );
};
export default SbomProductDetailPage;

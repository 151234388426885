import { Button, Form, Input } from "antd";
import "antd/dist/antd.css";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import { Translation } from "../../../helper/LanguageSupport/TranslationHelper";
import { Login } from "../../../redux/actions/Shared/AuthActions";
import Logo from "../../../assets/img/corporate/crp-logo-white-dark.png";
import { useTranslation } from "react-i18next";

const LoginPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const cookies = new Cookies();

  const onFinish = (values) => {
    dispatch(
      Login(values.username, values.password,values.accountCode, values.rememberMe, history)
    );
  };

  return (
    <div className="p-login">
      <div className="p-login__center">
        <div className="p-login__logo">
          <img src={Logo} />
        </div>
        <Form
          name="basic"
          initialValues={{
            rememberMe: true,
            username: cookies.get("solarPanelUserName"),
            password: cookies.get("solarPanelPass"),
            accountCode: cookies.get("solarPanelAccountCode"),
          }}
          onFinish={onFinish}
          className="p-login__form"
        >
          <Form.Item
            name="accountCode"
            className="p-login__form-item p-login__form-item-name"
          >
            <Input
              placeholder={Translation("customerCode")}
              prefix={<i className="fal fa-user"></i>}
              bordered={false}
              className="p-login__form-item-input"
            />
          </Form.Item>
          <Form.Item
            name="username"
            rules={[
              { required: true, message: Translation("alertCustomerName") },
            ]}
            className="p-login__form-item p-login__form-item-name"
          >
            <Input
              placeholder={Translation("userName")}
              prefix={<i className="fal fa-user"></i>}
              bordered={false}
              className="p-login__form-item-input"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: Translation("alertPassword") }]}
            className="p-login__form-item p-login__form-item-password"
          >
            <Input.Password
              placeholder={Translation("password")}
              prefix={<i className="fal fa-lock-alt"></i>}
              bordered={false}
              className="p-login__form-item-input"
            />
          </Form.Item>
          {/* <Form.Item className="p-login__form-remember">
          <Form.Item name="rememberMe" valuePropName="checked">
            <Checkbox>{Translation("rememberMe")}</Checkbox>
          </Form.Item>
        </Form.Item> */}
          <Form.Item className="p-login__form-item">
            <Button
              type="primary"
              htmlType="submit"
              block
              className="p-login__form-item-button"
            >
              {t("login")}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <h5 className="p-login__footer">
        {t("sendBoxLink")} {Translation("solarPanel")} {t("allRightsReserved")}
      </h5>
    </div>
  );
};
export default LoginPage;

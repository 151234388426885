import React from "react";
import DashboardDefinationGrid from "./DashboardDefinationGrid";

const DashboardDefinationPage = () => {
  return (
    <div className="c-main">
      <div className="c-content">
        <DashboardDefinationGrid />
      </div>
    </div>
  );
};
export default DashboardDefinationPage;

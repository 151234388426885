export const applicationConfig = {
  apiUrl: "https://api.sendboxlink.io",
  apiDevUrl: "https://api.sendboxlink.io",
  //apiDevUrl: 'https://localhost:50366',
  elasticApiUrl: "http://192.168.10.33:9201",
  customerPanelUrl: "https://customer.sendboxlink.io",
  apiKey: "6!7s-7p4X-7xsLc-HOT?",
  apiVersion: "0.x.x",
  defaultLanguage: "tr-TR",
  defaultLanguageName: "Türkçe",
  defaultLanguageSmall: "tr",
  maxLoadSecond: 100,
  propNames: {
    apiKey: "x-api-key",
    tokenKey: "x-token-key",
    languageKey: "accept-language",
  },
  projectVersion: "v0.0.1 Alpha",
  permissionList: {
    Notification: true,
  },
  serviceUrl: {
    apiService: {
      checkUrl: "/api/Check",
      registerDevice: "/api/RegisterDevice",
      notificationDevice: "/api/NotificationDevice",
      uploadFile: "/api/UploadService/UploadFile",
    },
    reportDesignerService: {
      reportDesigner: "/api/ReportDesigner/GetReportDesignerModel",
    },
  },
};

export function getBaseApiUrl() {
  if (process.env.NODE_ENV !== "production") return applicationConfig.apiDevUrl;
  else return applicationConfig.apiUrl;
}

export function getApiUrl(serviceUrl) {
  let baseUrl = getBaseApiUrl();
  return baseUrl + serviceUrl;
}

export default applicationConfig;

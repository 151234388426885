import React, { useState } from "react";
import { Modal, Button, Input, message } from "antd";
import { useDispatch } from "react-redux";
import axios from "axios";
import { getApiUrl } from "../../../../config/applicationConfig";
import { GetFavoriteTagGroup } from "../../../../redux/actions/Analysis/AnalysisActions";
import { selectedTagGroupData } from "../recoil/RecoilActions";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

const AnalysisPageTreeAddFavorite = () => {
    const {t} = useTranslation();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [name, setName] = useState("");
  const selectedTagGroup = useRecoilState(selectedTagGroupData)[0];

  const showModal = () => {
    if (selectedTagGroup.length > 0) {
      setIsModalVisible(true);
    } else {
      message.error(t("selectTemplate"));
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    if (name.length > 0) {
      InsertAnalysisFavorite(selectedTagGroup, name);
    } else {
      message.error(t("enterTemplateTitle"));
    }
  };

  const InsertAnalysisFavorite = (selectedTags, name) => {
    const data = {
      treeviewIds: selectedTags.filter((p) => p.type == 4).map((p) => p.id),
      userId: 0,
      name: name,
    };
    axios
      .post(getApiUrl("/api/TagCustomTypeGroupFavorite/Insert"), data)
      .then((res) => res.data)
      .then((res) => {
        message.success(res.message);
        dispatch(GetFavoriteTagGroup());
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsModalVisible(false);
        setName("");
      });
  };

  return (
    <>
      <Button
        style={{ width: "100%", backgroundColor: "#cacaca" }}
        size="small"
        disabled={selectedTagGroup.length <= 0}
        onClick={showModal}
      >
        Seçimi Kaydet
      </Button>
      <Modal
        title={t("addSelectionToFavorites")}
        visible={isModalVisible}
        width={500}
        cancelText={t("cancel")}
        onCancel={handleCancel}
        okText={t("save")}
        onOk={handleOk}
      >
        <Input
          placeholder={t("favoriName")}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Modal>
    </>
  );
};
export default AnalysisPageTreeAddFavorite;

import { SelectBox } from "devextreme-react";
import React from "react";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../helper/Grid/GridCreatorMixin";
import DataSource from "devextreme/data/data_source";
import { setSubGridApiDefault } from "../../../config/fetchApiConfig";
import { useTranslation } from "react-i18next";

const ImportFileTypeGrid = () => {
  const { t } = useTranslation();
  const modelDataSource = new DataSource({
    store: setSubGridApiDefault("Model", "id", "GetRelationList"),
    group: "brandName",
  });

  const pageGrid = {
    apiController: "ImportFileType",
    apiKey: "Id",
    editTitle: t("importFileType"),
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 1,
      validationSummary: true,
      allowCopy: false,
    },
    onInitNewRow: (options) => {
      options.data.isActive = true;
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
      {
        dataField: "Versiyon",
        caption: t("version"),
      },
    ],
  };

  const importFileTypeDetailGrid = {
    apiController: "ImportFileTypeDetail",
    apiKey: "Id",
    editTitle: t("importFileTypeDetail"),
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      allowCopy: false,
    },
    subGridFeature: {
      loadUrl: "GetList",
      relationKey: "ImportFileTypeId",
      insertUrl: "Insert",
      updateUrl: "Update",
      removeUrl: "Delete",
    },
    columns: [
      {
        dataField: "Description",
        caption: t("description"),
      },
      {
        dataField: "ModelId",
        caption: "Model",
        dataType: "number",
        relationContent: {
          controller: "Model",
          display: "Name",
        },
        editCellComponent: (cell) => {
          return (
            <SelectBox
              dataSource={modelDataSource}
              valueExpr="id"
              grouped={true}
              onValueChanged={({ value }) => cell.setValue(value)}
              searchEnabled={true}
              defaultValue={cell.value}
              displayExpr="name"
              showClearButton={true}
            />
          );
        },
      },
    ],
  };

  let gridData = CreateGridData(pageGrid);
  const importFileTypeDetailData = CreateGridData(importFileTypeDetailGrid);

  gridData.subGrids = [
    {
      tabCaption: t("details"),
      tabGridFeature: importFileTypeDetailData,
    },
  ];
  return <RenderMasterGrid gridData={gridData} />;
};
export default ImportFileTypeGrid;

import React from "react";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../helper/Grid/GridCreatorMixin";
import { useTranslation } from "react-i18next";

const CommunicationDataTypeGrid = () => {
  const { t } = useTranslation();
  const pageGrid = {
    apiController: "ProductCommunicationType",
    apiKey: "Id",
    editTitle: t("productContactTypeInfo"),
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 1,
      validationSummary: true,
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
    ],
  };

  const communicationDataTypeGrid = {
    apiController: "CommunicationDataType",
    apiKey: "Id",
    editTitle: t("communicationDataType"),
    subGridFeature: {
      loadUrl: "GetList",
      relationKey: "ProductCommunicationTypeId",
      insertUrl: "Insert",
      updateUrl: "Update",
      removeUrl: "Delete",
    },
    columns: [
      {
        dataField: "Reference",
        caption: t("type"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "Code",
        caption: t("code"),
      },
      {
        dataField: "Description",
        caption: t("description"),
      },
      {
        dataField: "ValueMathType",
        caption: t("valueMultiplier"),
        dataType: "number",
        localRelationContent: {
          dataSource: [
            { key: 0, value: "+/-" },
            { key: 1, value: "+" },
            { key: 2, value: "-" },
          ],
          value: "key",
          display: "value",
        },
        fieldValidation: {
          isRequired: true,
        },
      },
    ],
  };

  const communicationDataTypeDetailGrid = {
    apiController: "CommunicationDataTypeDetail",
    apiKey: "Id",
    editTitle: t("communicationDataType"),
    subGridFeature: {
      loadUrl: "GetList",
      relationKey: "CommunicationDataTypeId",
      insertUrl: "Insert",
      updateUrl: "Update",
      removeUrl: "Delete",
    },
    columns: [
      {
        dataField: "Value",
        caption: t("type"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "Field",
        caption: t("type"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "Code",
        caption: t("code"),
      },
      {
        dataField: "Description",
        caption: t("description"),
      },
    ],
  };

  let gridData = CreateGridData(pageGrid);
  let communicationDataTypeGridData = CreateGridData(communicationDataTypeGrid);
  let communicationDataTypeDetailGridData = CreateGridData(
    communicationDataTypeDetailGrid
  );

  communicationDataTypeGridData.subGrids = [
    {
      tabCaption: t("contactDataTypeDetail"),
      tabGridFeature: communicationDataTypeDetailGridData,
    },
  ];

  gridData.subGrids = [
    {
      tabCaption: t("contactDataType"),
      tabGridFeature: communicationDataTypeGridData,
    },
  ];

  return <RenderMasterGrid gridData={CreateGridData(gridData)} />;
};
export default CommunicationDataTypeGrid;

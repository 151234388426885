import { ContextMenu, TreeView as DevTreeView } from "devextreme-react";
import React from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { paths } from "./../../../helper/resources/paths";
import { useSelector, useDispatch } from "react-redux";
import {
  SET_PARENT_PRODUCT_ID,
  SET_SELECTED_CENTRAL_ID,
  SET_SELECTED_TREEVIEW,
  DELETE_SELECTED_TREEVIEW,
} from "./../../../redux/actions/actionTypes";
import {
  DeleteTreeView,
  GetTreeViewData,
} from "./../../../redux/actions/Sbom/TreeViewActions";
import { useTranslation } from "react-i18next";

const TreeView = () => {
  const { t } = useTranslation();
  const productTreeState = useSelector((p) => p.ProductTreeReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  const contextMenuDataSource = [
    { id: "expand", text: t("expand") },
    { id: "collapse", text: t("collapse") },
    { id: "delete", text: t("delete") },
    { id: "update", text: t("update") },
    { id: "add", text: t("add") },
  ];

  const [state, setState] = React.useState({
    data: productTreeState.treeViewData,
    plusConfirmVisible: false,
    selectedSbomId: 0,
    treeViewRef: React.createRef(),
    contextMenuRef: React.createRef(),
  });
  React.useEffect(() => {
    setState({
      ...state,
      selectedSbomId: productTreeState.selectedTreeView.sbomId,
    });
  }, [productTreeState.selectedTreeView]);

  React.useEffect(() => {
    if (productTreeState.selectedCentralId > 0) {
      state.treeViewRef.current.instance.expandItem(
        productTreeState.selectedTreeView.sbomId
      );
    }
  }, [productTreeState.treeViewData]);

  const changePlusConfirm = () => {
    dispatch({ type: SET_PARENT_PRODUCT_ID, payload: state.selectedSbomId });
    history.push(paths.managament.productTree.productTypeSelect);
  };

  const expandAllNodes = () => {
    state.treeViewRef.current.instance.expandAll();
  };
  const collapseAllNodes = () => {
    state.treeViewRef.current.instance.collapseAll();
  };

  const contextMenuItemClick = (event) => {
    const treeView = state.treeViewRef.current.instance;
    switch (event.itemData.id) {
      case "expand": {
        treeView.expandItem(state.selectedSbomId);
        break;
      }
      case "collapse": {
        treeView.collapseItem(state.selectedSbomId);
        break;
      }
      case "update": {
        history.push(paths.managament.productTree.updateSbom);
        break;
      }
      case "add": {
        changePlusConfirm();
        break;
      }
      case "delete": {
        DeleteTreeView(state.selectedSbomId)
          .then(() => {
            dispatch({
              type: SET_SELECTED_CENTRAL_ID,
              payload: productTreeState.selectedCentralId,
            });
          })
          .then(() => {
            dispatch(GetTreeViewData(productTreeState.selectedCentralId));
          });

        break;
      }
      default:
        break;
    }
  };
  const treeViewItemContextMenu = (e) => {
    dispatch({
      type: SET_SELECTED_TREEVIEW,
      payload: {
        sbomId: e.itemData.id,
        productId: e.itemData.productId,
        isProduct: e.itemData.isProduct,
        categoryCode: e.itemData.categoryCode,
      },
    });

    setState({ ...state, selectedSbomId: e.itemData.id });

    const contextMenu = state.contextMenuRef.current.instance;

    contextMenu.option("items[0].visible", !e.node.expanded);
    contextMenu.option("items[1].visible", e.node.expanded);
  };

  const customItemOnClick = (sbomId, productId, isProduct, categoryCode) => {
    dispatch({
      type: SET_SELECTED_TREEVIEW,
      payload: {
        sbomId: sbomId,
        productId: productId,
        isProduct: isProduct,
        categoryCode: categoryCode,
      },
    });
    history.push(paths.managament.productTree.sbomProductDetailPage);
  };
  const customItemRender = (item, sbomId) => {
    const check = item.id === sbomId;
    return (
      <div
        className={`dx-item dx-treeview-item ${check ? "active" : ""}`}
        onClick={() => {
          {
            if (
              item.id === productTreeState.selectedTreeView.sbomId &&
              productTreeState.selectedTreeView.sbomId
            ) {
              dispatch({ type: DELETE_SELECTED_TREEVIEW, payload: null });
            } else {
              setState({ ...state, selectedSbomId: item.id });
              dispatch({
                type: SET_SELECTED_TREEVIEW,
                payload: {
                  sbomId: item.id,
                  productId: item.productId,
                  isProduct: item.isProduct,
                  categoryCode: item.categoryCode,
                },
              });
            }
          }
        }}
      >
        <div>{item.name}</div>
        <div
          style={{ position: "absolute", right: "10px", marginTop: "-20px" }}
        >
          <Button
            size="small"
            onClick={() =>
              customItemOnClick(
                item.id,
                item.productId,
                item.isProduct,
                item.categoryCode
              )
            }
          >
            <i className="fad fa-th-large"></i>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="p-tree">
      <div className="p-tree__menu-header">
        <Button onClick={() => changePlusConfirm()}>
          <i className="fas fa-file-plus"></i>
        </Button>
        <Button
          onClick={() =>
            dispatch(GetTreeViewData(productTreeState.selectedCentralId))
          }
        >
          <i className="far fa-redo-alt"></i>
        </Button>
        <Button onClick={collapseAllNodes}>
          <i className="fad fa-compress"></i>
        </Button>
        <Button onClick={expandAllNodes}>
          <i className="fad fa-expand"></i>
        </Button>
      </div>
      <div className="p-tree__content">
        <DevTreeView
          id="treeviewProduct"
          dataStructure="plain"
          items={productTreeState.treeViewData}
          ref={state.treeViewRef}
          displayExpr="name"
          parentIdExpr="parentId"
          focusStateEnabled={false}
          selectNodesRecursive={true}
          itemRender={(item) =>
            customItemRender(item, productTreeState.selectedTreeView.sbomId)
          }
          selectedItemKeys={[productTreeState.selectedTreeView.sbomId]}
          selectionMode="single"
          onItemContextMenu={treeViewItemContextMenu}
          searchEnabled={true}
        />
        <ContextMenu
          ref={state.contextMenuRef}
          target="#treeviewProduct .dx-treeview-item"
          dataSource={contextMenuDataSource}
          onItemClick={contextMenuItemClick}
        />
      </div>
    </div>
  );
};
export default TreeView;

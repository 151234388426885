import React from "react";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetUserByToken } from "../redux/actions/Shared/AuthActions";

const Routes = () => {
  const userState = useSelector((p) => p.SignedUserReducer);

  const history = useHistory();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(GetUserByToken(localStorage.getItem("token"), history));
  }, []);

  return userState.isLogin === true ? (
    <PrivateRoutes userState={userState} />
  ) : userState.isLogin === false ? (
    <PublicRoutes />
  ) : (
    <></>
  );
};

export default Routes;

import React from "react";
import { TinyArea, Progress } from "@ant-design/plots";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../helper/Grid/GridCreatorMixin";
import { useTranslation } from "react-i18next";

const DemoProgress = () => {
  const config = {
    height: 30,
    autoFit: true,
    percent: 0.7,
    color: ["#001a33", "#00386d"],
  };
  return <Progress {...config} />;
};

const DemoTinyArea = () => {
  const data = [
    264, 417, 438, 887, 309, 397, 550, 575, 563, 430, 525, 592, 492, 467, 513,
    546, 983, 340, 539, 243, 226, 192,
  ];

  const config = {
    height: 30,
    autoFit: true,
    data,
    smooth: true,
    areaStyle: {
      fill: "#00386d",
    },
  };
  return <TinyArea {...config} />;
};

const FleetManagerGrid = () => {
  const { t } = useTranslation();
  const pageGrid = {
    apiKey: "Id",
    customDataSource: [
      {
        id: 1,
        status: 80,
        plant: "PLANT-213",
        energyToday: 23.861,
        irradiance: 0,
        country: "UNITED KINGDOM",
        availability: null,
        ualMwp: null,
        dataDelay: 32,
        notification: 1,
        invertersStatus: "11;1;0;1",
        hub: null,
        notification2: 4,
      },
    ],
    hideId: true,
    selection: false,
    hideIsActive: true,
    editMode: {
      isActive: false,
      allowAdding: false,
      allowDeleting: false,
      allowUpdating: false,
      allowCopy: false,
      useIcons: false,
    },
    columns: [
      {
        dataField: "Status",
        caption: t("status"),
        dataType: "number",
        customCellRender: (data) => {
          return (
            <div
              style={{
                width: "35px",
                height: "35px",
                backgroundColor: "#d55b6a",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <strong
                style={{
                  color: "white",
                }}
              >
                {data.value}
              </strong>
            </div>
          );
        },
      },
      {
        dataField: "Plant",
        caption: t("plant"),
      },
      {
        dataField: "EnergyToday",
        caption: t("energyToday"),
        dataType: "number",
      },
      {
        dataField: "Irradiance",
        caption: t("irradiance"),
        dataType: "number",
        customCellRender: (data) => {
          return (
            <div>
              <DemoTinyArea />
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                706.93 W/m²
              </span>
            </div>
          );
        },
      },
      {
        dataField: "Country",
        caption: t("country"),
      },
      {
        dataField: "Availability",
        caption: t("availability"),
        customCellRender: (data) => {
          return (
            <div>
              <DemoProgress />
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                100%
              </span>
            </div>
          );
        },
      },
      {
        dataField: "UalMwp",
        caption: t("ualMwp"),
      },
      {
        dataField: "dataDelay",
        caption: t("dataDelay"),
        dataType: "number",
      },
      {
        dataField: "Notification",
        caption: t("notification"),
        dataType: "number",
        headerCellRender: (data) => <i className="fas fa-bell"></i>,
      },
      {
        dataField: "InvertersStatus",
        caption: t("invertersStatus"),
        customCellRender: (data) => {
          return (
            <div className="c-fleet__container">
              <strong
                className="c-fleet__box"
                style={{ backgroundColor: "#9bc27a" }}
              >
                8
              </strong>
              <strong
                className="c-fleet__box"
                style={{ backgroundColor: "#ffbf20" }}
              >
                0
              </strong>
              <strong
                className="c-fleet__box"
                style={{ backgroundColor: "#ff9456" }}
              >
                4
              </strong>
              <strong
                className="c-fleet__box"
                style={{ backgroundColor: "#d55b6a" }}
              >
                1
              </strong>
            </div>
          );
        },
      },
      {
        dataField: "Hub",
        caption: t("hub"),
      },
      {
        dataField: "Notification2",
        caption: t("notification2"),
        dataType: "number",
        headerCellRender: (data) => <i className="fas fa-bell"></i>,
      },
    ],
  };

  const gridData = CreateGridData(pageGrid);

  return <RenderMasterGrid gridData={gridData} />;
};
export default FleetManagerGrid;

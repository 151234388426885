import React from "react";
import { AlarmCauseTypeSource } from "../../../helper/enums/EnumDataSource";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../helper/Grid/GridCreatorMixin";
import UpdateDescriptionModal from "../../Alarms/AlarmView/Components/UpdateDescriptionModal";
import { useTranslation } from "react-i18next";

const NotActiveAlarmReportGrid = () => {
  const { t } = useTranslation();
  const [gridRef, setGridRef] = React.useState();

  const pageGrid = {
    apiController: "AlarmTriggerRecordView",
    apiKey: "Id",
    editTitle: t("alarmInfo"),
    setRef: setGridRef,
    loadParams: { isActive: false, isDeleted: false },
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: false,
      allowDeleting: false,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 1,
      validationSummary: true,
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        editCellVisibility: false,
      },
      {
        dataField: "Name",
        caption: t("name"),
        editCellVisibility: false,
      },
      {
        dataField: "Description",
        caption: t("description"),
      },
      {
        dataField: "AlarmCauseType",
        caption: t("causeOfAlarm"),
        dataType: "number",
        localRelationContent: {
          dataSource: AlarmCauseTypeSource,
        },
      },
      {
        dataField: "SbomId",
        caption: t("sbomCode"),
        dataType: "number",
        relationContent: {
          controller: "Sbom",
          display: "Name",
        },
        editCellVisibility: false,
      },
      {
        dataField: "CentralId",
        caption: t("centralCode"),
        dataType: "number",
        relationContent: {
          controller: "Central",
          display: "Name",
        },
        editCellVisibility: false,
      },
      {
        dataField: "AccountFieldId",
        caption: t("fieldCode"),
        dataType: "number",
        relationContent: {
          controller: "AccountField",
          display: "Name",
        },
        editCellVisibility: false,
      },
      {
        dataField: "AccountId",
        caption: t("companyCode"),
        dataType: "number",
        relationContent: {
          controller: "Account",
          display: "Name",
        },
        editCellVisibility: false,
      },
      {
        dataField: "AlarmTriggerRepeatCount",
        caption: t("numberRepetitions"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "CreatedAt",
        caption: t("creationDate"),
        dataType: "date",
        format: "dd/MM/yyyy HH:mm",
      },
      {
        dataField: "UpdatedAt",
        caption: t("updateDate"),
        dataType: "date",
        format: "dd/MM/yyyy HH:mm",
      },
      {
        dataField: "DeletedTime",
        caption: t("deleteDate"),
        dataType: "date",
        format: "dd/MM/yyyy HH:mm",
      },
    ],
  };
  const alarmTriggerRecordDetailGrid = {
    apiController: "AlarmTriggerRecordDetail",
    apiKey: "Id",
    editTitle: t("alarmRecordDetail"),
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: false,
      allowDeleting: true,
      allowUpdating: false,
      allowCopy: false,
    },
    subGridFeature: {
      loadUrl: "GetList",
      relationKey: "AlarmTriggerRecordId",
      insertUrl: "Insert",
      updateUrl: "Update",
      removeUrl: "Delete",
    },
    columns: [
      {
        dataField: "Value",
        caption: t("value"),
        dataType: "number",
      },
      {
        dataField: "TagCustomTypeId",
        caption: t("tagModel"),
        dataType: "number",
        relationContent: {
          controller: "TagCustomType",
          display: "Name",
        },
      },
      {
        dataField: "CreatedAt",
        caption: t("creationDate"),
        dataType: "date",
        format: "dd/MM/yyyy HH:mm",
      },
    ],
  };

  let gridData = CreateGridData(pageGrid);
  const alarmTriggerRecordDetailData = CreateGridData(
    alarmTriggerRecordDetailGrid
  );

  gridData.subGrids = [
    {
      tabCaption: t("details"),
      tabGridFeature: alarmTriggerRecordDetailData,
    },
  ];
  return (
    <>
      <UpdateDescriptionModal gridRef={gridRef} />
      <RenderMasterGrid gridData={gridData} />
    </>
  );
};

export default NotActiveAlarmReportGrid;

import React from "react";
import { DatePicker, Button, Tooltip } from "antd";
import "moment/locale/tr";
import FleetManagerGrid from "./FleetManagerGrid";
import dateLang from "antd/es/date-picker/locale/tr_TR";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;
const FleetManagerPage = () => {
  const { t } = useTranslation();
  return (
    <div className="c-main">
      <div className="c-page__header">
        <strong className="c-page__header-title">{t("pageName")}</strong>
        <div className="c-page__header-filter">
          <div className="c-page__header-filter-item">
            <RangePicker locale={dateLang} bordered={false} />
          </div>
          <div className="c-page__header-filter-item">
            <span className="c-page__header-filter-date-name">{t("period")}</span>
            <Tooltip
              placement="bottom"
              color="#32d2c9"
              title={t("facilitySpecificPeriodVal")}
            >
              <Button
                className="c-page__header-filter-date-button"
                size="small"
              >
                {t("ö")}
              </Button>
            </Tooltip>
            <Tooltip
              placement="bottom"
              color="#32d2c9"
              title={t("dailyValues")}
            >
              <Button
                className="c-page__header-filter-date-button"
                size="small"
              >
                {t("g")}
              </Button>
            </Tooltip>
            <Tooltip
              placement="bottom"
              color="#32d2c9"
              title={t("monthlyValues")}
            >
              <Button
                className="c-page__header-filter-date-button"
                size="small"
              >
                {t("a")}
              </Button>
            </Tooltip>
            <Tooltip
              placement="bottom"
              color="#32d2c9"
              title={t("yearlyValues")}
            >
              <Button
                className="c-page__header-filter-date-button"
                size="small"
              >
                {t("y")}
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="f-header">
        <div className="f-header__item f-header__item-small">
          <small>{t("ratedPower")}</small>
          <strong>
            26.00 <span>MWp</span>
          </strong>
          <small>1 İlden</small>
        </div>
        <div className="f-header__item f-header__item-small">
          <small>{t("activeAlarms")}</small>
          <strong>4</strong>
          <small>1 İlden</small>
        </div>
        <div className="f-header__item f-header__item-big">
          <div className="f-header__item-big-col">
            <small>{t("dailyEnergy")}</small>
            <strong>
              23.86 <span>MWp</span>
            </strong>
            <small>1 İlden</small>
          </div>
          <div className="f-header__item-big-col">
            <small>{t("dailySunExposure")}</small>
            <strong>
              3.82 <span>KWh/m2</span>
            </strong>
            <small>1 İlden</small>
          </div>
          <div className="f-header__item-big-col">
            <small>{t("dailyPerformance")}</small>
            <strong>
              24.03 <span>%</span>
            </strong>
            <small>1 İlden</small>
          </div>
          <div className="f-header__item-big-col">
            <small>{t("totalAvailability")}</small>
            <strong>
              - <span>%</span>
            </strong>
            <small>0 İlden</small>
          </div>
        </div>
      </div>
      <div className="c-content">
        <FleetManagerGrid />
      </div>
    </div>
  );
};
export default FleetManagerPage;

import React from "react";
import ImportFileTypeGrid from "./ImportFileTypeGrid";

const ImportFileTypePage = () => {
  return (
    <div className="c-main">
      <div className="c-content">
        <ImportFileTypeGrid />
      </div>
    </div>
  );
};
export default ImportFileTypePage;

import React from "react";
import ProductManagamentGrid from "./ProductManagamentGrid";

const ProductManagamentPage = () => {
  return (
    <div className="c-main">
      <div className="c-content">
        <ProductManagamentGrid />
      </div>
    </div>
  );
};
export default ProductManagamentPage;

import React from "react";
import BrowserTree from "./BrowserTree";
import BrowserGrid from "./BrowserGrid";
import BrowserGraph from "./BrowserGraph";

const BrowserPage = () => {
  return (
    <div className="c-main">
      <div className="c-browser__top">
        <BrowserTree />
        <div className="c-content">
          <BrowserGrid />
        </div>
      </div>
      <div className="c-browser__bottom">
        <div className="c-content">
          <BrowserGraph />
        </div>
      </div>
    </div>
  );
};
export default BrowserPage;

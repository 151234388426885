import React from "react";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../helper/Grid/GridCreatorMixin";
import colorData from "../../../assets/localData/colors.json";
import { Button, Input } from "antd";
import { useHistory } from "react-router-dom";
import { paths } from "./../../../helper/resources/paths";
import { SelectBox, NumberBox } from "devextreme-react";
import { TextBox } from "devextreme-react";
import { CoordinateType } from "../../../helper/enums/CoordinateType";
import { CoordinateTypeSource } from "../../../helper/enums/EnumDataSource";
import { doInputEvent } from "./../../../helper/JavascriptHelper";
import { fetcher } from "../../../config/fetchApiConfig";
import { getApiUrl } from "./../../../config/applicationConfig";
import { NotificationFormEdit } from "../../../components/Shared/form/NotificationFormEdit";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/Shared/Modal/Modal";
import UpdatePasswordModal from "../../../components/Shared/Modal/UpdatePasswordModal";

const FieldManagamentGrid = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const updateGridHide = (data) => {
    if (data?.coordinateType === CoordinateType.ED50) {
      if (document.getElementById("accountFieldGridId")) {
        document.getElementById("accountFieldGridId").style.visibility = null;
      }
    } else if (data?.coordinateType === CoordinateType.WG84) {
      if (document.getElementById("accountFieldGridId")) {
        document.getElementById("accountFieldGridId").style.visibility =
          "hidden";
      }
    }
  };

  const pageGrid = {
    apiController: "AccountField",
    apiKey: "Id",
    editTitle: t("areaInfo"),
    hideIsActive: true,
    editMode: {
      onChangesChange: (e) => {
        if (e[0]?.data) {
          updateGridHide(e[0].data);
        }
      },
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 1,
      validationSummary: true,
      customButtons: [
        {
          width: 170,
          caption: undefined,
          buttons: [
            {
              render: (e) => {
                let data = e.row.data;
                let url = paths.managament.centralManagament.replace(
                  ":accountFieldId",
                  data.id
                );

                return (
                  <Button
                    className="btn-grid-button"
                    onClick={() => history.push(url)}
                  >
                    {t("centralOperations")}
                  </Button>
                );
              },
            },
          ],
        },
      ],
    },
    onInitNewRow: (options) => {
      options.data.weatherStationId = 1;
      options.data.isVirtual = false;
      options.data.isActive = false;
      options.data.coordinateType = CoordinateType.WG84;
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 5,
          },
        },
        editCellComponent: (cell) => {
          const onChange = (value) => {
            cell.setValue(value);
          };
          const handleKeyDown = (e) => {
            if (e.key === "Tab") {
              fetcher(getApiUrl(`/api/AccountField/AutoGenerateCode`))
                .then((res) => res.json())
                .then((data) => {
                  var el = document.getElementById("fieldCodeInput");
                  doInputEvent(el, data);
                });
            }
          };
          return (
            <Input
              defaultValue={cell.value}
              maxLength={5}
              minLength={16}
              id="fieldCodeInput"
              onKeyDown={handleKeyDown}
              onChange={(e) => onChange(e.target.value)}
            />
          );
        },
        groupInfo: {
          groupCaption: t("areaInfo"),
          groupColCount: 3,
          groupColSpan: 3,
          groupIndex: 0,
        },
      },
      {
        dataField: "ShortName",
        caption: t("shortName"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 5,
          },
        },
        groupInfo: {
          groupCaption: t("areaInfo"),
          groupColCount: 3,
          groupColSpan: 3,
          groupIndex: 0,
        },
      },
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
        },
        groupInfo: {
          groupCaption: t("areaInfo"),
          groupColCount: 3,
          groupColSpan: 3,
          groupIndex: 0,
        },
      },
      {
        dataField: "ColorCode",
        caption: t("colorCode"),
        localRelationContent: {
          dataSource: colorData,
          value: "Hex",
          display: "Hex",
        },
        editCellComponent: (cell) => {
          return (
            <SelectBox
              dataSource={colorData}
              valueExpr="hex"
              displayExpr="hex"
              defaultValue={cell.value}
              onValueChanged={({ value }) => cell.setValue(value)}
              itemRender={(data) => {
                return (
                  <div
                    className="c-color"
                    style={{
                      background:
                        "linear-gradient(90deg, rgb(255,255,255) 20%, " +
                        data.rgb +
                        " 100%)",
                    }}
                  >
                    <div
                      className="c-color__view"
                      style={{ backgroundColor: data.hex }}
                    />
                    <span className="c-color__code">{data.hex}</span>
                  </div>
                );
              }}
              fieldRender={(data) => {
                return (
                  <div
                    className="c-color"
                    style={{
                      background:
                        data &&
                        "linear-gradient(90deg, rgb(255,255,255) 20%, " +
                          data.rgb +
                          " 100%)",
                    }}
                  >
                    <span className="c-color__code">
                      <TextBox
                        defaultValue={data && data.hex}
                        readOnly={true}
                      />
                    </span>
                  </div>
                );
              }}
            />
          );
        },
        groupInfo: {
          groupCaption: t("areaInfo"),
          groupColCount: 3,
          groupColSpan: 3,
          groupIndex: 1,
        },
      },
      {
        dataField: "Latitude",
        caption: t("latitude"),
        dataType: "number",
        groupInfo: {
          groupCaption: t("areaInfo"),
          groupColCount: 3,
          groupColSpan: 3,
          groupIndex: 1,
        },
      },
      {
        dataField: "Longitude",
        caption: t("longitude"),
        dataType: "number",
        groupInfo: {
          groupCaption: t("areaInfo"),
          groupColCount: 3,
          groupColSpan: 3,
          groupIndex: 1,
        },
      },
      {
        dataField: "CoordinateType",
        caption: t("coordinateType"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        localRelationContent: {
          dataSource: CoordinateTypeSource,
        },
        groupInfo: {
          groupCaption: t("areaInfo"),
          groupColCount: 3,
          groupColSpan: 3,
          groupIndex: 1,
        },
      },
      {
        dataField: "Grid",
        caption: "Grid",
        dataType: "number",
        editCellComponent: (cell) => {
          return (
            <NumberBox
              defaultValue={cell.value}
              onValueChanged={(e) => cell.setValue(e.value)}
              id="accountFieldGridId"
              style={{ visibility: cell.value ? null : "hidden" }}
            />
          );
        },
        groupInfo: {
          groupCaption: t("areaInfo"),
          groupColCount: 3,
          groupColSpan: 3,
          groupIndex: 1,
        },
      },
      {
        dataField: "AccountId",
        caption: t("company"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        relationContent: {
          controller: "Account",
          display: "Value",
        },
        groupInfo: {
          groupCaption: t("companyInfo"),
          groupColCount: 3,
          groupColSpan: 3,
          groupIndex: 2,
        },
      },
      {
        dataField: "LocationTypeId",
        caption: t("locationType"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        relationContent: {
          controller: "LocationType",
          display: "Name",
        },
        groupInfo: {
          groupCaption: t("companyInfo"),
          groupColCount: 3,
          groupColSpan: 3,
          groupIndex: 2,
        },
      },
      {
        dataField: "WeatherStationId",
        caption: t("weatherStation"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        relationContent: {
          controller: "WeatherStation",
          display: "Name",
        },
        groupInfo: {
          groupCaption: t("companyInfo"),
          groupColCount: 3,
          groupColSpan: 3,
          groupIndex: 2,
        },
      },
      {
        dataField: "IsVirtual",
        caption: t("virtual"),
        dataType: "boolean",
        groupInfo: {
          groupCaption: t("settingInformation"),
          groupColCount: 3,
          groupColSpan: 3,
          groupIndex: 3,
        },
      },
      {
        dataField: "IsActive",
        caption: t("active"),
        dataType: "boolean",
        groupInfo: {
          groupCaption: t("settingInformation"),
          groupColCount: 3,
          groupColSpan: 3,
          groupIndex: 3,
        },
      },
    ],
  };

  const featuresGrid = {
    apiController: "AccountFieldFeature",
    apiKey: "Id",
    editTitle: t("areaFeatures"),
    subGridFeature: {
      loadUrl: "GetList",
      relationKey: "AccountFieldId",
      insertUrl: "Insert",
      updateUrl: "Update",
      removeUrl: "Delete",
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
    ],
  };

  const userGrid = {
    apiController: "User",
    apiKey: "Id",
    editTitle: t("user"),
    hideIsActive: false,
    subGridFeature: {
      loadUrl: "GetListByAccountField",
      relationKey: "AccountFieldId",
      insertUrl: "InsertByAccountField",
      updateUrl: "Update",
      removeUrl: "Delete",
    },
    editMode: {
      customButtons: [
          {
              width: 130,
              caption: t("process"),
              buttons: [
                  {
                      render: (e) => {
                          var data = e.row.data
                          return (
                              <Modal width={600} className={"btn-grid"} buttonText={"Şifre Değiştir"} title={"Şifre Değiştir"} content={<UpdatePasswordModal email={data.email}/>} hideFooter/>
                          )
                      }
                  }]
              }
      ],
  },
    columns: [
      {
        dataField: "FirstName",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "LastName",
        caption: t("lastName"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "Email",
        caption: t("eMail"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "Password",
        caption: t("password"),
        dataType: "password",
        visibility: false,
      },
      {
        dataField: "Description",
        caption: t("description"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "NotificationSettings",
        caption: t("notificationSettings"),
        dataType: "string",
        customCellRender: (cell) =>
          JSON.parse(cell.value).toString().replaceAll("Is", ""),
        editCellComponent: (cell) => (
          <NotificationFormEdit
            value={cell.value}
            setValue={(value) => cell.setValue(value)}
          />
        ),
        groupInfo: {
          groupCaption: t("notificationSettings"),
          groupIndex: 2,
        },
      },
      {
        dataField: "NotificationPhoneNumber",
        caption: t("phoneNumber"),
        dataType: "string",
        editCellComponent: (cell) => (
          <PhoneInput
            country={"tr"}
            value={cell.value}
            onChange={(value) => cell.setValue(value)}
          />
        ),
        groupInfo: {
          groupCaption: t("notificationSettings"),
          groupIndex: 2,
        },
      },
    ],
  };

  let gridData = CreateGridData(pageGrid);
  const featuresGridData = CreateGridData(featuresGrid);
  const userGridData = CreateGridData(userGrid);

  gridData.subGrids = [
    {
      tabCaption: t("features"),
      tabGridFeature: featuresGridData,
    },
    {
      tabCaption: t("users"),
      tabGridFeature: userGridData,
    },
  ];

  return <RenderMasterGrid gridData={gridData} />;
};
export default FieldManagamentGrid;

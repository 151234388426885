import React from "react";
import { fetcher } from "../../../config/fetchApiConfig";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../helper/Grid/GridCreatorMixin";
import { getApiUrl } from "./../../../config/applicationConfig";
import { Input } from "antd";
import { doInputEvent } from "./../../../helper/JavascriptHelper";
import { useTranslation } from "react-i18next";

const BrandGrid = () => {
  const { t } = useTranslation();
  const pageGrid = {
    apiController: "Brand",
    apiKey: "Id",
    editTitle: t("brandInformation"),
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 1,
      validationSummary: true,
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        editCellComponent: (cell) => {
          const onChange = (value) => {
            cell.setValue(value);
          };
          const handleKeyDown = (e) => {
            if (e.key === "Tab") {
              fetcher(getApiUrl(`/api/Brand/AutoGenerateCode`))
                .then((res) => res.json())
                .then((data) => {
                  var el = document.getElementById("brandCodeInput");
                  doInputEvent(el, data);
                });
            }
          };
          return (
            <Input
              defaultValue={cell.value}
              maxLength={5}
              minLength={16}
              id="brandCodeInput"
              onKeyDown={handleKeyDown}
              onChange={(e) => onChange(e.target.value)}
            />
          );
        },
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
    ],
  };
  const modelGrid = {
    apiController: "Model",
    apiKey: "Id",
    editTitle: t("companyDetail"),
    subGridFeature: {
      loadUrl: "GetList",
      relationKey: "BrandId",
      insertUrl: "Insert",
      updateUrl: "Update",
      removeUrl: "Delete",
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
      {
        dataField: "Description",
        caption: t("description"),
      },
    ],
  };

  let gridData = CreateGridData(pageGrid);
  const modelGridData = CreateGridData(modelGrid);

  gridData.subGrids = [
    {
      tabCaption: t("models"),
      tabGridFeature: modelGridData,
    },
  ];
  return <RenderMasterGrid gridData={gridData} />;
};
export default BrandGrid;

import React from "react";
import { fetcher } from "../../../config/fetchApiConfig";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../helper/Grid/GridCreatorMixin";
import { getApiUrl } from "./../../../config/applicationConfig";
import { doInputEvent } from "./../../../helper/JavascriptHelper";
import { Input } from "antd";
import { useTranslation } from "react-i18next";

const TagCustomTypeGroupGrid = () => {
  const { t } = useTranslation();
  const pageGrid = {
    apiController: "TagCustomTypeGroup",
    apiKey: "Id",
    editTitle: t("tagCustomTypeGroup"),
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 1,
      validationSummary: true,
    },
    onInitNewRow: (options) => {
      options.data.isActive = true;
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        fieldValidation: {
          isRequired: true,
        },
        editCellComponent: (cell) => {
          const onChange = (value) => {
            cell.setValue(value);
          };
          const handleKeyDown = (e) => {
            if (e.key === "Tab") {
              fetcher(getApiUrl(`/api/TagCustomTypeGroup/AutoGenerateCode`))
                .then((res) => res.json())
                .then((data) => {
                  var el = document.getElementById("groupCodeInput");
                  doInputEvent(el, data);
                });
            }
          };
          return (
            <Input
              defaultValue={cell.value}
              maxLength={5}
              minLength={16}
              id="groupCodeInput"
              onKeyDown={handleKeyDown}
              onChange={(e) => onChange(e.target.value)}
            />
          );
        },
      },
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "OrderNo",
        caption: t("orderNo"),
        dataType: "number",
      },
      {
        dataField: "MasterGroupId",
        caption: t("masterGroup"),
        dataType: "number",
        relationContent: {
          controller: "TagCustomTypeGroup",
          display: "Name",
          customParam: { masterGroupIsNull: true },
        },
      },
    ],
  };

  return <RenderMasterGrid gridData={CreateGridData(pageGrid)} />;
};
export default TagCustomTypeGroupGrid;

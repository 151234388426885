import React from "react";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../helper/Grid/GridCreatorMixin";
import CountryCityDropdown from "./../../../components/Shared/form/CityCountry/CountryCityDropdown";
import AccountDetailTypeDefination from "./AccountDetailTypeDefination";
import { IecStandardTypeSource } from "../../../helper/enums/EnumDataSource";
import { getApiUrl } from "./../../../config/applicationConfig";
import { fetcher } from "../../../config/fetchApiConfig";
import { doInputEvent } from "./../../../helper/JavascriptHelper";
import { Input } from "antd";
import { NotificationFormEdit } from "../../../components/Shared/form/NotificationFormEdit";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import UpdatePasswordModal from "../../../components/Shared/Modal/UpdatePasswordModal";
import Modal from "../../../components/Shared/Modal/Modal";
import { useSelector } from "react-redux";

const CompanyManagementGrid = () => {
  const { t } = useTranslation();
  
  const pageGrid = {
    apiController: "Account",
    apiKey: "Id",
    editTitle: t("companyInfo"),
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 1,
      validationSummary: true,
    },
    columns: [
      {
        dataField: "Code",
        caption: t("companyCode"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 5,
          },
        },
        editCellComponent: (cell) => {
          const onChange = (value) => {
            cell.setValue(value);
          };
          const handleKeyDown = (e) => {
            if (e.key === "Tab") {
              fetcher(getApiUrl(`/api/Account/AutoGenerateCode`))
                .then((res) => res.json())
                .then((data) => {
                  var el = document.getElementById("companyCodeInput");
                  doInputEvent(el, data);
                });
            }
          };
          return (
            <Input
              defaultValue={cell.value}
              maxLength={5}
              minLength={16}
              id="companyCodeInput"
              onKeyDown={handleKeyDown}
              onChange={(e) => onChange(e.target.value)}
            />
          );
        },
        groupInfo: {
          groupCaption: t("companyDetail"),
          groupColCount: 3,
          groupColSpan: 3,
          groupIndex: 0,
        },
      },
      {
        dataField: "Name",
        caption: t("companyName"),
        fieldValidation: {
          isRequired: true,
        },
        groupInfo: {
          groupCaption: t("companyDetail"),
          groupColCount: 3,
          groupColSpan: 3,
          groupIndex: 0,
        },
      },
      {
        dataField: "Address1",
        caption: t("address"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 10,
          },
        },
        groupInfo: {
          groupCaption: t("addressInfo"),
          groupColCount: 3,
          groupColSpan: 3,
          groupIndex: 1,
        },
      },
      {
        dataField: "Address2",
        caption: t("address2"),
        groupInfo: {
          groupCaption: t("addressInfo"),
          groupColCount: 3,
          groupColSpan: 3,
          groupIndex: 1,
        },
      },
      {
        dataField: "CityId",
        caption: t("city"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        relationContent: {
          controller: "City",
          display: "Name",
        },
        editCellComponent: (cell) => (
          <CountryCityDropdown
            setCityValue={cell.setValue}
            cityValue={cell.value}
          />
        ),
        groupInfo: {
          groupCaption: t("addressInfo"),
          groupColCount: 3,
          groupColSpan: 3,
          groupIndex: 1,
        },
      },
      {
        dataField: "Email",
        caption: t("eMail"),
        groupInfo: {
          groupCaption: t("contact"),
          groupColCount: 4,
          groupColSpan: 4,
          groupIndex: 2,
        },
      },
      {
        dataField: "Telephone",
        caption: t("phone"),
        editorType: "dxPhone",
        groupInfo: {
          groupCaption: t("contact"),
          groupColCount: 4,
          groupColSpan: 4,
          groupIndex: 2,
        },
      },
      {
        dataField: "Fax",
        caption: t("fax"),
        editorType: "dxPhone",
        groupInfo: {
          groupCaption: t("contact"),
          groupColCount: 4,
          groupColSpan: 4,
          groupIndex: 2,
        },
      },
      {
        dataField: "Gsm",
        caption: t("gsm"),
        editorType: "dxPhone",
        groupInfo: {
          groupCaption: t("contact"),
          groupColCount: 3,
          groupColSpan: 3,
          groupIndex: 2,
        },
      },
      {
        dataField: "AccountTypeId",
        caption: t("customerType"),
        dataType: "number",
        relationContent: {
          controller: "AccountType",
          display: "Name",
        },
        groupInfo: {
          groupCaption: t("companyDetail"),
          groupColCount: 3,
          groupColSpan: 3,
          groupIndex: 0,
        },
      },
      {
        dataField: "ResellerAccountId",
        caption: t("reseller"),
        dataType: "number",
        relationContent: {
          controller: "Account",
          display: "Name",
        },
        editCellVisibility: false
      },
      {
        dataField: "IecStandardType",
        caption: "IEC 61724-1",
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        localRelationContent: {
          dataSource: IecStandardTypeSource,
        },
      },
    ],
  };

  const accountDetailGrid = {
    apiController: "AccountDetail",
    apiKey: "Id",
    editTitle: t("companyDetail"),
    subGridFeature: {
      loadUrl: "GetList",
      relationKey: "AccountId",
      insertUrl: "Insert",
      updateUrl: "Update",
      removeUrl: "Delete",
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
      {
        dataField: "Email",
        caption: t("eMail"),
        fieldValidation: {
          isEmail: true,
        },
      },
      {
        dataField: "CityId",
        caption: t("city"),
        dataType: "number",
        relationContent: {
          controller: "City",
          display: "Name",
        },
        editCellComponent: (cell) => (
          <CountryCityDropdown
            setCityValue={cell.setValue}
            cityValue={cell.value}
          />
        ),
      },
      {
        dataField: "Address1",
        caption: t("address"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 20,
          },
        },
      },
      {
        dataField: "Address2",
        caption: t("address2"),
      },
      {
        dataField: "Telephone",
        caption: t("companyPhone"),
        editorType: "dxPhone",
      },
      {
        dataField: "Fax",
        caption: t("companyFaks"),
        editorType: "dxPhone",
      },
      {
        dataField: "Gsm",
        caption: t("companyGsm"),
        editorType: "dxPhone",
      },
      {
        dataField: "Description",
        caption: t("description"),
      },
      {
        dataField: "AccountDetailTypeId",
        caption: t("companyDetailType"),
        dataType: "number",
        relationContent: {
          controller: "AccountDetailType",
          display: "Name",
        },
        editCellComponent: (cell) => (
          <AccountDetailTypeDefination
            setValue={cell.setValue}
            value={cell.value}
          />
        ),
      },
    ],
  };

  const centralDistributionGrid = {
    apiController: "CentralDistribution",
    apiKey: "Id",
    editTitle: t("companyDetail"),
    subGridFeature: {
      loadUrl: "GetList",
      relationKey: "AccountId",
      insertUrl: "Insert",
      updateUrl: "Update",
      removeUrl: "Delete",
    },
    columns: [
      {
        dataField: "StartDate",
        caption: t("startDate"),
        editorType: "dxDateBox",
      },
      {
        dataField: "EndDate",
        caption: t("endDate"),
        editorType: "dxDateBox",
      },
    ],
  };

  const userGrid = {
    apiController: "User",
    apiKey: "Id",
    editTitle: t("user"),
    hideIsActive: false,
    subGridFeature: {
      loadUrl: "GetList",
      relationKey: "AccountId",
      insertUrl: "Insert",
      updateUrl: "Update",
      removeUrl: "Delete",
    },
    editMode: {
      customButtons: [
          {
              width: 130,
              caption: t("process"),
              buttons: [
                  {
                      render: (e) => {
                          var data = e.row.data
                          return (
                              <Modal width={600} className={"btn-grid"} buttonText={"Şifre Değiştir"} title={"Şifre Değiştir"} content={<UpdatePasswordModal email={data.email}/>} hideFooter/>
                          )
                      }
                  }]
              }
      ],
    },
    columns: [
      {
        dataField: "FirstName",
        caption: t("name"),
      },
      {
        dataField: "LastName",
        caption: t("lastName"),
      },
      {
        dataField: "Email",
        caption: t("eMail"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "Password",
        caption: t("password"),
        dataType: "password"
      },
      {
        dataField: "Description",
        caption: t("description"),
      },
      {
        dataField: "NotificationSettings",
        caption: t("notificationSettings"),
        dataType: "string",
        customCellRender: (cell) =>
          JSON.parse(cell.value).toString().replaceAll("Is", ""),
        editCellComponent: (cell) => (
          <NotificationFormEdit
            value={cell.value}
            setValue={(value) => cell.setValue(value)}
          />
        ),
        groupInfo: {
          groupCaption: t("notificationSettings"),
          groupIndex: 2,
        },
      },
      {
        dataField: "NotificationPhoneNumber",
        caption: t("phoneNumber"),
        dataType: "string",
        editCellComponent: (cell) => (
          <PhoneInput
            country={"tr"}
            value={cell.value}
            onChange={(value) => cell.setValue(value)}
          />
        ),
        groupInfo: {
          groupCaption: t("notificationSettings"),
          groupIndex: 2,
        },
      },
    ],
  };

  let gridData = CreateGridData(pageGrid);
  const accountDetailGridData = CreateGridData(accountDetailGrid);
  const centralDistributionGridData = CreateGridData(centralDistributionGrid);
  const userGridData = CreateGridData(userGrid);

  gridData.subGrids = [
    {
      tabCaption: t("details"),
      tabGridFeature: accountDetailGridData,
    },
    {
      tabCaption: t("distributors"),
      tabGridFeature: centralDistributionGridData,
    },
    {
      tabCaption: t("users"),
      tabGridFeature: userGridData,
    },
  ];

  return <RenderMasterGrid gridData={gridData} />;
};
export default CompanyManagementGrid;

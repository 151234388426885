import { MenuType } from "../../enums/MenuType";
import { paths } from "../../resources/paths";
import CompanyManagementPage from "./../../../views/Managament/CompanyManagement/CompanyManagementPage";
import FieldManagamentPage from "./../../../views/Managament/FieldManagament/FieldManagamentPage";
import CentralManagamentPage from "./../../../views/Managament/FieldManagament/CentralManagement/CentralManagamentPage";
import ProductTree from "./../../../views/Managament/ProductTree/ProductTree";
import ProductManagamentPage from "./../../../views/Managament/ProductManagament/ProductManagamentPage";

export const ManagementItems = (t) => [
  {
    key: "1",
    icon: "fad fa-city",
    text: t("companyManagament"),
    path: paths.managament.companyManagament,
    render: <CompanyManagementPage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "2",
    icon: "fad fa-braille",
    text: t("fieldManagament"),
    path: paths.managament.fieldManagament,
    render: <FieldManagamentPage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "2.1",
    icon: "fas fa-plus-square",
    text: t("centralManagament"),
    path: paths.managament.centralManagament,
    render: <CentralManagamentPage />,
    MenuType: MenuType.SUBMENU,
  },
  {
    key: "3",
    icon: "fad fa-edit",
    text: t("productManagament"),
    path: paths.managament.productManagament,
    render: <ProductManagamentPage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "4",
    icon: "fad fa-folder-tree",
    text: t("productTree"),
    path: paths.managament.productTree.index,
    render: <ProductTree />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "4.1",
    icon: "fas fa-plus-square",
    text: t("productTree"),
    path: paths.managament.productTree.newProduct,
    render: <ProductTree />,
    MenuType: MenuType.SUBMENU,
  },
  {
    key: "4.3",
    icon: "fas fa-plus-square",
    text: t("productTree"),
    path: paths.managament.productTree.productTypeSelect,
    render: <ProductTree />,
    MenuType: MenuType.SUBMENU,
  },
  {
    key: "4.4",
    icon: "fas fa-plus-square",
    text: t("productTree"),
    path: paths.managament.productTree.productSelect,
    render: <ProductTree />,
    MenuType: MenuType.SUBMENU,
  },
  {
    key: "4.5",
    icon: "fas fa-plus-square",
    text: t("productTree"),
    path: paths.managament.productTree.sBom,
    render: <ProductTree />,
    MenuType: MenuType.SUBMENU,
  },
  {
    key: "4.6",
    icon: "fas fa-plus-square",
    text: t("productTree"),
    path: paths.managament.productTree.sbomProductDetailPage,
    render: <ProductTree />,
    MenuType: MenuType.SUBMENU,
  },
  {
    key: "4.7",
    icon: "fas fa-plus-square",
    text: t("productTree"),
    path: paths.managament.productTree.sbomProductCustomDetailPage,
    render: <ProductTree />,
    MenuType: MenuType.SUBMENU,
  },
  {
    key: "4.8",
    icon: "fas fa-plus-square",
    text: t("productTree"),
    path: paths.managament.productTree.sbomProductCommunicationPage,
    render: <ProductTree />,
    MenuType: MenuType.SUBMENU,
  },
  {
    key: "4.9",
    icon: "fas fa-plus-square",
    text: t("productTree"),
    path: paths.managament.productTree.updateSbom,
    render: <ProductTree />,
    MenuType: MenuType.SUBMENU,
  },
  {
    key: "4.10",
    icon: "fas fa-plus-square",
    text: t("productTree"),
    path: paths.managament.productTree.sbomProductMovement,
    render: <ProductTree />,
    MenuType: MenuType.SUBMENU,
  },
];

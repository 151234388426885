import {
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Logout } from "../../../redux/actions/Shared/AuthActions";
import crpLogoWhite from "../../../assets/img/corporate/crp-logo-white.png";
import { SetSidebarHidder } from "../../../redux/actions/Shared/SidebarHidderActions";
import { useHistory } from "react-router-dom";
import DashboardMasterDropdown from "./DashboardMasterDropdown";
import i18n from "../../../helper/LanguageSupport/i18nextInit";

const HeaderPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userState = useSelector((p) => p.SignedUserReducer);

  const [collapsed, setCollapsed] = useState(false);

  const menu = (
    <Menu>
      <Menu.Item key="0">{t("userProfile")}</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="0" onClick={() => dispatch(Logout(history))}>
        {t("signOut")}
      </Menu.Item>
    </Menu>
  );

  const lang = (
    <Menu onClick={e => i18n.changeLanguage(e.key)}>
      <Menu.Item key="tr" >TR</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="en">EN</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="fr">FR</Menu.Item>
    </Menu>
  );

  return (
    <>
      <header className="c-header">
        <div className="c-header__left">
          <a className="c-header__left-logo" href="/Home" rel="noreferrer">
            <img src={crpLogoWhite} alt="solar-logo" />
          </a>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger c-header__left-open-menu",
              onClick: (e) => {
                dispatch(SetSidebarHidder(!collapsed));
                setCollapsed((value) => !value);
              },
            }
          )}
        </div>
        <div className="c-header__menu">
          <div className="c-header__menu-container">
            <div className="c-header__menu-item">
              <div className="c-header__menu-search">
                <button className="c-header__menu-search-btn">
                  <i className="fas fa-search"></i>
                </button>
                <input
                  type="text"
                  className="c-header__menu-search-input"
                  placeholder={t("search")}
                />
              </div>
            </div>
            <div className="c-header__menu-item">
              <DashboardMasterDropdown />
            </div>
            <div className="c-header__menu-item">
              <i className="far fa-bell"></i>
            </div>
            <div className="c-header__menu-item">
              <Dropdown overlay={menu} trigger={["click"]}>
                <a
                  className="ant-dropdown-link c-header__menu-item-drop"
                  href="#"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="far fa-user fa-lg"></i>
                  <span className="c-header__menu-item-drop-name">
                    {userState.user.firstName} {userState.user.lastName}
                  </span>
                  <DownOutlined />
                </a>
              </Dropdown>
            </div>
            <div className="c-header__menu-item">
              <Dropdown overlay={lang} trigger={["click"]}>
                <a
                  className="ant-dropdown-link c-header__menu-item-drop"
                  href="#"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="far fa-globe"></i>
                  <span className="c-header__menu-item-drop-lang">TR</span>
                </a>
              </Dropdown>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default HeaderPage;

import React from "react";
import CentralManagamentGrid from "./CentralManagamentGrid";

const CentralManagamentPage = () => {
  return (
    <div className="c-main">
      <div className="c-content">
        <CentralManagamentGrid />
      </div>
    </div>
  );
};
export default CentralManagamentPage;

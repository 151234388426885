import { loadMessages, locale } from "devextreme/localization";
import React, { useCallback, useEffect } from "react";
import LoadingMask from "react-loadingmask";
import { useDispatch, useSelector } from "react-redux";
import devextremeLocalization from "./assets/locales/tr/devextremeLocalization.json";
import { setFetchApiDefault } from "./config/fetchApiConfig";
import { SetLoading } from "./redux/actions/Shared/LoadingActions";
import Routes from "./routes";
import axios from "axios";
import { SetGlobalStringFunctions } from "./helper/JavascriptHelper";
import { RecoilRoot } from "recoil";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const App = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector((p) => p.LoadingReducer);
  const userState = useSelector((p) => p.SignedUserReducer);
  const history = useHistory();

  const initEffect = useCallback(() => {
    loadMessages(devextremeLocalization);
    locale(localStorage.getItem("i18nextLng"));
    SetGlobalStringFunctions();
    setFetchApiDefault(SetLoading, dispatch, history);
  }, [dispatch]);

  useEffect(() => {
    initEffect();
    if (userState.isLogin) {
      axios.defaults.headers.common.Authorization = `Bearer ${userState.token}`
    }
  }, [initEffect, userState]);

  return (
    <>
      <RecoilRoot>
        <LoadingMask loading={isLoading} loadingText={t("pleaseWait")}>
          <Routes></Routes>
        </LoadingMask>
      </RecoilRoot>
    </>
  );
};

export default App;

import React from "react";
import { SelectBox } from "devextreme-react";
import { useEffect } from "react";
import { setSubGridApiDefault } from "../../../../config/fetchApiConfig";
import { useSelector, useDispatch } from "react-redux";
import { SET_ALARMCONNECTION_EDIT } from "./../../../../redux/actions/actionTypes";
import { useTranslation } from "react-i18next";

const SbomSelectBox = ({ setValue, value, _gridName = "" }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [customValue, setCustomValue] = React.useState(null);
  const alarmConnectionState = useSelector((p) => p.AlarmConnectionReducer);

  useEffect(() => {
    if (value) {
      dispatch({
        type: SET_ALARMCONNECTION_EDIT,
        payload: { dataField: "selectedSbomId", value: value },
      });
      setCustomValue(value);
    }
  }, [value]);

  return (
    <SelectBox
      dataSource={setSubGridApiDefault(
        "Sbom",
        "id",
        "GetListByCentral",
        null,
        null,
        null,
        {
          centralId: alarmConnectionState.selectedCentralId,
          gridName: _gridName,
        }
      )}
      displayExpr="name"
      onValueChanged={({ value }) => {
        setValue && setValue(value.id);
        setCustomValue(value.id);
        dispatch({
          type: SET_ALARMCONNECTION_EDIT,
          payload: { dataField: "selectedSbomId", value: value.id },
        });
      }}
      searchEnabled={true}
      value={customValue ?? alarmConnectionState.selectedSbomId}
      placeholder={t("selectSbom")}
    />
  );
};
export default SbomSelectBox;

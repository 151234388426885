import React from "react";
import BrandGrid from "./BrandGrid";

const BrandPage = () => {
  return (
    <div className="c-main">
      <div className="c-content">
        <BrandGrid />
      </div>
    </div>
  );
};
export default BrandPage;

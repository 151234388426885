import { Tabs } from "antd";
import RecipeRangeGrid from "./../Recipe/RecipeRange/RecipeRangeGrid";
import FleetManagerGrid from "./../FleetManager/FleetManagerGrid";
import i18n from "../../helper/LanguageSupport/i18nextInit"

const { TabPane } = Tabs;
const onChange = (key) => {

    console.log(key);
};

const BrowserGrid = () => (
  <Tabs defaultActiveKey="1" onChange={onChange} size="small">
    <TabPane tab={i18n.t("parametersBig")} key="1">
      <RecipeRangeGrid />
    </TabPane>
    <TabPane tab={i18n.t("generalDataBig")} key="2">
      <FleetManagerGrid />
    </TabPane>
    <TabPane tab={i18n.t("settingsBig")} key="3">
      <RecipeRangeGrid />
    </TabPane>
    <TabPane tab={i18n.t("eventsBig")} key="4">
      <FleetManagerGrid />
    </TabPane>
    <TabPane tab={i18n.t("stateBig")} key="5">
      <RecipeRangeGrid />
    </TabPane>
    <TabPane tab={i18n.t("subscriptionsBig")} key="6">
      <FleetManagerGrid />
    </TabPane>
  </Tabs>
);

export default BrowserGrid;

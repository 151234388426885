import RoleManagementPage from "../../../views/UserManagement/RoleManagement/RoleManagementPage";
import { MenuType } from "../../enums/MenuType";
import { paths } from "../../resources/paths";

export const UserManagementItems = (t) => [
  {
    key: "1",
    icon: "fas fa-map-marker-plus",
    text: t("roleManagement"),
    path: paths.userManagement.roleManagement,
    render: <RoleManagementPage />,
    MenuType: MenuType.MAINMENU,
  },
];

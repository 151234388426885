import React from "react";
import CentralTypeGrid from "./CentralTypeGrid";

const CentralTypePage = () => {
  return (
    <div className="c-main">
      <div className="c-content">
        <CentralTypeGrid />
      </div>
    </div>
  );
};
export default CentralTypePage;

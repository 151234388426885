import React from "react";
import LogReportGrid from "./LogReportGrid";

const LogReportPage = () => {
  return (
    <div className="c-main">
      <div className="c-content">
        <LogReportGrid />
      </div>
    </div>
  );
};
export default LogReportPage;

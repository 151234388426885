import React from "react";
import axios from "axios";
import TreeView from "devextreme-react/tree-view";
import { getApiUrl } from "../../../config/applicationConfig";
import { useDispatch, useSelector } from "react-redux";
import { MoreOutlined, DeleteOutlined } from "@ant-design/icons";
import { Dropdown, Space, Menu, message } from "antd";
import { dynamicSort } from "../../../helper/JavascriptHelper";
import CentralPageTreeAddFavorite from "./Components/CentralPageTreeAddFavorite";
import { GetCentralByAnalysisFavorite } from "../../../redux/actions/Analysis/AnalysisActions";
import { useRecoilState } from "recoil";
import { selectedCentral } from "./recoil/RecoilActions";
import { useTranslation } from "react-i18next";

const CentralPageTree = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const list = React.useRef();
  const [lastTop, setTop] = React.useState(0);
  const [component, setComponent] = React.useState();
  const [filterParam, setFilterParam] = React.useState("id");
  const [dataSource, setDataSource] = React.useState([]);
  const analysisState = useSelector((p) => p.AnalysisReducer);

  const setSelectedCentrals = useRecoilState(selectedCentral)[1];

  React.useEffect(() => {
    dispatch(GetCentralByAnalysisFavorite());
    if (dataSource.length <= 0) {
      axios
        .get(getApiUrl("/api/AlarmSbomTreeview/GetListOnlyCentral"))
        .then((res) => res.data)
        .then((res) => {
          setDataSource(res.data);
        });
    }
  }, []);

  const SetCentralByAnalysisFavoriteDetail = (centralByAnalysisFavoriteId) => {
    component.unselectAll();
    axios
      .get(getApiUrl("/api/CentralByAnalysisFavoriteDetail/GetList"), {
        params: {
          centralByAnalysisFavoriteId: centralByAnalysisFavoriteId,
        },
      })
      .then((res) => res.data)
      .then((res) => {
        res.data?.forEach((e) => {
          dataSource
            .filter((p) => p.id == e.treeviewId)
            .forEach((item) => component.selectItem(item));
        });
      })
      .catch(() => {});
  };
  const DeleteCentralByAnalysisFavoriteDetail = (
    centralByAnalysisFavoriteId
  ) => {
    axios
      .delete(getApiUrl("/api/CentralByAnalysisFavorite/Delete"), {
        params: {
          key: centralByAnalysisFavoriteId,
        },
      })
      .then((res) => res.data)
      .then((res) => {
        message.success(res.message);
        dispatch(GetCentralByAnalysisFavorite());
      })
      .catch(() => {});
  };

  const filterMenu = (
    <Menu>
      <Menu.SubMenu key={0} title={t("sort")}>
        <Menu.Item
          key={"0-1"}
          onClick={(e) => {
            setFilterParam("name");
          }}
        >
          {t("alphabetical")}
        </Menu.Item>
        <Menu.Item
          key={"0-2"}
          onClick={(e) => {
            setFilterParam("createDate");
          }}
        >
          {t("dateOfRegistration")}
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu key={1} title="Favoriler">
        {analysisState.favoriteAnalysisParameter?.map((item) => (
          <Menu.Item key={"1-" + item.id}>
            <div style={{ display: "flex", maxWidth: "150px" }}>
              <div
                style={{ maxWidth: "130px", overflow: "auto" }}
                onClick={() => SetCentralByAnalysisFavoriteDetail(item.id)}
              >
                <a>{item.name}</a>
              </div>
              <div
                style={{ minWidth: "20px", marginLeft: "10px" }}
                onClick={() => DeleteCentralByAnalysisFavoriteDetail(item.id)}
              >
                <DeleteOutlined />
              </div>
            </div>
          </Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.Item
        key={3}
        onClick={(e) => {
          setSelectedCentrals([]);
          setTop(0);
          component.unselectAll();
        }}
      >
        {t("clearSelection")}
      </Menu.Item>
      <Menu.Item key={4} onClick={(e) => list?.current?.instance?.expandAll()}>
        {t("openAll")}
      </Menu.Item>
      <Menu.Item
        key={5}
        onClick={(e) => list?.current?.instance?.collapseAll()}
      >
        {t("closeAll")}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="c-select__tree">
      <div className="c-select__tree-header" style={{ display: "flex" }}>
        <strong>{t("central")}</strong>

        <div style={{ paddingLeft: "70%" }}>
          <Dropdown overlay={filterMenu} trigger={["click"]}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <MoreOutlined style={{ fontSize: "18px", color: "black" }} />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>

      <div className="c-select__tree-menu">
        <TreeView
          onContentReady={(e) => {
            list?.current?.instance?._scrollableContainer.scrollTo(lastTop);
            setComponent(e.component);
          }}
          ref={list}
          key={filterParam}
          searchEnabled={true}
          searchEditorOptions={{ placeholder: t("search") }}
          dataSource={dataSource.sort(dynamicSort(filterParam))}
          dataStructure="plain"
          keyExpr="id"
          parentIdExpr="masterId"
          selectionMode="multiple"
          displayExpr="name"
          selectByClick={true}
          showCheckBoxesMode="selectAll"
          height="calc(100% - 35px)"
          onItemSelectionChanged={async (e) => {
            setTop(list.current.instance._scrollableContainer.scrollTop());
            await setSelectedCentrals(e.component.getSelectedNodes());
          }}
          onSelectAllValueChanged={async (e) => {
            if (e.value === true) {
              setTop(list.current.instance._scrollableContainer.scrollTop());
              await setSelectedCentrals(e.component.getSelectedNodes());
            }
          }}
        />
        <CentralPageTreeAddFavorite />
      </div>
    </div>
  );
};

export default CentralPageTree;

export const GET_LOADING = "GET_LOADING";
export const GET_SIDEBAR_HIDDER = "GET_SIDEBAR_HIDDER";
export const GET_SIGNED_USER = "GET_SIGNED_USER";

export const GET_PRODUCT_TREE_STATE = "GET_PRODUCT_TREE_STATE";
export const SET_SELECTED_CENTRAL_ID = "SET_SELECTED_CENTRAL_ID";
export const SET_SELECTBOX_DATA = "SET_SELECTBOX_DATA";
export const SET_PRODUCT_TYPE = "SET_PRODUCT_TYPE";
export const SET_PARENT_PRODUCT_ID = "SET_PARENT_PRODUCT_ID";
export const SET_SBOM_DETAIL = "SET_SBOM_DETAIL";
export const SET_TREEVIEW_DATA = "SET_TREEVIEW_DATA";
export const SET_SELECTED_TREEVIEW = "SET_SELECTED_TREEVIEW";
export const DELETE_SELECTED_TREEVIEW = "DELETE_SELECTED_TREEVIEW";
export const SET_TURN_TREEVIEW = "SET_TURN_TREEVIEW";

export const SET_ALARM_TRIGGER = "SET_ALARM_TRIGGER";
export const SET_ALARM_DETAIL = "SET_ALARM_DETAIL";
export const SET_ALARM_TYPE = "SET_ALARM_TYPE";
export const CLEAR_ALARM_TYPE = "CLEAR_ALARM_TYPE";
export const SET_ALARMCONNECTION_EDIT = "SET_ALARMCONNECTION_EDIT";
export const SET_DEFAULT_ALARMCONNECTION_EDIT =
  "SET_DEFAULT_ALARMCONNECTION_EDIT";

export const SET_RECIPERANGE_EDIT = "SET_RECIPERANGE_EDIT";

export const SET_DASHBOARD_WIDGETS = "SET_DASHBOARD_WIDGETS";
export const GET_DASHBOARD_LAYOUTS = "GET_DASHBOARD_LAYOUTS";

export const GET_DASHBOARD_WIDGET_DATA = "GET_DASHBOARD_WIDGET_DATA";
export const GET_DASHBOARD_WIDGET_IDS = "GET_DASHBOARD_WIDGET_IDS";
export const DELETE_DASHBOARD_WIDGET_IDS = "DELETE_DASHBOARD_WIDGET_IDS";

export const SET_HEADER_FILTER = "SET_HEADER_FILTER";
export const SET_ANALYSIS_HEADER_FILTER = "SET_ANALYSIS_HEADER_FILTER";

export const SET_ANALYSIS_TAGS = "SET_ANALYSIS_TAGS";
export const SET_ANALYSIS_TEMPLATE = "SET_ANALYSIS_TEMPLATE";
export const SET_ANALYSIS_PARAMETER = "SET_ANALYSIS_PARAMETER";
export const SELECTED_TAGCUSTOMTYPEGROUP_VIEW =
  "SELECTED_TAGCUSTOMTYPEGROUP_VIEW";
export const FAVORITE_ANALYSISPARAMETER = "FAVORITE_ANALYSISPARAMETER";
export const FAVORITE_TAGGROUP = "FAVORITE_TAGGROUP";
export const SET_TAGCUSTOMTYPEGROUP_VIEW = "SET_TAGCUSTOMTYPEGROUP_VIEW";
export const SET_GRAPH_DATA = "SET_GRAPH_DATA";

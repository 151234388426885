import React from "react";
import CountryDropdown from "../../../components/Shared/form/CityCountry/CountryDropdown";
import RegionDropdown from "../../../components/Shared/form/CityCountry/RegionDropdown";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../helper/Grid/GridCreatorMixin";
import { useTranslation } from "react-i18next";

const LocationUnitGrid = React.memo(
  () => {
      const { t } = useTranslation();
    const [countryId, setCountryId] = React.useState(0);

    const pageGrid = {
      apiController: "LocationUnit",
      apiKey: "Id",
      editTitle: t("locationTypeInfo"),
      editMode: {
        isActive: true,
        mode: "form",
        allowAdding: true,
        allowDeleting: true,
        allowUpdating: true,
        useIcons: true,
        containerColCount: 1,
        validationSummary: true,
      },
      columns: [
        {
          dataField: "Code",
          caption: t("code"),
          fieldValidation: {
            isRequired: true,
            stringLength: {
              min: 2,
            },
          },
        },
        {
          dataField: "Name",
          caption: t("name"),
          fieldValidation: {
            isRequired: true,
            stringLength: {
              min: 2,
            },
          },
        },
      ],
    };
    const recipeTypeGrid = {
      apiController: "RecipeType",
      apiKey: "Id",
      editTitle: t("tariffType"),
      subGridFeature: {
        loadUrl: "GetList",
        relationKey: "LocationUnitId",
        insertUrl: "Insert",
        updateUrl: "Update",
        removeUrl: "Delete",
      },
      columns: [
        {
          dataField: "Code",
          caption: t("code"),
          fieldValidation: {
            isRequired: true,
            stringLength: {
              min: 2,
            },
          },
        },
        {
          dataField: "Name",
          caption: t("name"),
          fieldValidation: {
            isRequired: true,
            stringLength: {
              min: 2,
            },
          },
        },
        {
          dataField: "CountryId",
          caption: t("country"),
          dataType: "number",
          fieldValidation: {
            isRequired: true,
          },
          relationContent: {
            controller: "Country",
            display: t("name"),
          },
          editCellComponent: (cell) => (
            <CountryDropdown
              setValue={(id) => {
                cell.setValue(id);
              }}
              value={cell.value}
            />
          ),
        },
        {
          dataField: "CityId",
          caption: t("city"),
          dataType: "number",
          relationContent: {
            controller: "City",
            display: "Name",
          },
          editCellComponent: (cell) => (
            <RegionDropdown
              countryId={1}
              value={cell.value}
              setValue={cell.setValue}
            />
          ),
        },
      ],
    };

    let gridData = CreateGridData(pageGrid);
    const recipeTypeGridData = CreateGridData(recipeTypeGrid);

    gridData.subGrids = [
      {
        tabCaption: t("tariffs"),
        tabGridFeature: recipeTypeGridData,
      },
    ];
    return <RenderMasterGrid gridData={gridData} />;
  },
  () => true
);

export default LocationUnitGrid;

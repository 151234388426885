import HomePage from "../../views/Shared/HomePage/HomePage";
import WorkOrdersPage from "../../views/WorkOrders/WorkOrdersPage";
import { MenuType } from "../enums/MenuType";
import { paths } from "../resources/paths";
import { AlarmItems } from "./SubMenuItems/AlarmItems";
import { AreaItems } from "./SubMenuItems/AreaItems";
import { DefinationItems } from "./SubMenuItems/DefinationItems";
import { ManagementItems } from "./SubMenuItems/ManagementItems";
import { RegisterModelItems } from "./SubMenuItems/RegisterModelItems";
import { ReportItems } from "./SubMenuItems/ReportItems";
import { RecipeItems } from "./SubMenuItems/RecipeItems";
import AnalysisPage from "../../views/Analysis/Analysis/AnalysisPage";
import { AnalysisItems } from "./SubMenuItems/AnalysisItems";
import { UserManagementItems } from "./SubMenuItems/UserManagementItems";

const items = (t) => [
  {
    key: "0",
    icon: "fas fa-home",
    text: t("home"),
    path: paths.homePage,
    subMenuItems: [],
    menuType: MenuType.SIDEBAR,
    render: <HomePage />,
  },
  {
    key: "1",
    icon: "fas fa-tasks",
    text: t("managament"),
    path: "",
    subMenuItems: ManagementItems(t),
    menuType: MenuType.SIDEBAR,
    render: null,
  },
  {
    key: "2",
    icon: "fas fa-analytics",
    text: t("analysis"),
    path: paths.analysis.analysis,
    subMenuItems: AnalysisItems(t),
    menuType: MenuType.SIDEBAR,
    render: <AnalysisPage />,
  },
  {
    key: "3",
    icon: "fas fa-bell",
    text: t("alarms"),
    path: paths.alarms.alarms,
    subMenuItems: AlarmItems(t),
    menuType: MenuType.SIDEBAR,
  },
  {
    key: "4",
    icon: "fas fa-bring-front",
    text: t("areas"),
    path: paths.areas.areas,
    subMenuItems: AreaItems(t),
    menuType: MenuType.SIDEBAR,
  },
  {
    key: "5",
    icon: "fas fa-file-contract",
    text: t("reports"),
    path: paths.reports,
    subMenuItems: ReportItems(t),
    menuType: MenuType.SIDEBAR,
  },
  {
    key: "6",
    icon: "fas fa-plus-square",
    text: t("registerModelAdd"),
    path: paths.registerModelAdded.registerModelAdd,
    subMenuItems: RegisterModelItems(t),
    menuType: MenuType.SIDEBAR,
  },
  {
    key: "7",
    icon: "fas fa-solar-panel",
    text: t("definations"),
    path: "",
    subMenuItems: DefinationItems(t),
    menuType: MenuType.SIDEBAR,
  },
  {
    key: "8",
    icon: "fas fa-solar-panel",
    text: t("recipes"),
    path: "",
    subMenuItems: RecipeItems(t),
    menuType: MenuType.SIDEBAR,
  },
  {
    key: "9",
    icon: "fas fa-sort-size-down",
    text: t("workOrders"),
    path: paths.workOrders,
    subMenuItems: [],
    menuType: MenuType.SIDEBAR,
    render: <WorkOrdersPage />,
  },
  {
    key: "10",
    icon: "fad fa-users-cog",
    text: t("userManagement"),
    path: "",
    subMenuItems: UserManagementItems(t),
    menuType: MenuType.SIDEBAR,
  },
  // {
  //   key: "2",
  //   icon: "fas fa-tasks-alt",
  //   text: t("fleetManager"),
  //   path: paths.fleetManager,
  //   subMenuItems: [],
  //   menuType: MenuType.SIDEBAR,
  //   render: <FleetManagerPage />,
  // },
  // {
  //   key: "3",
  //   icon: "fad fa-project-diagram",
  //   text: t("crossPlantExport"),
  //   path: paths.crossPlantExport,
  //   subMenuItems: [],
  //   menuType: MenuType.SIDEBAR,
  //   render: <CrossPlantExportPage />,
  // },
];

//Test edildi tasarım çalışmalarında rahat çalışmak için yorumlar ile pasif hale getirildi
export const MenuItems = (t, userRole) =>
  items(t).filter(
    function (e) {
      const roles =
        userRole.map((item) => {
          var object = {
            menuKey: item.menuKey,
            subMenuKeys: item.subMenuKeys.map((p) => p.subMenuKey),
          };
          return object;
        }) ?? [];

      const subMenuItem = roles.find((p) => p.menuKey === e.key);

      if (subMenuItem) {
        e.subMenuItems = e.subMenuItems.filter(function (e) {
          return subMenuItem.subMenuKeys.indexOf(e.key) >= 0
          //  return true;
        });
      }
      this.push(...this, "0");
       return this.indexOf(e.key) >= 0
      //  return true;
    },
    Array.from(userRole, (x) => x.menuKey)
  );

import "devexpress-reporting/dx-reportdesigner";
import ko from "knockout";
import React from "react";
import { getBaseApiUrl } from "../../config/applicationConfig";
import { applicationConfig } from "./../../config/applicationConfig";
import { useTranslation } from "react-i18next";

class ReportDesignerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.reportUrl = ko.observable("Report1");
    this.designerModel = ko.observable();
    this.requestOptions = {
      host: getBaseApiUrl(),
      getDesignerModelAction:
        applicationConfig.serviceUrl.reportDesignerService.reportDesigner,
    };
  }
  render() {
    return <div ref="designer" data-bind="dxReportDesigner: $data"></div>;
  }
  componentDidMount() {
    ko.applyBindings(
      {
        reportUrl: this.reportUrl,
        requestOptions: this.requestOptions,
      },
      this.refs.designer
    );
  }
  componentWillUnmount() {
    ko.cleanNode(this.refs.designer);
  }
}

const ReportIdentificationPage = () => {
  const { t } = useTranslation();
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ReportDesignerComponent />
    </div>
  );
};

export default ReportIdentificationPage;

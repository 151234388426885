import { message } from "antd";

const messageSuccess = (text) => {
  message.success({
    content: text,
    className: "custom-class",
    style: {
      position: "fixed",
      bottom: 0,
      right: 0,
    },
  });
};

export default messageSuccess;

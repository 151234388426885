import {
  SET_PARENT_PRODUCT_ID,
  SET_PRODUCT_TYPE,
  SET_SBOM_DETAIL,
  SET_SELECTBOX_DATA,
  SET_SELECTED_CENTRAL_ID,
  SET_SELECTED_TREEVIEW,
  SET_TREEVIEW_DATA,
} from "../../actions/actionTypes";
import {
  SET_TURN_TREEVIEW,
  DELETE_SELECTED_TREEVIEW,
} from "./../../actions/actionTypes";

const productTreeState = {
  selectedCentralId: null,
  productType: null,
  parentSbomId: null,
  sbomCode: null,
  sbomName: null,
  sbomIsActive: true,
  treeViewData: [],
  selectedTreeView: {
    sbomId: null,
    productId: null,
    isProduct: true,
    categoryCode: "",
  },
  selectBoxData: {
    centralId: null,
    accountId: null,
    fieldId: null,
    fieldSelectBoxOpen: false,
    centralSelectBoxOpen: false,
  },
};

export const ProductTreeReducer = (state = productTreeState, action) => {
  switch (action.type) {
    case SET_SELECTED_CENTRAL_ID:
      return {
        ...productTreeState,
        selectedCentralId: parseInt(action.payload),
      };
    case SET_SELECTBOX_DATA:
      return { ...state, selectBoxData: action.payload };
    case DELETE_SELECTED_TREEVIEW:
      return { ...state, selectedTreeView: productTreeState.selectedTreeView };
    case SET_TURN_TREEVIEW:
      return {
        ...productTreeState,
        selectedCentralId: parseInt(action.payload),
        treeViewData: state.treeViewData,
        selectedTreeView: state.selectedTreeView,
      };
    case SET_PARENT_PRODUCT_ID:
      return { ...state, parentSbomId: parseInt(action.payload) };
    case SET_PRODUCT_TYPE:
      return { ...state, productType: action.payload };
    case SET_SBOM_DETAIL:
      return {
        ...state,
        sbomCode: action.payload.code,
        sbomName: action.payload.name,
        sbomIsActive: action.payload.isActive,
      };
    case SET_TREEVIEW_DATA:
      return { ...state, treeViewData: action.payload };
    case SET_SELECTED_TREEVIEW:
      return { ...state, selectedTreeView: action.payload };
    default:
      return state;
  }
};

import { Button, Card } from "antd";
import axios from "axios";
import React from "react";
import { useTranslation } from "react-i18next";
import { getApiUrl } from "../../../../config/applicationConfig";
import { TagBox } from "devextreme-react";
import { UpdateRangePermission } from "../../../../redux/actions/Role/RoleActions";
import { setSubGridApiDefault } from "../../../../config/fetchApiConfig";

const RolPermissionModal = ({ roleId, selectedPermissions }) => {
  const { t } = useTranslation();
  const [permissions, setPermissions] = React.useState([]);

  React.useEffect(()=> {
    axios
    .get(getApiUrl("/api/RolePermission/GetList?roleId="+roleId))
    .then((res) => res.data)
    .then((res) => setPermissions(res.data.map(x => x.permissionId)));
  },[roleId])

  React.useEffect(()=> {
    setPermissions(selectedPermissions);
  },[selectedPermissions])

  return (
    <div style={{ marginBottom: "15px" }}>
      <Card >
        <TagBox
            dataSource={setSubGridApiDefault(
              "RolePermission",
              "id",
              "GetListPermission",
              null,
              null,
              null
            )}
            showSelectionControls={true}
            valueExpr="id"
            displayExpr="apiPath"
            onValueChanged={({value}) => setPermissions(value)}
            defaultValue={selectedPermissions}
            searchEnabled="true"
            searchMode="contains"
        />
      </Card>
      <Button className="btn-save" style={{ float: "right" }} onClick={()=>UpdateRangePermission(roleId,permissions)}>
        {t("save")}
      </Button>
    </div>
  );
};

export default RolPermissionModal;

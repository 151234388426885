import React from "react";
import {
  OperatorTypeSource,
  PriorityTypeSource,
  SqlOperatorTypeSource,
} from "../../helper/enums/EnumDataSource";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../helper/Grid/GridCreatorMixin";
import AddAlarmModal from "./Components/AddAlarmModal";
import AccountSelectBox from "./Components/AlarmConnection/AccountSelectBox";
import CentralSelectBox from "./Components/AlarmConnection/CentralSelectBox";
import AccountFieldSelectBox from "./Components/AlarmConnection/AccountFieldSelectBox";
import SbomSelectBox from "./Components/AlarmConnection/SbomSelectBox";
import { useDispatch } from "react-redux";
import { SET_DEFAULT_ALARMCONNECTION_EDIT } from "../../redux/actions/actionTypes";
import { getApiUrl } from "./../../config/applicationConfig";
import { doInputEvent } from "../../helper/JavascriptHelper";
import { fetcher } from "../../config/fetchApiConfig";
import { Input } from "antd";
import PhoneInput from "react-phone-input-2";
import { NotificationFormEdit } from "../../components/Shared/form/NotificationFormEdit";
import { useTranslation } from "react-i18next";

const AlarmIdentificationPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const pageGrid = {
    apiController: "AlarmTrigger",
    apiKey: "Id",
    editTitle: t("alarmInfo"),
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: false,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 1,
      validationSummary: true,
      allowAllCopy: true,
      allowCopy: false,
    },
    columns: [
      {
        dataField: "Code",
        caption: t("alarmCode"),
        editCellComponent: (cell) => {
          const onChange = (value) => {
            cell.setValue(value);
          };
          const handleKeyDown = (e) => {
            if (e.key === "Tab") {
              fetcher(getApiUrl(`/api/AlarmTrigger/AutoGenerateCode`))
                .then((res) => res.json())
                .then((data) => {
                  var el = document.getElementById("alarmTriggerCodeInput");
                  doInputEvent(el, data);
                });
            }
          };
          return (
            <Input
              defaultValue={cell.value}
              maxLength={5}
              minLength={16}
              id="alarmTriggerCodeInput"
              onKeyDown={handleKeyDown}
              onChange={(e) => onChange(e.target.value)}
            />
          );
        },
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "Name",
        caption: t("alarmName"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "Priority",
        caption: t("priority"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        localRelationContent: {
          dataSource: PriorityTypeSource,
        },
      },
      {
        dataField: "IntervalTime",
        caption: t("delayTime"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "RepeatCount",
        caption: t("numberRepetitions"),
        dataType: "number",
      },
      {
        dataField: "IsSunrise",
        caption: t("sunrise") + " / " + t("sunset"),
        dataType: "boolean",
      },
      {
        dataField: "Sunrise",
        caption: t("sunriseAlarm"),
        dataType: "number",
      },
      {
        dataField: "Sunset",
        caption: t("sunsetAlarm"),
        dataType: "number",
      },
      {
        dataField: "AlarmTypeId",
        caption: t("alarmType"),
        dataType: "number",
        relationContent: {
          controller: "AlarmType",
          display: "Code",
        },
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "NotificationSettings",
        caption: t("notificationSettings"),
        dataType: "string",
        customCellRender: (cell) =>
          JSON.parse(cell.value).toString().replaceAll("Is", ""),
        editCellComponent: (cell) => (
          <NotificationFormEdit
            value={cell.value}
            setValue={(value) => cell.setValue(value)}
          />
        ),
        groupInfo: {
          groupCaption: t("notificationSettings"),
          groupIndex: 2,
        },
      },
    ],
  };
  const alarmDetailGrid = {
    apiController: "AlarmTriggerDetail",
    apiKey: "Id",
    editTitle: t("alarmDetail"),
    subGridFeature: {
      loadUrl: "GetList",
      relationKey: "AlarmTriggerId",
      insertUrl: "Insert",
      updateUrl: "Update",
      removeUrl: "Delete",
    },
    columns: [
      {
        dataField: "Description",
        caption: t("description"),
      },

      {
        dataField: "RegValue",
        caption: t("value"),
        dataType: "number",
      },
      {
        dataField: "Operator",
        caption: t("operator"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        localRelationContent: {
          dataSource: OperatorTypeSource,
        },
      },
      {
        dataField: "TagCustomTypeId",
        caption: t("tag"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        relationContent: {
          controller: "TagCustomType",
          display: "Name",
        },
      },
      {
        dataField: "SqlOperator",
        caption: t("sqlOperator"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        localRelationContent: {
          dataSource: SqlOperatorTypeSource,
        },
      },
      {
        dataField: "PlusMinusValue",
        caption: "+/-",
        dataType: "number"
      },
      {
        dataField: "Max Value",
        caption: t("maxValue"),
        dataType: "number"
      },
      {
        dataField: "Min Value",
        caption: t("minValue"),
        dataType: "number"
      },
      {
        dataField: "IsPercent",
        caption: t("isPercent"),
        dataType: "boolean"
      },
      {
        dataField: "PercentInfo",
        caption: t("percentInfo"),
        dataType: "number"
      },
    ],
  };
  const alarmConnectGrid = {
    apiController: "AlarmConnect",
    apiKey: "Id",
    editTitle: t("alarmDetail"),
    onEditCanceled: (e) =>
      dispatch({ type: SET_DEFAULT_ALARMCONNECTION_EDIT, payload: null }),
    onSaving: (e) =>
      dispatch({ type: SET_DEFAULT_ALARMCONNECTION_EDIT, payload: null }),
    subGridFeature: {
      loadUrl: "GetList",
      relationKey: "AlarmTriggerId",
      insertUrl: "Insert",
      updateUrl: "Update",
      removeUrl: "Delete",
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
      },
      {
        dataField: "Name",
        caption: t("name"),
      },
      {
        dataField: "Description",
        caption: t("desciription"),
      },
      {
        dataField: "AccountId",
        caption: t("company"),
        dataType: "number",
        relationContent: {
          controller: "Account",
          display: "Name",
        },
        editCellComponent: (cell) => (
          <AccountSelectBox
            setValue={(id) => {
              cell.setValue(id);
            }}
            value={cell.value}
          />
        ),
      },
      {
        dataField: "AccountFieldId",
        caption: t("area"),
        dataType: "number",
        relationContent: {
          controller: "AccountField",
          display: "Name",
        },
        editCellComponent: (cell) => (
          <AccountFieldSelectBox
            setValue={(id) => {
              cell.setValue(id);
            }}
            value={cell.value}
          />
        ),
      },
      {
        dataField: "CentralId",
        caption: t("central"),
        dataType: "number",
        relationContent: {
          controller: "Central",
          display: "Name",
        },
        editCellComponent: (cell) => (
          <CentralSelectBox
            setValue={(id) => {
              cell.setValue(id);
            }}
            value={cell.value}
          />
        ),
      },
      {
        dataField: "SbomId",
        caption: t("bom"),
        dataType: "number",
        relationContent: {
          controller: "Sbom",
          display: "Name",
        },
        editCellComponent: (cell) => (
          <SbomSelectBox
            setValue={(id) => {
              cell.setValue(id);
            }}
            value={cell.value}
            _gridName="AlarmDetail"
          />
        ),
      },
    ],
  };

  let gridData = CreateGridData(pageGrid);
  const alarmDetailData = CreateGridData(alarmDetailGrid);
  const alarmConnectData = CreateGridData(alarmConnectGrid);

  gridData.subGrids = [
    {
      tabCaption: t("details"),
      tabGridFeature: alarmDetailData,
    },
    {
      tabCaption: t("links"),
      tabGridFeature: alarmConnectData,
    },
  ];

  return (
    <div className="c-main">
      <div className="c-content">
        <AddAlarmModal />
        <RenderMasterGrid gridData={gridData} />
      </div>
    </div>
  );
};
export default AlarmIdentificationPage;

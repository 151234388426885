import axios from "axios";
import {
  FAVORITE_TAGGROUP,
  SET_ANALYSIS_PARAMETER,
  SET_GRAPH_DATA,
  SET_TAGCUSTOMTYPEGROUP_VIEW,
} from "../actionTypes";
import { getApiUrl } from "./../../../config/applicationConfig";
import { FAVORITE_ANALYSISPARAMETER } from "./../actionTypes";

export const GetAnalysisParameter = () => {
  return async (dispatch) => {
    axios
      .get(getApiUrl("/api/AnalysisParameter/GetList"))
      .then((res) => res.data)
      .then((res) => {
        dispatch({ type: SET_ANALYSIS_PARAMETER, payload: res.data });
      });
  };
};

export const GetTagCustomTypeGroup = () => {
  return async (dispatch) => {
    axios
      .get(getApiUrl("/api/TagCustomTypeGroup/GetListView"))
      .then((res) => res.data)
      .then((res) => {
        dispatch({ type: SET_TAGCUSTOMTYPEGROUP_VIEW, payload: res.data });
      });
  };
};

export async function AsyncGetCustomTagDataMovement(
  dateFilterState,
  selectedCentral,
  selectedTagGroupData,
  products,
  categories,
  sunriseType
) {
  if (selectedCentral.length > 0 && selectedTagGroupData.length > 0) {
    try {
      const response = await axios.post(
        getApiUrl("/api/CustomTagDataMovement/GetListByAnalysis"),
        {
          alarmSbomTreeviews: selectedCentral,
          selectedTags: selectedTagGroupData,
          startDate: dateFilterState.startDate,
          endDate: dateFilterState.endDate,
          productIds: products.map((p) => p.id),
          productCategoryIds: categories.map((p) => p.id),
          sunriseType: sunriseType,
        }
      );
      return response.data;
    } catch (err) {
      return [];
    }
  } else {
    return [];
  }
}

export const GetCustomTagDataMovement = (
  headerFilterState,
  selectedCentral,
  selectedGroup,
  products,
  categories,
  sunriseType
) => {
  if (selectedCentral.length > 0 && selectedGroup.length > 0) {
    return async (dispatch) => {
      axios
        .post(getApiUrl("/api/CustomTagDataMovement/GetListByAnalysis"), {
          alarmSbomTreeviews: selectedCentral,
          selectedTags: selectedGroup,
          startDate: headerFilterState.startDate,
          endDate: headerFilterState.endDate,
          productIds: products.map((p) => p.id),
          productCategoryIds: categories.map((p) => p.id),
          sunriseType: sunriseType,
        })
        .then((res) => dispatch({ type: SET_GRAPH_DATA, payload: res.data }))
        .catch((err) => console.log(err));
    };
  } else {
    return async (dispatch) => {
      //dispatch({type: , payload: []})
    };
  }
};

export const GetFavoriteTagGroup = () => {
  return async (dispatch) => {
    axios
      .get(getApiUrl("/api/TagCustomTypeGroupFavorite/GetList"))
      .then((res) => res.data)
      .then((res) => {
        dispatch({ type: FAVORITE_TAGGROUP, payload: res.data });
      })
      .catch(() => {});
  };
};

export const GetCentralByAnalysisFavorite = () => {
  return async (dispatch) => {
    axios
      .get(getApiUrl("/api/CentralByAnalysisFavorite/GetList"))
      .then((res) => res.data)
      .then((res) => {
        dispatch({ type: FAVORITE_ANALYSISPARAMETER, payload: res.data });
      })
      .catch(() => {});
  };
};

export const paths = {
  // private

  //shared
  dashboard: "/Dashboard",
  homePage: "/Home",
  pageSelection: "/Blank/PageSelection",
  settings: "/Settings",
  fleetManager: "/fleetManager",
  crossPlantExport: "/crossPlantExport",
  workOrders: "/workOrders",
  userManagement: "/userManagment",

  reports: {
    reports: "/reports",
    reportView: "/reportView",
    reportIdentification: "/reportIdentification",
    logReports: "/Reports/LogReports",
    logDetailReports: "/Reports/LogDetailReports",
    notActiveAlarmReport: "/Reports/NotActiveAlarmReport",
    dataConnectionReport: "/Reports/DataConnectionReport",
  },

  alarms: {
    alarms: "/alarms",
    alarmView: "/alarmView",
    alarmIdentification: "/alarmIdentification",
  },

  areas: {
    areas: "/areas",
    areasOverView: "/areas/OverView",
    areasBrowser: "/areas/Browser",
    areasAdd: "/areas/Add",
    fleetManager: "/areas/fleetManager",
  },

  analysis: {
    analysis: "/analysis",
    analysisDetail: "/analysisDetail",
  },

  registerModelAdded: {
    registerModelAdd: "/registerModelAdd",
    tagAdd: "/tagAdd",
    registerModelTagAdd: "/registerModelTagAdd",
  },

  definations: {
    productUnitDefination: "/Defination/ProductUnit",
    productCategoryDefination: "/Defination/ProductCategory",
    productTypeDefination: "/Defination/ProductType",
    unitDefination: "/Defination/Unit",
    tagGroupDefination: "/Defination/TagGroup",
    tagCustomTypeDefination: "/Defination/TagCustomType",
    tagCustomTypeGroupDefination: "/Defination/TagCustomTypeGroup",
    accountTypeDefination: "/Defination/AccountType",
    locationTypeDefination: "/Defination/LocationType",
    centralType: "/Defination/CentralType",
    communicationDataType: "/Defination/CommunicationDataType",
    brand: "/Defination/Brand",
    locationUnit: "/Defination/LocationUnit",
    dashboard: "/Defination/Dashboard",
    importFileType: "/Defination/ImportFileType",
  },

  recipes: {
    recipeRange: "/Recipes/RecipeRange",
  },

  managament: {
    userManagment: "/Managment/UserManagment",
    companyManagament: "/Managment/CompanyManagament",
    fieldManagament: "/Managment/FieldManagament",
    centralManagament:
      "/Managment/FieldManagament/CentralManagament/:accountFieldId",
    productManagament: "/Managment/ProductManagament",
    productTree: {
      index: "/Managment/ProductTree",
      newProduct: "/Managment/ProductTree/New/Product",
      productTypeSelect: "/Managment/ProductTree/New/TypeSelect",
      productSelect: "/Managment/ProductTree/New/ProductSelect",
      sBom: "/Managment/ProductTree/New/Sbom",
      sbomProductDetailPage: "/Managment/ProductTree/Sbom/ProductDetailPage",
      sbomProductCustomDetailPage:
        "/Managment/ProductTree/Sbom/ProductCustomDetailPage/:sbomProductDetailId",
      sbomProductCommunicationPage:
        "/Managment/ProductTree/Sbom/ProductCommunicationPage/:sbomProductDetailId",
      sbomProductMovement:
        "/Managment/ProductTree/Sbom/ProductMovement/:sbomId",
      updateSbom: "/Managment/ProductTree/Sbom/UpdateSbom",
    },
  },

  auth: {
    login: "/Login",
    forgotPassword: "/ForgotPassword",
  },

  userManagement: {
    roleManagement: "/UserManagement/RoleManagement"
  }
  
};

import { SET_RECIPERANGE_EDIT } from "./../../actions/actionTypes";

const recipeRangeState = {
  selectedAccountId: null,
  selectedAccountFieldId: null,
  selectedCentralId: null,
};

export const RecipeRangeReducer = (state = recipeRangeState, action) => {
  switch (action.type) {
    case SET_RECIPERANGE_EDIT:
      return { ...state, [action.payload.dataField]: action.payload.value };
    default:
      return state;
  }
};

import React from "react";
import FieldManagamentGrid from "./FieldManagamentGrid";

const FieldManagamentPage = () => {
  return (
    <div className="c-main">
      <div className="c-content">
        <FieldManagamentGrid />
      </div>
    </div>
  );
};
export default FieldManagamentPage;

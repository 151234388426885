import React from "react";
import { Checkbox } from "antd";

export const NotificationFormEdit = ({ setValue, value }) => {
  const options = [
    { label: "Email", value: "IsEmail" },
    { label: "Sms", value: "IsSms" },
    { label: "Whatsapp", value: "IsWhatsapp" },
    { label: "Telegram", value: "IsTelegram" },
    { label: "Bip", value: "IsBip" },
    { label: "Mobile App", value: "IsMobileApplication" },
  ];

  return (
    <div>
      <Checkbox.Group
        options={options}
        defaultValue={value ? JSON.parse(value) : null}
        onChange={(e) => setValue(JSON.stringify(e))}
      />
    </div>
  );
};

import React from "react";
import EditForm from "../../../../../components/Shared/form/EditForm.js";
import { CreateGridData } from "../../../../../helper/Grid/GridCreatorMixin.js";
import { useHistory } from "react-router-dom";
import { paths } from "./../../../../../helper/resources/paths";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getApiUrl } from "./../../../../../config/applicationConfig";
import { GetTreeViewData } from "./../../../../../redux/actions/Sbom/TreeViewActions";
import { useTranslation } from "react-i18next";

const UpdateSbom = () => {
  const { t } = useTranslation();
  const productTreeState = useSelector((p) => p.ProductTreeReducer);
  const [formData, setFormData] = React.useState();

  const history = useHistory();
  const dispatch = useDispatch();

  React.useEffect(() => {
    axios
      .get(getApiUrl("/api/Sbom/GetById"), {
        params: {
          id: productTreeState.selectedTreeView.sbomId,
        },
      })
      .then((res) => res.data)
      .then((res) => setFormData(res))
      .catch((res) => console.log(res));
  }, [productTreeState]);

  const _onSubmit = (formData) => {
    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    axios
      .post(getApiUrl("/api/Sbom/UpdateName"), null, {
        params: {
          sbomId: productTreeState.selectedTreeView.sbomId,
          name: object["name"],
          isActive: object["isActive"],
        },
      })
      .then(() => {
        dispatch(GetTreeViewData(productTreeState.selectedCentralId));
        history.push(paths.managament.productTree.index);
      });
  };
  const pageGrid = {
    apiController: "Sbom",
    apiKey: "Id",
    editTitle: t("treeInformation"),
    onSubmit: _onSubmit,
    formData: formData,
    hideIsActive: true,
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 2,
      validationSummary: true,
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        disabled: true,
      },
      {
        dataField: "Name",
        caption: t("name"),
      },
      {
        dataField: "ShortCode",
        caption: t("shortCode"),
        disabled: true,
      },
      {
        dataField: "IsActive",
        caption: t("active"),
        editorType: "dxCheckBox",
      },
    ],
  };
  var gridData = CreateGridData(pageGrid);

  return (
    <div>
      <EditForm gridData={gridData} />
    </div>
  );
};
export default UpdateSbom;

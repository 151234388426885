import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import store from "./redux/reducers/configureStore";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "./helper/LanguageSupport/i18nextInit";
import "react-loadingmask/dist/react-loadingmask.css";
import "./assets/content/App.css";
import "./assets/content/StyleSheet.scss";
import "./assets/content/Partial/Responsive.scss";
import "./assets/content/UserTracking.scss";

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

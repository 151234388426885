import React from "react";
import { Button } from "antd";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../../helper/Grid/GridCreatorMixin";
import { paths } from "./../../../../helper/resources/paths";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { GetTreeViewData } from "./../../../../redux/actions/Sbom/TreeViewActions";
import axios from "axios";
import messageError from "./../../../../helper/messages/errorMessage";
import { getApiUrl } from "./../../../../config/applicationConfig";
import { useTranslation } from "react-i18next";

const ProductSelect = () => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  let productTreeState = useSelector((p) => p.ProductTreeReducer);

  const dispatch = useDispatch();
  const history = useHistory();
  const _onSelectionChanged = (data) => {
    setValue(data.selectedRowKeys[0]);
  };
  const pageGrid = {
    apiController: "Product",
    apiKey: "Id",
    editTitle: t("productInfo"),
    exportPanel: false,
    refreshItem: false,
    groupPanel: false,
    searchPanel: false,
    onSelectionChanged: _onSelectionChanged,
    selection: true,
    selectionMode: "single",
    defaultSelectedRowKeys: [value],
    editMode: {
      isActive: false,
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
      {
        dataField: "BrandId",
        caption: t("brand"),
        dataType: "number",
        relationContent: {
          controller: "Brand",
          display: "Name",
          loadUrl: "GetList",
        },
      },
      {
        dataField: "ModelId",
        caption: t("model"),
        dataType: "number",
        relationContent: {
          controller: "Model",
          display: "Name",
          loadUrl: "GetList",
        },
      },
      {
        dataField: "SerialNumber",
        caption: t("serialNumber"),
      },
      {
        dataField: "ProductionDate",
        caption: t("productionDate"),
        editorType: "dxDateBox",
      },
      {
        dataField: "ProductCategoryId",
        caption: t("productCategory"),
        dataType: "number",
        relationContent: {
          controller: "ProductCategory",
          display: "Name",
        },
      },
      {
        dataField: "ProductUnitId",
        caption: t("productUnit"),
        dataType: "number",
        relationContent: {
          controller: "ProductUnit",
          display: "Name",
        },
      },
    ],
  };

  const _onClick = () => {
    var object = {};

    object["parentSbomId"] = productTreeState.parentSbomId;
    object["centralId"] = productTreeState.selectedCentralId;
    object["sbomCode"] = productTreeState.sbomCode;
    object["sbomName"] = productTreeState.sbomName;
    object["sbomIsActive"] = productTreeState.sbomIsActive;
    object["isProduct"] =
      productTreeState.productType === "abstract" ? false : true;
    object["productId"] = value;

    var json = JSON.stringify(object);
    var sendFormData = new FormData();
    sendFormData.append("values", json);

    axios
      .post(getApiUrl("/api/Product/InsertSbomAndProduct"), sendFormData, {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      })
      .then((res) => res.data)
      .then(() => {
        history.push(paths.managament.productTree.index);
        dispatch(GetTreeViewData(productTreeState.selectedCentralId));
      })
      .catch((err) => messageError(err.message));
  };

  let routeProductTypeSelect = () =>
    history.push(paths.managament.productTree.newProduct);

  return (
    <div className="c-product__select">
      <Button
        className="c-product__select-button-new"
        onClick={routeProductTypeSelect}
      >
        {t("addNewProduct")}
      </Button>
      <RenderMasterGrid gridData={CreateGridData(pageGrid)} />
      <div className="c-product__select-button-right">
        <Button className="c-product__select-button-save" onClick={_onClick}>
          {t("save")}
        </Button>
      </div>
    </div>
  );
};
export default ProductSelect;

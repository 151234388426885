import BrowserPage from "../../../views/Areas/BrowserPage";
import OverviewPage from "../../../views/Areas/OverviewPage";
import { MenuType } from "../../enums/MenuType";
import { paths } from "../../resources/paths";
import CentralManagamentPage from "../../../views/Areas/CentralManagement/CentralManagamentPage";
import FleetManagerPage from "./../../../views/FleetManager/FleetManagerPage";

export const AreaItems = (t) => [
  {
    key: "1",
    icon: "fad fa-layer-group",
    text: t("overView"),
    path: paths.areas.areasOverView,
    render: <OverviewPage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "2",
    icon: "fad fa-send-backward",
    text: t("browser"),
    path: paths.areas.areasBrowser,
    render: <BrowserPage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "3",
    icon: "fad fa-layer-plus",
    text: t("add"),
    path: paths.managament.centralManagament.replace("/:accountFieldId", ""),
    render: <CentralManagamentPage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "4",
    icon: "fad fa-layer-plus",
    text: t("fleetManager"),
    path: paths.areas.fleetManager,
    render: <FleetManagerPage />,
    MenuType: MenuType.MAINMENU,
  },
];

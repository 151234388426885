import React, { useEffect, useState } from "react";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../helper/Grid/GridCreatorMixin";
import { useRecoilStateLoadable } from "recoil";
import { graphDataState } from "./recoil/RecoilActions";
import { useTranslation } from "react-i18next";

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const AnalysisPageGrid = () => {
  const { t } = useTranslation();
  const [cDataSource, setCDataSource] = useState();
  const [rKey, setrKey] = useState(0);
  const graphData = useRecoilStateLoadable(graphDataState)[0];

  useEffect(() => {
    if (graphData.state === "hasValue" && graphData.contents != null) {
      const unitByGroupData = groupBy(graphData.contents, "tagCode");
      setCDataSource(
        Object.keys(unitByGroupData).map((key) => ({
          id: key,
          accountName: key.split("~")[4],
          accountFieldName: key.split("~")[3],
          centralName: key.split("~")[6],
          productName: key.split("~")[5],
          tagName: key.split("~")[7],
          unit: key.split("~")[1],
          top: unitByGroupData[key]
            .map((p) => p.calcValue)
            .reduce((acc, valueOf) => acc + valueOf, 0)
            .toFixed(2),
          max: Math.max(...unitByGroupData[key].map((p) => p.calcValue)),
          min: Math.min(...unitByGroupData[key].map((p) => p.calcValue)),
          avarage:
            unitByGroupData[key]
              .map((p) => p.calcValue)
              .reduce((acc, valueOf) => acc + valueOf, 0) /
            unitByGroupData[key].length,
        }))
      );
      setrKey(rKey + 1);
    }
  }, [graphData]);

  const pageGrid = {
    customDataSource: cDataSource,
    editTitle: "",
    selection: false,
    refreshItem: false,
    hideIsActive: true,
    editMode: {
      isActive: false,
      allowAdding: false,
      allowDeleting: false,
      allowUpdating: false,
      allowCopy: false,
      useIcons: false,
    },
    columns: [
      {
        dataField: "AccountName",
        caption: t("company"),
      },
      {
        dataField: "AccountFieldName",
        caption: t("area"),
      },
      {
        dataField: "CentralName",
        caption: t("central"),
      },
      {
        dataField: "ProductName",
        caption: t("device"),
      },
      {
        dataField: "tagName",
        caption: t("tag"),
      },
      {
        dataField: "Unit",
        caption: t("unit"),
      },
      // {
      //     dataField: "Top",
      //     caption: "Toplam"
      // },
      {
        dataField: "Max",
        caption: t("max"),
        customCellRender: (cell) => cell.value.toFixed(2),
      },
      {
        dataField: "Min",
        caption: t("min"),
        customCellRender: (cell) => cell.value.toFixed(2),
      },
      {
        dataField: "Avarage",
        caption: t("avarage"),
        customCellRender: (cell) => cell.value.toFixed(2),
      },
    ],
  };

  return (
    <div key={rKey}>
      <RenderMasterGrid gridData={CreateGridData(pageGrid)} />
    </div>
  );
};
export default AnalysisPageGrid;

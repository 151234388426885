import { Button, Radio } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { paths } from "./../../../../helper/resources/paths";
import { useDispatch, useSelector } from "react-redux";
import { SET_PRODUCT_TYPE } from "../../../../redux/actions/actionTypes";
import { SET_PARENT_PRODUCT_ID } from "./../../../../redux/actions/actionTypes";
import { useTranslation } from "react-i18next";

const ProductTypeSelect = () => {
  const { t } = useTranslation();
  let productTreeState = useSelector((p) => p.ProductTreeReducer);
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(null);
  const history = useHistory();

  const _onNext = () => {
    dispatch({ type: SET_PRODUCT_TYPE, payload: value });
    dispatch({
      type: SET_PARENT_PRODUCT_ID,
      payload: productTreeState.parentSbomId,
    });
    history.push(paths.managament.productTree.sBom);
  };

  const options = [
    { label: t("product"), value: "concrete" },
    { label: t("other"), value: "abstract" },
  ];

  return (
    <div className="c-edit">
      <h3 className="c-edit__form-title">{t("productTypeSelect")}</h3>
      <Radio.Group
        options={options}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        optionType="button"
        buttonStyle="solid"
        className="c-edit__button"
      />
      <div className="c-edit__footer">
        <Button
          onClick={_onNext}
          type="primary"
          className="c-edit__footer-button"
        >
          {t("next")}
        </Button>
      </div>
    </div>
  );
};
export default ProductTypeSelect;

import React from "react";
import EditForm from "../../../../../components/Shared/form/EditForm.js";
import { CreateGridData } from "../../../../../helper/Grid/GridCreatorMixin.js";
import axios from "axios";
import { getApiUrl } from "../../../../../config/applicationConfig.js";
import { useHistory } from "react-router-dom";
import { Button } from "antd";
import { paths } from "./../../../../../helper/resources/paths";
import { useSelector, useDispatch } from "react-redux";
import messageError from "./../../../../../helper/messages/errorMessage";
import { GetTreeViewData } from "./../../../../../redux/actions/Sbom/TreeViewActions";
import DataSource from "devextreme/data/data_source";
import { setSubGridApiDefault } from "../../../../../config/fetchApiConfig.js";
import { SelectBox } from "devextreme-react";
import { useTranslation } from "react-i18next";

const NewProduct = () => {
  const { t } = useTranslation();
  const productTreeState = useSelector((p) => p.ProductTreeReducer);
  const [state, setState] = React.useState({
    modelId: null,
  });
  const history = useHistory();
  const dispatch = useDispatch();

  const _onSubmit = (formData) => {
    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    object["parentSbomId"] = productTreeState.parentSbomId;
    object["centralId"] = productTreeState.selectedCentralId;
    object["sbomCode"] = productTreeState.sbomCode;
    object["sbomName"] = productTreeState.sbomName;
    object["modelId"] = state.modelId;
    object["isProduct"] =
      productTreeState.productType === "abstract" ? false : true;

    var json = JSON.stringify(object);
    var sendFormData = new FormData();
    sendFormData.append("values", json);

    axios
      .post(getApiUrl("/api/Product/InsertSbomAndProduct"), sendFormData, {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      })
      .then((res) => res.data)
      .then(() => {
        history.push(paths.managament.productTree.index);
        dispatch(GetTreeViewData(productTreeState.selectedCentralId));
      })
      .catch((err) => messageError(err.message));
  };

  const modelDataSource = new DataSource({
    store: setSubGridApiDefault("Model", "id", "GetRelationList"),
    group: "brandName",
  });

  const pageGrid = {
    apiController: "Product",
    apiKey: "Id",
    editTitle: t("productInfo"),
    onSubmit: _onSubmit,
    hideIsActive: true,
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 2,
      validationSummary: true,
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
      {
        dataField: "ModelId",
        caption: t("model"),
        customEditCellComponent: (cell) => {
          return (
            <SelectBox
              dataSource={modelDataSource}
              valueExpr="id"
              grouped={true}
              value={state.modelId}
              onValueChanged={({ value }) =>
                setState({ ...state, modelId: value })
              }
              displayExpr="name"
            />
          );
        },
      },
      {
        dataField: "SerialNumber",
        caption: t("serialNumber"),
      },
      {
        dataField: "ProductionDate",
        caption: t("productionDate"),
        editorType: "dxDateBox",
      },
      {
        dataField: "ProductCategoryId",
        caption: t("productCategory"),
        dataType: "number",
        relationContent: {
          controller: "ProductCategory",
          display: "Name",
        },
      },
      {
        dataField: "ProductUnitId",
        caption: t("productUnit"),
        dataType: "number",
        relationContent: {
          controller: "ProductUnit",
          display: "Name",
        },
      },
    ],
  };
  var gridData = CreateGridData(pageGrid);
  let routeProductSelect = () =>
    history.push(paths.managament.productTree.productSelect);

  return (
    <div>
      <Button onClick={routeProductSelect}>
        {t("productAlreadyRegistered")}
      </Button>
      <EditForm gridData={gridData} />
    </div>
  );
};
export default NewProduct;

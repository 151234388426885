import { SET_DASHBOARD_WIDGETS } from "../../actions/actionTypes";

const defaultState = {
  dashboardWidgets: [],
};
export const DashboardReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_DASHBOARD_WIDGETS:
      return { ...state, dashboardWidgets: action.payload };
    default:
      return state;
  }
};

import {
  SET_ALARMCONNECTION_EDIT,
  SET_DEFAULT_ALARMCONNECTION_EDIT,
} from "./../../actions/actionTypes";

const alarmConnectionState = {
  selectedAccountId: null,
  selectedAccountFieldId: null,
  selectedCentralId: null,
  selectedSbomId: null,
};

export const AlarmConnectionReducer = (
  state = alarmConnectionState,
  action
) => {
  switch (action.type) {
    case SET_ALARMCONNECTION_EDIT:
      return { ...state, [action.payload.dataField]: action.payload.value };
    case SET_DEFAULT_ALARMCONNECTION_EDIT:
      return alarmConnectionState;
    default:
      return state;
  }
};

import axios from "axios";
import Cookies from "universal-cookie";
import applicationConfig, { getApiUrl } from "../../../config/applicationConfig";
import { setFetchApiToken } from "../../../config/fetchApiConfig";
import messageError from "../../../helper/messages/errorMessage";
import messageInfo from "../../../helper/messages/infoMessage";
import messageSuccess from "../../../helper/messages/successMessage";
import { GET_SIGNED_USER } from "../actionTypes";
import { paths } from "./../../../helper/resources/paths";
import i18n from "../../../helper/LanguageSupport/i18nextInit";

export const Login = (username, password,accountCode, rememberMe, history) => {
    rememberMe = rememberMe || false;
  if (rememberMe) {
    const cookies = new Cookies();
    cookies.set("solarPanelUserName", username);
    cookies.set("solarPanelPass", password);
    cookies.set("solarPanelAccountCode", accountCode);
  }
  return async (dispatch) => {
    var userForLoginDto = {
      Email: username,
      Password: password,
      AccountCode: accountCode
    };
    axios
      .post(getApiUrl("/api/Auth/Login"), userForLoginDto)
      .then((res) => res.data)
      .then((result) => {
        if (result.success) {
          if(accountCode){
            window.location.href = applicationConfig.customerPanelUrl + "?token=" + result.data.token
          }
          else{
            axios.defaults.headers.common.Authorization = `Bearer ${result.data.data.token}`
            localStorage.setItem("token", result.data.data.token);
            dispatch({
              type: GET_SIGNED_USER,
              payload: { token: result.data.data.token, isLogin: true, user: result.data },
            });
            messageSuccess(i18n.t("successfullySignedIn"));
            history.push(paths.homePage);
          }
        } else {
          dispatch({
            type: GET_SIGNED_USER,
            payload: { token: "", isLogin: false, user: {} },
          });
          messageError({ text: result.message });
        }
      })
      .catch((err) => messageError({ text: err.message }));
  };
};

export const GetUserByToken = (token, history) => {
  return async (dispatch) => {
    axios
      .get(getApiUrl("/api/Auth/GetUserByToken"), {
        params: {
          token,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          axios.defaults.headers.common.Authorization = `Bearer ${data.data.token}`
          localStorage.setItem("isLogin", true);
          dispatch({
            type: GET_SIGNED_USER,
            payload: { token: token, isLogin: true, user: data.data },
          });
        } else {
          localStorage.setItem("isLogin", false);
          localStorage.setItem("token", "");
          dispatch({
            type: GET_SIGNED_USER,
            payload: { token: "", isLogin: false, user: {} },
          });
          history.push("/Login");
        }
      })
      .catch((err) => messageError({ text: err.message }));
  };
};

// message.error(err.message)
export const UpdateRangeUserRoles = (roleId, permissions) => {
  axios
    .post(getApiUrl("/api/RolePermission/UpdateRange"), permissions, {
      params: {
        roleId,
      },
    })
    .then((res) => res.data)
    .then((data) => {
      messageInfo({ text: data.message });
    })
    .catch((err) => messageError(err.message));
};

export const Logout = (history) => {
  return async (dispatch) => {
    localStorage.setItem("isLogin", false);
    localStorage.setItem("token", "");
    setFetchApiToken("");
    history.push(paths.auth.login);
    dispatch({
      type: GET_SIGNED_USER,
      payload: { token: "", isLogin: false, user: {} },
    });
  };
};

export const UpdatePassword = (email, password) => {
  axios
    .post(getApiUrl("/api/Auth/UpdatePassword"), null, {
      params: {
        email,
        password,
      },
    })
    .then((res) => res.data)
    .then((data) => {
      if (!data.success) {
        messageError(data.message);
      } else {
        messageSuccess(data.message);
      }
    })
    .catch((err) => messageError(err.message));
};

import {
  FAVORITE_ANALYSISPARAMETER,
  FAVORITE_TAGGROUP,
  SET_ANALYSIS_TAGS,
  SET_ANALYSIS_TEMPLATE,
  SET_GRAPH_DATA,
  SET_TAGCUSTOMTYPEGROUP_VIEW,
} from "../../actions/actionTypes";
import { SET_ANALYSIS_PARAMETER } from "./../../actions/actionTypes";

const analysisState = {
  selectedTags: [],
  selectedTemplate: [],
  analysisParameterList: null,
  selectedTagGroupData: [],
  favoriteAnalysisParameter: [],
  favoriteTagGroup: [],
  tagGroupData: [],
  graphData: [],
};

export const AnalysisReducer = (state = analysisState, action) => {
  switch (action.type) {
    case SET_ANALYSIS_TAGS:
      return { ...state, selectedTags: action.payload, selectedTemplate: [] };
    case SET_ANALYSIS_TEMPLATE:
      return {
        ...state,
        selectedTags: action.payload.selectedTags,
        headerFilterState: action.payload.selectedTemplate,
      };
    case SET_ANALYSIS_PARAMETER:
      return { ...state, analysisParameterList: action.payload };
    case SET_TAGCUSTOMTYPEGROUP_VIEW:
      return { ...state, tagGroupData: action.payload };
    case SET_GRAPH_DATA:
      return { ...state, graphData: action.payload };
    case FAVORITE_ANALYSISPARAMETER:
      return { ...state, favoriteAnalysisParameter: action.payload };
    case FAVORITE_TAGGROUP:
      return { ...state, favoriteTagGroup: action.payload };
    default:
      return state;
  }
};

import { GET_SIDEBAR_HIDDER } from "../../actions/actionTypes";

export const SidebarHidderReducer = (state = false, action) => {
  switch (action.type) {
    case GET_SIDEBAR_HIDDER:
      return action.payload;
    default:
      return state;
  }
};

import { Layout, Menu } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { MenuType } from "../../../helper/enums/MenuType";
import { MenuItems } from "../../../helper/MenuItems/MenuItems";

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const userState = useSelector((p) => p.SignedUserReducer);
  const collapsed = useSelector((p) => p.SidebarHidderReducer);

  const [searchText] = useState("");
  const [openKeys, setOpenKeys] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const selectedMainMenu = useState(localStorage.getItem("selectedMainMenu")?.toString() ?? "");

  const menuClick = (path, text) => history.push(path);
  const getIcon = (icon) =>  <i className={icon}></i>

  React.useEffect(() => {
    setOpenKeys([selectedMainMenu[0].toString()]);
  }, []);

  React.useEffect(()=>{
    if(userState && userState?.user){
      setMenuItems(
        MenuItems(t, userState.user.userRole).filter((p) => p.menuType === MenuType.SIDEBAR)
      )
    }
  },[userState.user.userRole])

  const onOpenChange = (keys) => {
    setOpenKeys([keys[keys.length - 1]]);
  };

  return (
    <Sider width="250" className="c-sider" collapsed={collapsed}>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[window.location.pathname]}
        onOpenChange={onOpenChange}
        openKeys={openKeys}
        className="c-menu"
      >
        {menuItems.map((items) =>
          items.subMenuItems.length > 0 ? (
            searchText.length > 0 ? (
              items.subMenuItems
                .filter(
                  (p) =>
                    p.MenuType === MenuType.MAINMENU &&
                    p.text.toLocaleLowerCase("tr-TR").includes(searchText)
                )
                .map((item) => (
                  <Menu.Item
                    key={item.path}
                    icon={getIcon(item.icon)}
                    onClick={() => menuClick(item.path, item.text)}
                  >
                    {item.text}
                  </Menu.Item>
                ))
            ) : (
              <SubMenu
                key={items.key}
                icon={getIcon(items.icon)}
                onTitleClick={() =>
                  localStorage.setItem(
                    "selectedMainMenu",
                    items.key,
                    "mainMenuInserted"
                  )
                }
                title={items.text}
              >
                {items.subMenuItems
                  .filter((p) => p.MenuType === MenuType.MAINMENU)
                  .map((item) => (
                    <Menu.Item
                      key={item.path}
                      icon={getIcon(item.icon)}
                      onClick={() => menuClick(item.path, item.text)}
                    >
                      {item.text}
                    </Menu.Item>
                  ))}
              </SubMenu>
            )
          ) : searchText.length > 0 ? null : (
            <Menu.Item
              key={items.path}
              icon={getIcon(items.icon)}
              onClick={() => menuClick(items.path)}
            >
              {items.text}
            </Menu.Item>
          )
        )}
      </Menu>
    </Sider>
  );
};
export default Sidebar;

import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { paths } from "../helper/resources/paths";
import LoginPage from "../views/Shared/Login/LoginPage";
import { useTranslation } from "react-i18next";


const PublicRoutes = () => {
  const {t} = useTranslation()
  return (
    <Switch>
      <Route
        path={paths.login}
        render={() => (
          <>
            <LoginPage />
          </>
        )}
      />
      <Route path={paths.forgotPassword} render={()=>(<div>{t("forgotPassword")}</div>)} />
      <Redirect
        to={paths.auth.login}
        render={() => (
          <>
            <LoginPage />
          </>
        )}
      />
    </Switch>
  );
};
export default PublicRoutes;

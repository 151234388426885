import React from "react";
import { SelectBox } from "devextreme-react";
import { useEffect } from "react";
import { setSubGridApiDefault } from "../../../../config/fetchApiConfig";
import { useDispatch, useSelector } from "react-redux";
import { SET_RECIPERANGE_EDIT } from "../../../../redux/actions/actionTypes";
import { useTranslation } from "react-i18next";

const AccountFieldSelectBox = ({ setValue, value }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const recipeRangeState = useSelector((p) => p.RecipeRangeReducer);

  useEffect(() => {
    dispatch({
      type: SET_RECIPERANGE_EDIT,
      payload: { dataField: "selectedAccountFieldId", value: value },
    });
  }, [value]);

  return (
    <SelectBox
      dataSource={setSubGridApiDefault(
        "AccountView",
        "id",
        "GetListAccountField",
        null,
        null,
        null,
        { accountId: recipeRangeState.selectedAccountId }
      )}
      displayExpr="value"
      onValueChanged={({ value }) => {
        setValue && setValue(value.id);
        dispatch({
          type: SET_RECIPERANGE_EDIT,
          payload: { dataField: "selectedAccountFieldId", value: value.id },
        });
        dispatch({
          type: SET_RECIPERANGE_EDIT,
          payload: { dataField: "selectedCentralId", value: null },
        });
      }}
      searchEnabled={true}
      value={recipeRangeState.selectedAccountFieldId ?? value}
      placeholder={t("selectArea")}
    />
  );
};
export default AccountFieldSelectBox;

import { MenuType } from "../../enums/MenuType";
import { paths } from "../../resources/paths";
import ReportViewPage from "../../../views/Reports/ReportViewPage";
import ReportIdentificationPage from "../../../views/Reports/ReportIdentificationPage";
import LogReportPage from "./../../../views/Reports/LogReports/LogReportPage";
import LogDetailReportPage from "./../../../views/Reports/LogDetailReports/LogDetailReportPage";
import NotActiveAlarmReportPage from "./../../../views/Reports/NotActiveAlarmReport/NotActiveAlarmReportPage";
import DataConnectionReportPage from "./../../../views/Reports/DataConnectionReport/DataConnectionReportPage";

export const ReportItems = (t) => [
  {
    key: "1",
    icon: "fas fa-file-search",
    text: t("reportView"),
    path: paths.reports.reportView,
    render: <ReportViewPage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "2",
    icon: "fas fa-file-export",
    text: t("reportIdentification"),
    path: paths.reports.reportIdentification,
    render: <ReportIdentificationPage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "3",
    icon: "fas fa-file-export",
    text: t("logReports"),
    path: paths.reports.logReports,
    render: <LogReportPage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "4",
    icon: "fas fa-file-export",
    text: t("logDetailReports"),
    path: paths.reports.logDetailReports,
    render: <LogDetailReportPage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "5",
    icon: "fas fa-file-export",
    text: t("notActiveAlarmReport"),
    path: paths.reports.notActiveAlarmReport,
    render: <NotActiveAlarmReportPage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "5",
    icon: "fas fa-file-export",
    text: t("dataConnectionReport"),
    path: paths.reports.dataConnectionReport,
    render: <DataConnectionReportPage />,
    MenuType: MenuType.MAINMENU,
  },
];

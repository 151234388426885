import React from "react";
import NotActiveAlarmReportGrid from "./NotActiveAlarmReportGrid";

const NotActiveAlarmReportPage = () => {
  return (
    <div className="c-main">
      <div className="c-content">
        <NotActiveAlarmReportGrid />
      </div>
    </div>
  );
};
export default NotActiveAlarmReportPage;

import React from "react";
import { RadioGroup } from "devextreme-react/radio-group";
import axios from "axios";
import { getApiUrl } from "./../../../../config/applicationConfig";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_ALARM_TRIGGER,
  SET_ALARM_TYPE,
} from "./../../../../redux/actions/actionTypes";
import { fetcher } from "../../../../config/fetchApiConfig";

const AddAlarmType = () => {
  const dispatch = useDispatch();
  const addAlarmState = useSelector((p) => p.AddAlarmReducer);
  const [alarmTypes, setAlarmTypes] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(getApiUrl("/api/AlarmType/GetList"))
      .then((res) => res.data)
      .then((res) => setAlarmTypes(res.data));
    fetcher(getApiUrl(`/api/AlarmTrigger/AutoGenerateCode`))
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: SET_ALARM_TRIGGER,
          payload: { dataField: "code", value: data },
        });
      });
  }, []);

  const onValueChange = (e) => {
    dispatch({ type: SET_ALARM_TYPE, payload: e.id });
  };
  const customRender = (alarmType) => {
    return (
      <div className="c-alert">
        <div className="c-alert__container">
          <div className="c-alert__icon">
            <i className={alarmType.icon}></i>
          </div>
          <div className="c-alert__content">
            <strong className="c-alert__content-title">{alarmType.code}</strong>
            <p className="c-alert__content-text">{alarmType.name}</p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <RadioGroup
        onValueChange={onValueChange}
        dataSource={alarmTypes}
        itemRender={customRender}
        layout="horizontal"
        value={addAlarmState.alarmTypeId}
      />
    </div>
  );
};
export default AddAlarmType;

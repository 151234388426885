import React from "react";
import { SelectBox } from "devextreme-react";
import {
  fetcher,
  setSubGridApiDefault,
} from "../../../../config/fetchApiConfig";
import { useEffect, useRef } from "react";
import { getApiUrl } from "../../../../config/applicationConfig";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  SET_SELECTBOX_DATA,
  SET_SELECTED_CENTRAL_ID,
} from "./../../../../redux/actions/actionTypes";
import { GetTreeViewData } from "./../../../../redux/actions/Sbom/TreeViewActions";
import { useHistory } from "react-router-dom";
import { paths } from "./../../../../helper/resources/paths";
import { useTranslation } from "react-i18next";

const CentralSelect = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  let productTreeState = useSelector((p) => p.ProductTreeReducer);

  useEffect(() => {
    if (
      productTreeState.selectedCentralId > 0 &&
      productTreeState.selectBoxData.accountId === null
    ) {
      fetcher(
        getApiUrl(
          `/api/Central/GetById?centralId=${productTreeState.selectedCentralId}`
        )
      )
        .then((res) => res.json())
        .then((data) => {
          dispatch({
            type: SET_SELECTBOX_DATA,
            payload: {
              centralId: data.id,
              accountId: data.accountField.accountId,
              fieldId: data.accountFieldId,
              fieldSelectBoxOpen: false,
              centralSelectBoxOpen: false,
            },
          });
        });
    }
  }, [productTreeState.selectedCentralId]);

  return (
    <div className="c-central">
      <SelectBox
        className="c-central__select"
        dataSource={setSubGridApiDefault(
          "AccountView",
          "id",
          "GetListAccount",
          null,
          null,
          null
        )}
        //onOpened={()=>dispatch({ type: SET_SELECTBOX_DATA, payload: {...productTreeState, centralSelectBoxOpen: false, fieldSelectBoxOpen: false}})}
        displayExpr="value"
        onValueChanged={({ value }) => {
          history.push(paths.managament.productTree.index);
          dispatch({ type: SET_SELECTED_CENTRAL_ID, payload: 0 });
          dispatch({
            type: SET_SELECTBOX_DATA,
            payload: {
              centralId: null,
              accountId: value.id,
              fieldId: null,
              fieldSelectBoxOpen: true,
              centralSelectBoxOpen: false,
            },
          });
        }}
        searchEnabled={true}
        value={productTreeState.selectBoxData.accountId}
        placeholder={t("selectCompany")}
      />
      <SelectBox
        className="c-central__select"
        opened={productTreeState.selectBoxData.fieldSelectBoxOpen}
        dataSource={setSubGridApiDefault(
          "AccountView",
          "id",
          "GetListAccountField",
          null,
          null,
          null,
          { accountId: productTreeState.selectBoxData.accountId }
        )}
        displayExpr="value"
        onValueChanged={({ value }) => {
          dispatch({
            type: SET_SELECTBOX_DATA,
            payload: {
              centralId: null,
              accountId: productTreeState.selectBoxData.accountId,
              fieldId: value.id,
              fieldSelectBoxOpen: false,
              centralSelectBoxOpen: true,
            },
          });
          history.push(paths.managament.productTree.index);
        }}
        searchEnabled={true}
        value={productTreeState.selectBoxData.fieldId}
        placeholder={t("selectArea")}
      />
      <SelectBox
        opened={productTreeState.selectBoxData.centralSelectBoxOpen}
        onOpened={() => {
          dispatch({
            type: SET_SELECTBOX_DATA,
            payload: {
              centralId: productTreeState.selectBoxData.centralId,
              accountId: productTreeState.selectBoxData.accountId,
              fieldId: productTreeState.selectBoxData.fieldId,
              fieldSelectBoxOpen: false,
              centralSelectBoxOpen: true,
            },
          });
        }}
        className="c-central__select"
        dataSource={setSubGridApiDefault(
          "Central",
          "id",
          "GetList",
          null,
          null,
          null,
          { accountFieldId: productTreeState.selectBoxData.fieldId }
        )}
        displayExpr="name"
        onValueChanged={({ value }) => {
          dispatch({
            type: SET_SELECTBOX_DATA,
            payload: {
              centralId: value.id,
              accountId: productTreeState.selectBoxData.accountId,
              fieldId: productTreeState.selectBoxData.fieldId,
              fieldSelectBoxOpen: false,
              centralSelectBoxOpen: false,
            },
          });
          dispatch({ type: SET_SELECTED_CENTRAL_ID, payload: value.id });
          history.push(paths.managament.productTree.index);
          dispatch(GetTreeViewData(value.id));
        }}
        searchEnabled={true}
        value={productTreeState.selectBoxData.centralId}
        placeholder={t("selectCentral")}
      />
    </div>
  );
};
export default CentralSelect;

import React from "react";
import RoleManagementGrid from "./RoleManagementGrid";

const RoleManagementPage = () => {
  return (
    <div className="c-main">
      <div className="c-content">
        <RoleManagementGrid />
      </div>
    </div>
  );
};

export default RoleManagementPage;

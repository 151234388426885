import { Button, Card, Input } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UpdatePassword } from '../../../redux/actions/Shared/AuthActions';

const UpdatePasswordModal = (props) => {
    const {t} = useTranslation()
    const [password, setPassword] = useState()

    return (
        <div style={{marginBottom:"15px"}}>
            <Card>
                    <Input.Password onChange={(e)=>setPassword(e.target.value)} placeholder="Yeni Şifre" />
            </Card>
            <Button className="btn-save" style={{float:"right"}} onClick={()=>UpdatePassword(props.email,password)}>{t("save")}</Button>
        </div>
    )
}

export default UpdatePasswordModal
import React from "react";
import { SelectBox } from "devextreme-react";
import { setSubGridApiDefault } from "../../../../config/fetchApiConfig";
import { useTranslation } from "react-i18next";

const RegionDropdown = ({ setValue, countryId, value }) => {
  const { t } = useTranslation();
  return (
    <SelectBox
      className="c-country__region"
      dataSource={setSubGridApiDefault(
        "City",
        "id",
        "GetListByCountry",
        null,
        null,
        null,
        { countryId: countryId ?? 0 }
      )}
      displayExpr="name"
      onValueChanged={({ value }) => setValue && setValue(value?.id ?? null)}
      searchEnabled={true}
      disabled={!countryId && !value}
      defaultValue={value}
      placeholder={t("selectCountry")}
    />
  );
};
export default RegionDropdown;

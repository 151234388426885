import React from "react";
import { Form, GroupItem, SimpleItem } from "devextreme-react/form";
import { Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { SET_ALARM_TRIGGER } from "../../../../redux/actions/actionTypes";
import { NotificationFormEdit } from "../../../../components/Shared/form/NotificationFormEdit";
import ArrayStore from "devextreme/data/array_store";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";


const AddDefinitions = ({ setDefinationsRef }) => {
    const { t } = useTranslation();
  const dispatch = useDispatch();
  const addAlarmState = useSelector((p) => p.AddAlarmReducer);

  const radioGroupItems = [
    { id: 0, text: t("low"), color: "#f59b25" },
    { id: 1, text: t("medium"), color: "#f26e2c" },
    { id: 2, text: t("high"), color: "#f24349" },
  ];

  const renderAlarmLevel = () => (
    <Radio.Group
      onChange={(e) =>
        dispatch({
          type: SET_ALARM_TRIGGER,
          payload: { dataField: "priority", value: e.target.value },
        })
      }
      defaultValue={0}
      value={addAlarmState.priority}
      buttonStyle="solid"
      style={{ width: "100%" }}
    >
      {radioGroupItems.map((item, index) => (
        <Radio.Button
          value={item.id}
          key={index}
          style={{ backgroundColor: item.color, width: "33.2%" }}
        >
          {item.text}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
  const style = {
    labelStyle: { fontWeight: "bold" },
    itemStyle: { paddingBottom: "1vh" },
  };
  return (
    <div>
      <Form
        ref={(ref) => setDefinationsRef(ref)}
        onFieldDataChanged={(e) =>
          e.dataField === "isSunrise"
            ? dispatch({ type: SET_ALARM_TRIGGER, payload: e })
            : null
        }
        defaultFormData={addAlarmState}
      >
        <GroupItem caption={t("alarmInfo")} colCount={2}>
          <SimpleItem dataField="code" label={{ text: t("code") }} />
          <SimpleItem dataField="name" label={{ text: t("name") }} />
          <SimpleItem
            dataField="intervalTime"
            label={{ text: t("delayTime") }}
            editorType="dxNumberBox"
          />
          <SimpleItem
            dataField="priority"
            render={(cell) => renderAlarmLevel(cell)}
          />
          <SimpleItem
            dataField="isSunrise"
            label={{ text: t("sunrise") / t("sunset") }}
            editorType="dxCheckBox"
          />
          <SimpleItem
            dataField="sunrise"
            label={{ text: t("startTime") }}
            visible={addAlarmState.isSunrise}
            editorType="dxNumberBox"
          />
          <SimpleItem
            dataField="sunset"
            label={{ text: t("endTime") }}
            visible={addAlarmState.isSunrise}
            editorType="dxNumberBox"
          />
        </GroupItem>
      </Form>
      <div>
        <div style={style.itemStyle}>
          <label style={style.labelStyle}>{t("notificationSettings")}</label>
          <NotificationFormEdit
            setValue={(e) =>
              dispatch({
                type: SET_ALARM_TRIGGER,
                payload: { dataField: "notificationSettings", value: e },
              })
            }
          />
        </div>
        <div style={style.itemStyle}>
          <label style={style.labelStyle}>{t("phoneNumber")}</label>
          <PhoneInput
            country={"tr"}
            onChange={(e) =>
              dispatch({
                type: SET_ALARM_TRIGGER,
                payload: { dataField: "notificationPhoneNumber", value: e },
              })
            }
          />
        </div>
      </div>
    </div>
  );
};
export default AddDefinitions;

import { SET_TREEVIEW_DATA, SET_TURN_TREEVIEW } from "./../actionTypes";
import messageError from "./../../../helper/messages/errorMessage";
import { getApiUrl } from "./../../../config/applicationConfig";
import axios from "axios";
import messageSuccess from "./../../../helper/messages/successMessage";
import { paths } from "./../../../helper/resources/paths";

export const GetTreeViewData = (centralId) => {
  return async (dispatch) => {
    axios
      .get(getApiUrl("/api/Sbom/GetListTreeView"), {
        params: {
          centralId,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        dispatch({ type: SET_TREEVIEW_DATA, payload: data });
      })
      .catch((err) => {
        messageError({ text: err.message });
      });
  };
};

export const DeleteTreeView = (sbomId) => {
  return axios
    .delete(getApiUrl("/api/Sbom/Delete"), {
      params: {
        key: sbomId,
      },
    })
    .then((res) => res.data)
    .then((data) => {
      messageSuccess("Başarıyla silindi");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const TurnTreeView = (centralId, history) => {
  history.push(paths.managament.productTree.index);
  return async (dispatch) => {
    dispatch({ type: SET_TURN_TREEVIEW, payload: centralId });
  };
};

import { MenuType } from "../../enums/MenuType";
import { paths } from "../../resources/paths";
import AnalysisPage from "../../../views/Analysis/Analysis/AnalysisPage";

export const AnalysisItems = (t) => [
  {
    key: "1",
    icon: "fas fa-analytics",
    text: t("analysis"),
    path: paths.analysis.analysis,
    render: <AnalysisPage />,
    MenuType: MenuType.MAINMENU,
  },
  {
    key: "2",
    icon: "fas fa-analytics",
    text: t("analysisDetails"),
    path: paths.analysis.analysisDetail,
    render: <AnalysisPage />,
    MenuType: MenuType.MAINMENU,
  },
];

import React from "react";
import { Button, Steps } from "antd";
import ProductTypeSelect from "./ProductTypeSelect";
import NewProduct from "./New/NewProduct";
import { paths } from "./../../../../helper/resources/paths";
import { Switch, Route, useHistory } from "react-router-dom";
import ProductSelect from "./ProductSelect";
import NewSbom from "./New/NewSbom";
import { useDispatch, useSelector } from "react-redux";
import { TurnTreeView } from "./../../../../redux/actions/Sbom/TreeViewActions";
import { useTranslation } from "react-i18next";

function ProductStepForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  let productTreeState = useSelector((p) => p.ProductTreeReducer);

  const getCurrentStep = (path) => {
    switch (path) {
      case paths.managament.productTree.productTypeSelect:
        return 0;
      case paths.managament.productTree.sBom:
        return 1;
      case paths.managament.productTree.productSelect:
        return 2;
      case paths.managament.productTree.newProduct:
        return 2;
      default:
        return 0;
    }
  };

  const returnTypeSelect = () => {
    history.push(paths.managament.productTree.productTypeSelect);
  };
  const { Step } = Steps;

  return (
    <Switch>
      <Route path={"/Managment/ProductTree/New/"}>
        <strong className="c-general__header">{t("treeProcessing")}</strong>
        <div className="c-button__bottom">
          <Button
            className="c-button__close"
            type="primary"
            danger
            size="small"
            onClick={() =>
              dispatch(
                TurnTreeView(productTreeState.selectedCentralId, history)
              )
            }
          >
            X
          </Button>
        </div>
        <Steps
          current={getCurrentStep(history.location.pathname)}
          style={{ width: 700, marginBottom: "20px" }}
        >
          <Steps.Step
            key="1"
            title={t("typeSelection")}
            onClick={returnTypeSelect}
          />
          <Steps.Step key="2" title={t("treeIdentification")} />
          <Steps.Step key="3" title={t("productRegistrationSelect")} />
        </Steps>
        <Route
          exact
          path={paths.managament.productTree.productTypeSelect}
          component={ProductTypeSelect}
        />
        <Route
          exact
          path={paths.managament.productTree.sBom}
          component={NewSbom}
        />
        <Route
          exact
          path={paths.managament.productTree.newProduct}
          component={NewProduct}
        />
        <Route
          exact
          path={paths.managament.productTree.productSelect}
          component={ProductSelect}
        />
      </Route>
    </Switch>
  );
}
export { ProductStepForm };

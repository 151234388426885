import React from "react";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../helper/Grid/GridCreatorMixin";
import { useTranslation } from "react-i18next";
import RoleMenuModal from "./Components/RoleMenuModal";
import Modal from "../../../components/Shared/Modal/Modal";
import RolPermissionModal from "./Components/RolPermissionModal";

const RoleManagementGrid = () => {
  const { t } = useTranslation();

  const pageGrid = {
    apiController: "Role",
    apiKey: "Id",
    editTitle: t("role"),
    hideIsActive: true,
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 1,
      validationSummary: true,
      customButtons: [
        {
          width: 500,
          caption: t("process"),
          buttons: [
            {
              render: (e) => {
                var data = e.row.data;
                return (
                  <Modal
                    width={600}
                    className={"btn-grid"}
                    buttonText={"Özel Yetkilendir"}
                    title={"Özel Yetkilendir"}
                    content={<RolPermissionModal roleId={data.id} selectedPermissions={data.permissions} />}
                    hideFooter
                  />
                );
              },
            },
            {
              render: (e) => {
                var data = e.row.data;
                return (
                  <Modal
                    width={600}
                    className={"btn-grid"}
                    buttonText={"Menü Yetkilendir"}
                    title={"Menü Yetkilendir"}
                    content={<RoleMenuModal roleId={data.id} />}
                    hideFooter
                  />
                );
              },
            },
          ],
        },
      ],
    },
    columns: [
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
      {
        dataField: "Description",
        caption: t("description"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
    ],
  };

  const userRoleGrid = {
    apiController: "UserRole",
    apiKey: "Id",
    editTitle: t("userRole"),
    hideIsActive: true,
    subGridFeature: {
      loadUrl: "GetList",
      relationKey: "RoleId",
      insertUrl: "Insert",
      updateUrl: "Update",
      removeUrl: "Delete",
    },
    columns: [
      {
        dataField: "UserId",
        caption: t("user"),
        dataType: "number",
        relationContent: {
          controller: "User",
          display: "FirstName",
        },
      },
    ],
  };

  let gridData = CreateGridData(pageGrid);
  const userRoleGridData = CreateGridData(userRoleGrid);

  gridData.subGrids = [
    {
      tabCaption: t("users"),
      tabGridFeature: userRoleGridData,
    },
  ];

  return <RenderMasterGrid gridData={gridData} />;
};
export default RoleManagementGrid;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationTR from "../../assets/locales/tr/translationTR.json";
import translationEN from "../../assets/locales/en/translationEN.json";
import translationFR from "../../assets/locales/fr/translationFR.json";

const resources = {
  tr: {
    translation: translationTR,
  },
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "tr",
    fallbackLng: ["en","tr","fr"],
    interpolation: {
      escapeValue: false,
    },
    debug: false,
  });

export default i18n;

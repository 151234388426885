import { addDays } from "../../../helper/JavascriptHelper";
import { SET_HEADER_FILTER } from "../../actions/actionTypes";

const defaultState = {
  startDate: addDays(Date.now(), -1),
  endDate: addDays(Date.now(), 0),
};

export const HeaderFilterReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_HEADER_FILTER:
      return action.payload;
    case "SET_DEFAULT_HEADER":
      return defaultState;
    default:
      return state;
  }
};

import { GET_SIGNED_USER } from "../../actions/actionTypes";

const signedUserState = {
  token: "",
  isLogin: null,
  user: {},
};

export const SignedUserReducer = (state = signedUserState, action) => {
  switch (action.type) {
    case GET_SIGNED_USER:
      return action.payload;
    default:
      return state;
  }
};

import React from "react";
import CommunicationDataTypeGrid from "./CommunicationDataTypeGrid";

const CommunicationDataTypePage = () => {
  return (
    <div className="c-main">
      <div className="c-content">
        <CommunicationDataTypeGrid />
      </div>
    </div>
  );
};
export default CommunicationDataTypePage;

import {
  DELETE_DASHBOARD_WIDGET_IDS,
  GET_DASHBOARD_WIDGET_DATA,
  GET_DASHBOARD_WIDGET_IDS,
} from "./../../actions/actionTypes";

const dashboardWidgetState = {
  widgets: [],
  widgetIds: [],
};

export const DashboardWidgetReducer = (
  state = dashboardWidgetState,
  action
) => {
  switch (action.type) {
    case GET_DASHBOARD_WIDGET_DATA:
      var tempDataIndex = state.widgets.findIndex(
        (p) => p.id === action.payload.id
      );
      if (tempDataIndex > 0) {
        state.widgets[tempDataIndex].data = action.payload.data;
        return state;
      } else {
        return { ...state, widgets: [...state.widgets, action.payload] };
      }
    case GET_DASHBOARD_WIDGET_IDS:
      return { ...state, widgetIds: [...state.widgetIds, action.payload] };
    case DELETE_DASHBOARD_WIDGET_IDS:
      return dashboardWidgetState;
    default:
      return state;
  }
};

import React, { useRef } from "react";
import { Button } from "antd";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../../helper/Grid/GridCreatorMixin";
import { SqlOperatorType } from "./../../../../helper/enums/SqlOperatorType";
import {
  OperatorTypeSource,
  SqlOperatorTypeSource,
} from "../../../../helper/enums/EnumDataSource";
import { useDispatch, useSelector } from "react-redux";
import { SET_ALARM_DETAIL } from "../../../../redux/actions/actionTypes";
import { useTranslation } from "react-i18next";
import { visibility } from "devexpress-reporting/scopes/reporting-chart-internal";

const AddAlarmDefinationGrid = () => {
    const { t } = useTranslation();
  const dispatch = useDispatch();
  const addAlarmState = useSelector((p) => p.AddAlarmReducer);

  const [gridRef, setGridRef] = React.useState(useRef());
  const [state, setState] = React.useState({
    count: 0,
    data: [],
    operatorType: null,
  });
  console.log(addAlarmState)
  const onRowInserting = (data) => {
    delete data.isActive;
    data["id2"] = state.count;

    dispatch({ type: SET_ALARM_DETAIL, payload: data });
    setState({ ...state, count: state.count + 1 });
  };
  const pageGrid = {
    customDataSource: addAlarmState.alarmTriggerDetails,
    apiKey: "Id2",
    editTitle: t("productInfo"),
    hideIsActive: true,
    onRowInserting: ({ data }) => {
      onRowInserting(data);
    },
    refreshItem: false,
    selection: false,
    searchPanel: false,
    columnChooser: false,
    exportPanel: false,
    groupPanel: false,
    allowCopy: false,
    setRef: setGridRef,
    onInitNewRow: (options) => {
      options.data.sqlOperatorType = state.operatorType;
    },
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      allowCopy: false,
    },
    columns: [
      {
        dataField: "TagCustomTypeId",
        caption: t("tag"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        relationContent: {
          controller: "TagCustomType",
          display: "Name",
        },
      },
      {
        dataField: "Operator",
        caption: t("operator"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        localRelationContent: {
          dataSource: OperatorTypeSource,
        },
      },
      {
        dataField: "RegValue",
        caption: t("value"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "SqlOperatorType",
        caption: t("sqlOperator"),
        dataType: "number",
        editCellVisibility: state.count > 0,
        localRelationContent: {
          dataSource: SqlOperatorTypeSource,
        },
      },
      {
        dataField: "Description",
        caption: t("description"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "PlusMinusValue",
        caption: "+/-",
        dataType: "number",
        visibility: addAlarmState.alarmTypeId !== 5 && addAlarmState.alarmTypeId !== 8
      },
      {
        dataField: "MaxValue",
        caption: t("maxValue"),
        dataType: "number",
        visibility: addAlarmState.alarmTypeId !== 5 && addAlarmState.alarmTypeId !== 8
      },
      {
        dataField: "MinValue",
        caption: t("minValue"),
        dataType: "number",
        visibility: addAlarmState.alarmTypeId !== 5 && addAlarmState.alarmTypeId !== 8
      },
      {
        dataField: "PercentInfo",
        caption: t("percentInfo"),
        dataType: "number",
        visibility:   addAlarmState.alarmTypeId !== 8
      },
    ],
  };

  return (
    <div className="alarm-defination-grid">
      <Button
        hidden={state.count <= 0}
        onClick={() => {
          setState({ ...state, operatorType: SqlOperatorType.And });
          setTimeout(() => {
            gridRef?.instance?.addRow();
          }, 100);
        }}
      >
        {t("and")}
      </Button>
      <Button
        hidden={state.count <= 0}
        onClick={() => {
          setState({ ...state, operatorType: SqlOperatorType.Or });
          setTimeout(() => {
            gridRef?.instance?.addRow();
          }, 100);
        }}
      >
        Or
      </Button>
      <Button
        hidden={state.count > 0}
        onClick={() => {
          setTimeout(() => {
            gridRef?.instance?.addRow();
          }, 100);
        }}
      >
        {t("add")}
      </Button>
      <RenderMasterGrid gridData={CreateGridData(pageGrid)} />
    </div>
  );
};
export default AddAlarmDefinationGrid;

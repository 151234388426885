import React from "react";
import { Steps as AntdSteps, Button } from "antd";

const { Step } = AntdSteps;

const Steps = ({ steps, onSave, customNext, customPrev }) => {
  const [current, setCurrent] = React.useState(0);

  const next = () => {
    setCurrent(current + 1);
    if (customNext) {
      customNext();
    }
  };

  const prev = () => {
    setCurrent(current - 1);
    if (customPrev) {
      customPrev();
    }
  };

  return (
    <>
      <AntdSteps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </AntdSteps>
      <div className="steps-content">{steps[current].content}</div>
      <div className="steps-action">
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Sonraki
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => {
              onSave();
              setCurrent(0);
            }}
          >
            Kaydet
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Önceki
          </Button>
        )}
      </div>
    </>
  );
};

export default Steps;

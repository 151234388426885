import React from "react";
import RegisterTagModelAddGrid from "./RegisterTagModelAddGrid";

const RegisterTagModelAddPage = () => {
  return (
    <div className="c-main">
      <div className="c-content">
        <RegisterTagModelAddGrid />
      </div>
    </div>
  );
};
export default RegisterTagModelAddPage;

import { Button, Card, Input } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getApiUrl } from "../../../../config/applicationConfig";
import { UpdateRangeSubMenu } from "../../../../redux/actions/Role/RoleActions";
import TreeView from "devextreme-react/tree-view";

const RoleMenuModal = ({ roleId, selectedSubMenus }) => {
  const { t } = useTranslation();
  const [menus, setMenus] = React.useState([])
  const [subMenus, setSubMenus] = React.useState([])

  const [state, setState] = React.useState({
    menuId: 0,
    subMenuIds: []
  });

  React.useEffect(() => {
    axios
      .get(getApiUrl("/api/SubMenu/GetList"))
      .then((res) => res.data)
      .then((res) => setSubMenus(res.data));
  }, [roleId]);

  return (
    <div style={{ marginBottom: "15px" }}>
      <Card >
        <TreeView
          items={subMenus}
          dataStructure="plain"
          selectionMode="multiple"
          keyExpr="id"
          parentIdExpr="masterMenuId"
          displayExpr="text"
          selectByClick={true}
          showCheckBoxesMode="selectAll"
          searchEnabled={true}
          selectedItemKeys={state.subMenuIds}
          onItemSelectionChanged={(e) => {
            setState({
              ...state,
              subMenuIds: e.component.getSelectedNodeKeys()
            })
          }}
          onSelectAllValueChanged={(e) => {
            setState({
              ...state,
              subMenuIds: e.component.getSelectedNodeKeys()
            })
          }}
        />
      </Card>
      <Button className="btn-save" style={{ float: "right" }} onClick={()=>UpdateRangeSubMenu(roleId,state.subMenuIds)}>
        {t("save")}
      </Button>
    </div>
  );
};

export default RoleMenuModal;

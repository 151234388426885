import React from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../../../helper/Grid/GridCreatorMixin";
import { paths } from "./../../../../helper/resources/paths";
import { useDispatch } from "react-redux";
import { SET_SELECTED_CENTRAL_ID } from "../../../../redux/actions/actionTypes";
import { Button, Input } from "antd";
import currencies from "../../../../assets/localData/currencies.json";
import { GetTreeViewData } from "./../../../../redux/actions/Sbom/TreeViewActions";
import {
  CoordinateTypeSource,
  IecStandardTypeSource,
} from "../../../../helper/enums/EnumDataSource";
import { CoordinateType } from "../../../../helper/enums/CoordinateType";
import { NumberBox } from "devextreme-react";
import { doInputEvent } from "./../../../../helper/JavascriptHelper";
import { fetcher } from "../../../../config/fetchApiConfig";
import { getApiUrl } from "./../../../../config/applicationConfig";
import { NotificationFormEdit } from "../../../../components/Shared/form/NotificationFormEdit";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import Modal from "../../../../components/Shared/Modal/Modal";
import UpdatePasswordModal from "../../../../components/Shared/Modal/UpdatePasswordModal";

const CentralManagamentGrid = () => {
  const { t } = useTranslation();
  let { accountFieldId = 0 } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const updateGridHide = (data) => {
    if (data?.coordinateType === CoordinateType.ED50) {
      if (document.getElementById("centralGridId")) {
        document.getElementById("centralGridId").style.visibility = null;
      }
    } else if (data?.coordinateType === CoordinateType.WG84) {
      if (document.getElementById("centralGridId")) {
        document.getElementById("centralGridId").style.visibility = "hidden";
      }
    }
  };

  const pageGrid = {
    apiController: "Central",
    apiKey: "Id",
    editTitle: t("centralInfo"),
    loadParams: { accountFieldId: accountFieldId ?? 0 },
    onRowInserting: (e) => {
      e.data["accountFieldId"] = accountFieldId;
    },
    onInitNewRow: (options) => {
      options.data.priceType = "TRY";
      options.data.coordinateType = CoordinateType.WG84;
    },
    editMode: {
      onChangesChange: (e) => {
        if (e[0]?.data) {
          updateGridHide(e[0].data);
        }
      },
      allowAllCopy: true,
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 1,
      validationSummary: true,
      customButtons: [
        {
          width: 170,
          caption: undefined,
          buttons: [
            {
              render: (e) => {
                let data = e.row.data;
                let url = paths.managament.productTree.index;

                return (
                  <Button
                    className="btn-grid-button"
                    onClick={() => {
                      dispatch({
                        type: SET_SELECTED_CENTRAL_ID,
                        payload: data.id,
                      });
                      dispatch(GetTreeViewData(data.id));
                      history.push(url);
                    }}
                  >
                    Boma Git
                  </Button>
                );
              },
            },
          ],
        },
      ],
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 5,
          },
        },
        editCellComponent: (cell) => {
          const onChange = (value) => {
            cell.setValue(value);
          };
          const handleKeyDown = (e) => {
            if (e.key === "Tab") {
              fetcher(
                getApiUrl(
                  `/api/Central/AutoGenerateCode?accountFieldId=${accountFieldId}`
                )
              )
                .then((res) => res.json())
                .then((data) => {
                  var el = document.getElementById("centralCodeInput");
                  doInputEvent(el, data);
                });
            }
          };
          return (
            <Input
              defaultValue={cell.value}
              maxLength={5}
              minLength={16}
              id="centralCodeInput"
              onKeyDown={handleKeyDown}
              onChange={(e) => onChange(e.target.value)}
            />
          );
        },
      },
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "Latitude",
        caption: t("latitude"),
        dataType: "number",
      },
      {
        dataField: "Longitude",
        caption: t("longitude"),
        dataType: "number",
      },
      {
        dataField: "AcRatedPower",
        caption: t("acRatedPower"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "DcRatedPower",
        caption: t("dcRatedPower"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "DcRestrictPower",
        caption: t("dcRestrictPower"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "PriceType",
        caption: t("priceType"),
        localRelationContent: {
          dataSource: currencies,
          value: "Symbol",
          display: "Symbol",
        },
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "RadiationLimit",
        caption: t("radiationLimit"),
        dataType: "number",
      },
      {
        dataField: "AccountId",
        caption: t("company"),
        dataType: "number",
        relationContent: {
          controller: "Account",
          display: "Name",
        },
        editCellVisibility: false,
      },
      {
        dataField: "AccountFieldId",
        caption: t("area"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        relationContent: {
          controller: "AccountField",
          display: "Name",
        },
        editCellVisibility: false,
      },
      {
        dataField: "CentralTypeId",
        caption: t("centralType"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        relationContent: {
          controller: "CentralType",
          display: "Name",
        },
      },
      {
        dataField: "CoordinateType",
        caption: t("coordinateType"),
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        localRelationContent: {
          dataSource: CoordinateTypeSource,
        },
      },
      {
        dataField: "Grid",
        caption: t("grid"),
        dataType: "number",
        editCellComponent: (cell) => {
          return (
            <NumberBox
              defaultValue={cell.value}
              onValueChanged={(e) => cell.setValue(e.value)}
              id="centralGridId"
              style={{ visibility: cell.value ? null : "hidden" }}
            />
          );
        },
      },
      {
        dataField: "IecStandardType",
        caption: "IEC 61724-1",
        dataType: "number",
        fieldValidation: {
          isRequired: true,
        },
        localRelationContent: {
          dataSource: IecStandardTypeSource,
        },
      },
    ],
  };

  const featuresGrid = {
    apiController: "CentralFeature",
    apiKey: "Id",
    editTitle: t("centralFeature"),
    subGridFeature: {
      loadUrl: "GetList",
      relationKey: "CentralId",
      insertUrl: "Insert",
      updateUrl: "Update",
      removeUrl: "Delete",
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
    ],
  };

  const recipesGrid = {
    apiController: "CentralRecipeList",
    apiKey: "Id",
    editTitle: t("centralRecipeList"),
    subGridFeature: {
      loadUrl: "GetList",
      relationKey: "CentralId",
      insertUrl: "Insert",
      updateUrl: "Update",
      removeUrl: "Delete",
    },
    columns: [
      {
        dataField: "Code",
        caption: t("code"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
      {
        dataField: "Name",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
          stringLength: {
            min: 2,
          },
        },
      },
      {
        dataField: "StartDate",
        caption: t("startDate"),
        editorType: "dxDateBox",
      },
      {
        dataField: "RecipeRangeId",
        caption: t("recipeRangeId"),
        dataType: "number",
        relationContent: {
          controller: "RecipeRange",
          display: "Code",
        },
      },
    ],
  };

  const userGrid = {
    apiController: "User",
    apiKey: "Id",
    editTitle: t("user"),
    hideIsActive: false,
    subGridFeature: {
      loadUrl: "GetListByCentral",
      relationKey: "CentralId",
      insertUrl: "InsertByCentral",
      updateUrl: "Update",
      removeUrl: "Delete",
    },
    editMode: {
      customButtons: [
          {
              width: 130,
              caption: t("process"),
              buttons: [
                  {
                      render: (e) => {
                          var data = e.row.data
                          return (
                              <Modal width={600} className={"btn-grid"} buttonText={"Şifre Değiştir"} title={"Şifre Değiştir"} content={<UpdatePasswordModal email={data.email}/>} hideFooter/>
                          )
                      }
                  }]
              }
      ],
  },
    columns: [
      {
        dataField: "FirstName",
        caption: t("name"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "LastName",
        caption: t("lastName"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "Email",
        caption: t("eMail"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "Password",
        caption: t("password"),
        dataType: "password",
        visibility: false,
      },
      {
        dataField: "Description",
        caption: t("description"),
        fieldValidation: {
          isRequired: true,
        },
      },
      {
        dataField: "NotificationSettings",
        caption: t("notificationSettings"),
        dataType: "string",
        customCellRender: (cell) =>
          JSON.parse(cell.value).toString().replaceAll("Is", ""),
        editCellComponent: (cell) => (
          <NotificationFormEdit
            value={cell.value}
            setValue={(value) => cell.setValue(value)}
          />
        ),
        groupInfo: {
          groupCaption: t("notificationSettings"),
          groupIndex: 2,
        },
      },
      {
        dataField: "NotificationPhoneNumber",
        caption: t("phoneNumber"),
        dataType: "string",
        editCellComponent: (cell) => (
          <PhoneInput
            country={"tr"}
            value={cell.value}
            onChange={(value) => cell.setValue(value)}
          />
        ),
        groupInfo: {
          groupCaption: t("notificationSettings"),
          groupIndex: 2,
        },
      },
    ],
  };

  let gridData = CreateGridData(pageGrid);
  const featuresGridData = CreateGridData(featuresGrid);
  const recipesGridData = CreateGridData(recipesGrid);
  const userGridData = CreateGridData(userGrid);

  gridData.subGrids = [
    {
      tabCaption: t("features"),
      tabGridFeature: featuresGridData,
    },
    {
      tabCaption: t("recipeDefinition"),
      tabGridFeature: recipesGridData,
    },
    {
      tabCaption: t("users"),
      tabGridFeature: userGridData,
    },
  ];

  return <RenderMasterGrid gridData={gridData} />;
};
export default CentralManagamentGrid;

import axios from "axios";
import messageError from "../../../helper/messages/errorMessage";
import messageSuccess from "../../../helper/messages/successMessage";
import { getApiUrl } from "../../../config/applicationConfig";

export const UpdateRangeSubMenu = (roleId, subMenuIds) => {
  axios
    .post(getApiUrl("/api/RolePermission/UpdateRangeSubMenu"), {
      roleId,
      subMenuIds,
    })
    .then((res) => res.data)
    .then((data) => messageSuccess(data.message))
    .catch((err) => messageError(err.message));
};

export const UpdateRangePermission = (roleId, permissionIds) => {
  axios
    .post(getApiUrl("/api/RolePermission/UpdateRangePermission"), {
      roleId,
      permissionIds,
    })
    .then((res) => res.data)
    .then((data) => messageSuccess(data.message))
    .catch((err) => messageError(err.message));
};

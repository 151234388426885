import React from "react";
import {
  CreateGridData,
  RenderMasterGrid,
} from "../../helper/Grid/GridCreatorMixin";
import { useTranslation } from "react-i18next";

const WorkOrdersPage = () => {
  const { t } = useTranslation();
  const pageGrid = {
    apiKey: "Id",
    customDataSource: [
      {
        id: 1,
        stockCode: "TEST KOD",
        stockName: "TEST ADI",
        unit: "TEST BİRİM",
      },
    ],
    hideId: true,
    selection: false,
    editMode: {
      isActive: false,
    },
    columns: [
      {
        dataField: "StockCode",
        caption: t("stockCode"),
      },
      {
        dataField: "StockName",
        caption: t("stockName"),
      },
      {
        dataField: "Unit",
        caption: t("unit"),
      },
    ],
  };

  const gridData = CreateGridData(pageGrid);

  return (
    <div className="c-main">
      <div className="c-content">
        <RenderMasterGrid gridData={gridData} />
      </div>
    </div>
  );
};
export default WorkOrdersPage;

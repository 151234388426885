import React from "react";
import { useParams } from "react-router-dom";
import EditForm from "./../../../../../components/Shared/form/EditForm";
import axios from "axios";
import messageError from "./../../../../../helper/messages/errorMessage";
import { getApiUrl } from "./../../../../../config/applicationConfig";
import { CreateGridData } from "../../../../../helper/Grid/GridCreatorMixin";
import messageSuccess from "./../../../../../helper/messages/successMessage";
import { useTranslation } from "react-i18next";

const SbomProductCommunicationPage = () => {
  const { t } = useTranslation();
  var { sbomProductDetailId = 0 } = useParams();

  const [formData, setFormData] = React.useState();

  React.useEffect(() => {
    getSbomProductDetail();
  }, []);

  const getSbomProductDetail = () => {
    axios
      .get(getApiUrl("/api/SbomProductCommunication/Get"), {
        params: {
          sbomProductDetailId: sbomProductDetailId,
        },
      })
      .then((res) => res.data)
      .then((res) => {
        if (res === null) {
          setFormData({
            intervalTime: 300,
            updateSeconds: 300,
          });
        } else {
          setFormData(res);
        }
      });
  };

  const _onSubmit = (formData) => {
    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    object["sbomProductDetailId"] = sbomProductDetailId;

    var json = JSON.stringify(object);
    var sendFormData = new FormData();
    sendFormData.append("values", json);

    axios
      .post(getApiUrl("/api/SbomProductCommunication/Insert"), sendFormData, {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      })
      .then((res) => res.data)
      .then((res) =>
        res.success
          ? messageSuccess(res.message)
          : messageError({ text: res.message })
      )
      .catch((err) => console.log(err));
  };

  const pageGrid = {
    apiController: "SbomProductCommunication",
    apiKey: "Id",
    editTitle: t("contactDetail"),
    loadParams: { sbomProductDetailId: sbomProductDetailId },
    formData: formData,
    hideIsActive: true,
    onSubmit: _onSubmit,
    editMode: {
      isActive: true,
      mode: "form",
      allowAdding: true,
      allowDeleting: true,
      allowUpdating: true,
      useIcons: true,
      containerColCount: 1,
      validationSummary: true,
    },
    columns: [
      {
        dataField: "LocalIpAddress",
        caption: t("localIpAddress"),
      },
      {
        dataField: "LocalPort",
        caption: t("localPort"),
        editorType: "dxNumberBox",
      },
      {
        dataField: "WanIpAddress",
        caption: t("wanIpAddress"),
      },
      {
        dataField: "WanPort",
        caption: t("wanPort"),
        editorType: "dxNumberBox",
      },
      {
        dataField: "RootDirectory",
        caption: t("rootDirectory"),
      },
      {
        dataField: "FtpUsername",
        caption: t("ftpUsername"),
      },
      {
        dataField: "FtpPassword",
        caption: t("ftpPassword"),
      },
      {
        dataField: "FtpPort",
        caption: t("ftpPort"),
        editorType: "dxNumberBox",
      },
      {
        dataField: "AsduAddress",
        caption: t("asduAddress"),
        editorType: "dxNumberBox",
      },
      {
        dataField: "AddressPort",
        caption: t("addressPort"),
        editorType: "dxNumberBox",
      },
      {
        dataField: "IntervalTime",
        caption: t("intervalTime"),
        editorType: "dxNumberBox",
      },
      {
        dataField: "SlaveId",
        caption: t("slaveId"),
        editorType: "dxNumberBox",
      },
      {
        dataField: "ProductRegisterModelId",
        caption: t("productRegisterModel"),
        editorType: "dxNumberBox",
        relationContent: {
          controller: "ProductRegisterModel",
          display: "Name",
          customParam: { sbomProductDetailId: sbomProductDetailId },
        },
      },
      {
        dataField: "UpdateSeconds",
        caption: t("updateSeconds"),
        editorType: "dxNumberBox",
      },
      {
        dataField: "IsVpn",
        caption: t("vpn"),
        editorType: "dxCheckBox",
      },
      {
        dataField: "IsOnline",
        caption: t("online"),
        editorType: "dxCheckBox",
      },
      {
        dataField: "DoConnect",
        caption: t("doConnect"),
        editorType: "dxCheckBox",
      },
      {
        dataField: "IsConnectFtp",
        caption: t("connectFtp"),
        editorType: "dxCheckBox",
      },
      {
        dataField: "FtpProductRegisterModelId",
        caption: t("ftpProductRegisterModel"),
        editorType: "dxNumberBox",
        relationContent: {
          controller: "ProductRegisterModel",
          display: "Name",
          customParam: { sbomProductDetailId: sbomProductDetailId },
          loadUrl: "GetFtpRelationList",
        },
      },
    ],
  };
  const gridData = CreateGridData(pageGrid);
  return (
    <div>
      <EditForm gridData={gridData} />
    </div>
  );
};
export default SbomProductCommunicationPage;

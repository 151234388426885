import React from "react";
import AddAlarmType from "./AddAlarm/AddAlarmType";
import AddDefinitions from "./AddAlarm/AddDefinitions";
import Steps from "./../../../components/Shared/form/Steps";
import { useSelector, useDispatch } from "react-redux";
import { CLEAR_ALARM_TYPE } from "../../../redux/actions/actionTypes";
import { AddAlarmInsert } from "../../../redux/actions/Alarm/AlarmActions";
import AddAlarmDefinationGrid from "./AddAlarm/AddAlarmDefinationGrid";
import { SET_ALARM_TRIGGER } from "./../../../redux/actions/actionTypes";
import { useTranslation } from "react-i18next";

const AddStepForm = ({ setIsModalVisible }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const addAlarmState = useSelector((p) => p.AddAlarmReducer);
  const [definationsRef, setDefinationsRef] = React.useState();

  const stepsItems = [
    {
      title: t("alarmType"),
      content: <AddAlarmType />,
    },
    {
      title: t("alarmInfo"),
      content: <AddDefinitions setDefinationsRef={setDefinationsRef} />,
    },
    {
      title: t("alarmDetailInfo"),
      content: <AddAlarmDefinationGrid />,
    },
  ];

  const onSave = () => {
    setTimeout(() => {
      dispatch({ type: CLEAR_ALARM_TYPE, payload: {} });
    }, 100);
    setIsModalVisible(false);
    dispatch(AddAlarmInsert(addAlarmState));
  };
  const definationOnNext = () => {
    if (definationsRef) {
      for (const [key, value] of Object.entries(
        definationsRef.props.defaultFormData
      )) {
        if (value) {
          dispatch({
            type: SET_ALARM_TRIGGER,
            payload: { dataField: key, value: value },
          });
        }
      }
    }
  };
  return (
    <div className="c-content">
      <Steps steps={stepsItems} onSave={onSave} customNext={definationOnNext} />
    </div>
  );
};
export default AddStepForm;
